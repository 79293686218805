import {inject, Injectable} from '@angular/core';
import {FormGroup, NonNullableFormBuilder, Validators} from '@angular/forms';

import {UserForm} from '@features/users/models';

import {UserCreateUtils} from './user-create.utils';

@Injectable({ providedIn: 'root' })
export class UserFormBuilder {
  private readonly fb = inject(NonNullableFormBuilder);
  private readonly userCreateUtils = inject(UserCreateUtils);

  readonly firstNameMinLength = 2;
  readonly firstNameMaxLength = 80;
  readonly lastNameMinLength = 2;
  readonly lastNameMaxLength = 80;
  readonly jobTitleMaxLength = 80;
  readonly commentMaxLength = 512;

  buildForm(): FormGroup<UserForm> {
    return this.fb.group<UserForm>({
      firstName: this.fb.control('',
        [
          Validators.required,
          Validators.minLength(this.firstNameMinLength),
          Validators.maxLength(this.firstNameMaxLength)
        ]
      ),
      lastName: this.fb.control('',
        [
          Validators.required,
          Validators.minLength(this.lastNameMinLength),
          Validators.maxLength(this.lastNameMaxLength)
        ]
      ),
      jobTitle: this.fb.control('', [Validators.maxLength(this.jobTitleMaxLength)]),
      email: this.fb.control('',
        [Validators.required, Validators.email],
        [this.userCreateUtils.createEmailNotTakenValidator()]
      ),
      phone: this.fb.control('', [this.userCreateUtils.createPhoneValidator()]),
      organizations: this.fb.control([]),
      role: this.fb.control(null, Validators.required),
      isPrimaryContact: this.fb.control(false),
      comment: this.fb.control('', [Validators.maxLength(this.commentMaxLength)]),
      isMustResetPassword: this.fb.control(true),
      sendEmailInvitation: this.fb.control(true),
      enforce2FA: this.fb.control(false),
      assetMapping: this.fb.control(true),
      eventReporting: this.fb.control(false),
    }, {
      validators: [this.userCreateUtils.createUserModeValidator()]
    });
  }
}
