import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {environment} from '@environments/environment';

import {FileChangeEvent} from '@core/common';

@Component({
  selector: 'aps-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  standalone: true,
  imports: [
    MatIcon,
    MatButton
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileInputComponent {
  @ViewChild('fileInput') fileInput!: ElementRef;
  @Output() changed = new EventEmitter<FileChangeEvent>();
  showFileSizeErrorMsg = false;

  processFile(imageInput: any): void {
    const file: File = imageInput.files[0];
    const fileSizeMb = file.size / (1024 * 1024);
    this.showFileSizeErrorMsg = false;

    if (fileSizeMb > environment.blueprintMaxFileSizeMb) {
      this.fileInput.nativeElement.value = '';
      this.showFileSizeErrorMsg = true;
      return;
    }

    const reader = new FileReader();

    reader.addEventListener('load', (event) => {
      this.changed.emit({ fileBase64: event.target?.result as string, file });
      this.fileInput.nativeElement.value = '';
    });
    reader.readAsDataURL(file);
  }

  browseFile(): void {
    this.fileInput.nativeElement?.click();
  }
}
