import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  forwardRef,
  inject,
  Input,
  OnInit
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import {MatAutocomplete, MatAutocompleteTrigger, MatOption} from '@angular/material/autocomplete';
import {MatError, MatFormField} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatInput} from '@angular/material/input';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {catchError, debounceTime, distinctUntilChanged, filter, map, Observable, of, switchMap, tap} from 'rxjs';

import {Maybe} from '@core/common';
import {LocationService} from '@shared/components/location/entity';
import {isLocationFeature} from '@shared/components/location/location.helpers';
import {LocationFeature} from '@shared/models';

@Component({
  selector: 'aps-input-location',
  templateUrl: './input-location.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MatAutocomplete,
    MatOption,
    MatError,
    MatProgressSpinner,
    MatFormField,
    MatIcon,
    MatInput,
    MatAutocompleteTrigger
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputLocationComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputLocationComponent),
      multi: true
    }
  ]
})
export class InputLocationComponent implements OnInit, ControlValueAccessor, Validator {
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly locationService = inject(LocationService);
  private readonly destroyRef = inject(DestroyRef);
  private _value!: string;

  @Input() label = 'Location';
  @Input() placeholder = 'Add Location';
  @Input() locationMaxLength = 256;
  disabled = false;
  locationControl = new FormControl<string | LocationFeature>(
    '', [Validators.maxLength(this.locationMaxLength)]
  );

  filteredOptions$!: Observable<LocationFeature[]>;
  pending$ = this.locationService.pending$;

  get value(): any {
    if (this.locationControl.valid) {
      return this.locationControl.value;
    }

    return '';
  }

  set value(val: string) {
    if (val) {
      this.locationControl.setValue(val);
    }
    this._value = val;
    this.onChange(val);
    this.onTouch(val);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (_: any) => {
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch = (_: any) => {
  };

  ngOnInit() {
    this.locationControl.valueChanges
      .pipe(
        tap(() => {
          this.onChange(this.value);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();

    this.filteredOptions$ = this.locationControl.valueChanges
      .pipe(
        filter((searchValue: string | LocationFeature | null) => typeof searchValue === 'string'),
        filter((searchValue: string) => searchValue?.length > 2),
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((searchTerm: string) => {
            return this.locationService.findLocation(searchTerm)
              .pipe(
                map((response) => response),
                catchError(() => {
                  return of([]);
                })
              );
          }
        )
      );
  }

  writeValue(location: string): void {
    this.value = location;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.locationControl.disable();
    } else {
      this.locationControl.enable();
    }
    this.changeDetectorRef.markForCheck();
  }

  validate(): Maybe<ValidationErrors> {
    return this.locationControl.invalid ? { location: true } : null;
  }

  displayFn(location: string | LocationFeature): string {
    if (isLocationFeature(location)) {
      return location?.properties?.display_name ?? '';
    } else {
      return location;
    }
  }

}
