import {
  LeafletControlLayersConfig
} from '@asymmetrik/ngx-leaflet/lib/layers/control/leaflet-control-layers-config.model';
import {environment} from '@environments/environment';
import {latLng, LatLngLiteral, MapOptions, tileLayer} from 'leaflet';

export const TILE_LAYER_STREET = `${environment.mapBaseUrl}/{z}/{x}/{y}.png`;
export const TILE_LAYER_SATELLITE = `${environment.mapBaseUrl}/v4/mapbox.satellite/{z}/{x}/{y}.jpg90?access_token=${environment.mapAccessToken}`;
export const MAP_CENTER: LatLngLiteral = { lat: 48.684969, lng: 9.0113444 };
export const MAP_MAX_ZOOM = 19;
export const MAP_MAX_ZOOM_SATELLITE = 22;
export const MAP_MIN_ZOOM_FOR_CACHE_TILES = 14;
export const MAP_OPTIONS: MapOptions = {
  layers: [
    tileLayer(TILE_LAYER_STREET, { maxZoom: MAP_MAX_ZOOM })
  ],
  zoom: 5,
  attributionControl: false,
  center: latLng(MAP_CENTER?.lat, MAP_CENTER?.lng)
};

export const MAP_LAYERS_CONTROL: LeafletControlLayersConfig = {
  baseLayers: {
    'Street': tileLayer(TILE_LAYER_STREET, { maxZoom: MAP_MAX_ZOOM, attribution: '' }),
    'Satellite': tileLayer(TILE_LAYER_SATELLITE, { maxZoom: MAP_MAX_ZOOM_SATELLITE, attribution: '' })
  },
  overlays: {}
};
