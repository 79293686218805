<div class="flex items-baseline" data-test="e2e-projects-filter">
  <form [formGroup]="form" autocomplete="off">
    <mat-form-field class="w-56 project-filter-form-field" appearance="outline">
      <input type="text" matInput placeholder="Search by name" formControlName="name" data-test="e2e-projects-filter-name-input">
      @if (form.get('name')?.value) {
        <mat-icon matSuffix aria-label="Clear" svgIcon="close" class="size-smd cursor-pointer" data-test="e2e-projects-filter-name-clear-btn" (click)="clearSearchByNameFilter()" />
      }
    </mat-form-field>
    <mat-checkbox color="primary"
                  class="ml-3"
                  data-test="e2e-projects-filter-show-active-checkbox"
                  formControlName="showActiveProjects">
      <span class="text-sm">Show active projects only</span>
    </mat-checkbox>
  </form>
</div>
