import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatLatestFrom} from '@ngrx/operators';
import {Store} from '@ngrx/store';
import {catchError, filter, forkJoin, from, iif, map, Observable, of, switchMap, tap, throwError} from 'rxjs';
import {take} from 'rxjs/operators';

import {BackendError, DEFAULT_MODAL_WIDTH, ErrorCode, Maybe} from '@core/common';
import {EventReportProjectCreateComponent} from '@features/event-reporting/components/event-report-project-create';
import {EventReportingApiService} from '@features/event-reporting/services';
import {EventReportingActions} from '@features/event-reporting/store/event-reporting.actions';
import {eventProjectsFeature} from '@features/event-reporting/store/event-reporting.feature';
import {ConfirmActionDialogComponent} from '@shared/components';
import {ConfirmActionDialogData} from '@shared/models';
import {NotificationService} from '@shared/services';

@Injectable()
export class EventReportingEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly projectApi = inject(EventReportingApiService);
  private readonly notification = inject(NotificationService);
  private readonly dialog = inject(MatDialog);

  loadEventProjects$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        EventReportingActions.loadEventProjects,
        EventReportingActions.createEventProjectSuccess,
        EventReportingActions.updateEventProjectSuccess,
        EventReportingActions.updateEventProjectStatusSuccess
      ),
      concatLatestFrom(() => [
        this.store.select(eventProjectsFeature.selectProjectsPagination),
        this.store.select(eventProjectsFeature.selectSortingState),
        this.store.select(eventProjectsFeature.selectProjectFilter)
      ]),
      switchMap(([_, pagination, sortingState, projFilter]) => {
        return this.projectApi.getProjects(pagination, sortingState, projFilter).pipe(
          map((response) => EventReportingActions.loadEventProjectsSuccess(response)),
          catchError((err) => {
            this.notification.error('Failed to fetch event projects.');
            console.error('Failed to fetch event projects!', err);
            return of(EventReportingActions.loadEventProjectsFailure());
          })
        );
      })
    );
  });

  createEventProject$ = createEffect(() => {
    let createdProjectId: Maybe<number> = null;

    return this.actions$.pipe(
      ofType(EventReportingActions.createEventProject),
      switchMap(({ req, fiberRouteFile, blueprintFile }) => this.projectApi.create(req).pipe(
        switchMap((projectId) => {
          createdProjectId = projectId;
          this.notification.success(`Created successfully`);

          const uploadBpFileOperation$ = blueprintFile ?
            this.projectApi.uploadBlueprintFile(projectId, blueprintFile) :
            of('');

          const uploadFiberRouteFileOperation$ = fiberRouteFile ?
            this.uploadFiberRouteFile(projectId, fiberRouteFile) :
            of('');

          return forkJoin([uploadBpFileOperation$, uploadFiberRouteFileOperation$]).pipe(
            map(() => EventReportingActions.createEventProjectSuccess()),
            catchError((err) => {
              if (err instanceof BackendError) {
                // need to reopen modal on edit mode with new project
                this.notification.error(err.message);
                if (typeof createdProjectId === 'number') {
                  this.dialog.closeAll();
                  return from([EventReportingActions.openEventProjectPreviewDialog(createdProjectId, err.message), EventReportingActions.loadEventProjects()]);
                }
              } else {
                this.notification.error();
              }
              return of(EventReportingActions.createEventProjectFailure());
            })
          );
        })
      ))
    );
  });

  updateEventProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventReportingActions.updateEventProject),
      concatLatestFrom(() => this.store.select(eventProjectsFeature.selectProjectById)),
      switchMap(([{ req, fiberRouteFile, blueprintFile }, project]) => {
        const updateProjectMarkerOperation$ = typeof req.gpsLocation?.id === 'number' ?
          of('') :
          this.projectApi.addProjectGpsLocation(project?.id!, { ...req.gpsLocation! });

        const uploadFileOperation$ = blueprintFile ?
          this.projectApi.uploadBlueprintFile(project!.id, blueprintFile) :
          of('');

        const uploadFiberRouteFileOperation$ = fiberRouteFile ?
          this.uploadFiberRouteFile(project!.id, fiberRouteFile) :
          of('');

        return this.projectApi.update(req).pipe(
          switchMap(() => forkJoin([
            updateProjectMarkerOperation$,
            uploadFileOperation$,
            uploadFiberRouteFileOperation$
          ]).pipe(
            map(() => {
              this.notification.success(`Updated successfully`);
              return EventReportingActions.updateEventProjectSuccess();
            }),
            catchError((err) => {
              if (err instanceof BackendError) {
                this.notification.error('Failed to upload file');
                return of(EventReportingActions.updateEventProjectFailure(err.message));
              }
              this.notification.error('Failed to update event project.');
              console.error('Failed to update event project!', err);
              return of(EventReportingActions.updateEventProjectFailure());
            })
          ))
        );
      })
    );
  });

  changeEventProjectStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventReportingActions.updateEventProjectStatus),
      concatLatestFrom(() => this.store.select(eventProjectsFeature.selectProjectById)),
      switchMap(([{ isActive }, project]) => {
        const confirmActionDialogData: ConfirmActionDialogData = {
          title: `Project will be ${isActive ? 'activated' : 'deactivated'}`,
          description: `Do you want to ${isActive ? 'activate' : 'deactivate'} this project?`
        };

        return this.dialog.open(ConfirmActionDialogComponent, {
          width: '504px',
          data: confirmActionDialogData
        }).afterClosed()
          .pipe(
            take(1),
            switchMap((value) => iif(
              () => Boolean(value),
              this.projectApi.updateStatus(project?.id!, isActive).pipe(
                map(() => {
                  this.notification.success(`Project successfully ${isActive ? 'activated' : 'deactivated'}`);
                  return EventReportingActions.updateEventProjectStatusSuccess(isActive);
                }),
                catchError((err) => {
                  this.notification.error('Failed to update event project status.');
                  console.error('Failed to update event project status!', err);
                  return of(EventReportingActions.updateEventProjectStatusFailure());
                })
              ),
              of(EventReportingActions.updateEventProjectStatusCancel())
            ))
          );
      })
    );
  });

  checkIfProjectPendingBeforePreviewDialogOpen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventReportingActions.openEventProjectPreviewDialog),
      concatLatestFrom(() => this.store.select(eventProjectsFeature.selectIsProjectByIdPending).pipe(
        filter(pending => !pending)
      )),
      map(([{ id }]) => EventReportingActions.loadEventProjectById(id))
    );
  });

  loadEventProjectById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventReportingActions.loadEventProjectById),
      switchMap(({ id }) => this.projectApi.getById(id).pipe(
        map((response) => EventReportingActions.loadEventProjectByIdSuccess(response)),
        catchError((err) => {
          this.notification.error(`Failed to fetch event project data.`);
          console.error(`Failed to fetch project. ID ${id}`, err);
          return of(EventReportingActions.loadEventProjectByIdFailure());
        })
      ))
    );
  });

  closeEventProjectDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        EventReportingActions.closeEventProjectPreviewDialog,
        EventReportingActions.createEventProjectSuccess,
        EventReportingActions.createEventProjectFailure,
        EventReportingActions.updateEventProjectSuccess
      ),
      tap(() => this.dialog.closeAll())
    );
  }, { dispatch: false });

  openEventProjectUpdateDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventReportingActions.loadEventProjectByIdSuccess),
      switchMap(() => this.dialog.open(EventReportProjectCreateComponent, {
        width: DEFAULT_MODAL_WIDTH,
        closeOnNavigation: true
      }).afterClosed()),
      map(() => EventReportingActions.clearEventProjectById())
    );
  });

  updateEventProjectsList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventReportingActions.updateEventProjectsSorting, EventReportingActions.updateEventProjectsPagination, EventReportingActions.setEventProjectFilter),
      map(() => EventReportingActions.loadEventProjects())
    );
  });

  uploadFiberRouteFile(projectId: number, file: File): Observable<string> {
    return this.projectApi.uploadFiberRouteFile(projectId, file).pipe(
      catchError((err: unknown) => {
        // Temp solution. Replace when error codes support will be added to backend
        if (err instanceof HttpErrorResponse && (err.status as HttpStatusCode) === HttpStatusCode.BadRequest) {
          if (typeof err?.error === 'string' && err?.error?.includes('Parsing exception')) {
            return throwError(() => new BackendError(
              'File format incorrect. CSV file must contain four columns, separated by semicolon',
              ErrorCode.INVALID_CSV
            ));
          }
          if (err?.error?.isValidFile === false) {
            return throwError(() => new BackendError(
              'The file contains more than one reference route. Please select a file with only one reference route.',
              ErrorCode.INVALID_KML_KMZ
            ));
          }
        }
        return throwError(() => err as Error);
      })
    );
  }
}
