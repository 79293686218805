import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';

export interface BaseEntity<T = number> {
  id: T;
  name?: string;
  isDisabled?: boolean;
}

export type RequestStatus = 'IDLE' | 'LOADING' | 'SUCCESS' | 'ERROR';

export interface ListResponse<T = any> {
  data: T[];
  pagination: PaginationRes;
}

export type Maybe<T> = T | null;

export interface Pagination {
  pageIndex: number;
  pageSize: number;
}

export interface GpsCoordinates {
  id?: number;
  latitude: number;
  longitude: number;
  altitude: number;
}

export interface PaginationRes {
  currentPage?: number;
  hasNext?: boolean;
  hasPrevious?: boolean;
  nextPage?: string;
  pageSize?: number;
  previousPage?: string;
  totalCount?: number;
  totalPages?: number;
}

export interface ImageMetadata {
  url: string;
  width: number;
  height: number;
}

export type DialogMode = 'create' | 'update';

export type LoadingStatus = 'NOT_LOADED' | 'LOADING' | 'LOADED' | 'LOADING_ERROR';

export enum ErrorCode {
  INVALID_CSV = 1,
  INVALID_KML_KMZ = 2,
  INVALID_BP_FILE = 3,
}

export class BackendError extends Error {
  readonly code: ErrorCode;

  constructor(message: string, code: ErrorCode) {
    super(message);
    this.code = code;
  }
}

export interface FileChangeEvent {
  fileBase64: string;
  file: File;
}

export type DataFetcherFn<T = number> = (pagination: Pagination) => Observable<ListResponse<BaseEntity<T>>>;

export interface ValidatedResponse<T = unknown> {
  error: string;
  isValid: boolean;
  toValidationResponse: {
    ValidationFailed: string[];
  };
  value?: T;
}

export type RawFormValue<T> = {
  [K in keyof T]: T[K] extends FormControl<infer U> ? U : T[K]
}
