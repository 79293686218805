import {AsyncPipe} from '@angular/common';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormControlStatus, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {MatButton} from '@angular/material/button';
import {
  MatDialogRef
} from '@angular/material/dialog';
import {MatError, MatFormField} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatInput} from '@angular/material/input';
import {environment} from '@environments/environment';
import {Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {NgxPermissionsModule} from 'ngx-permissions';
import {
  BehaviorSubject,
  tap,
} from 'rxjs';

import {BaseEntity, DataFetcherFn, Maybe, WINDOW} from '@core/common';
import {EventReportProjectDeactivateComponent} from '@features/event-reporting/components/event-report-deactivate';
import {EventReportingApiService} from '@features/event-reporting/services';
import {eventProjectsFeature, EventReportingActions} from '@features/event-reporting/store';
import {EventReportProjectFormBuilder} from '@features/event-reporting/utils';
import {OrganizationApiService} from '@features/organizations/services';
import {getNotificationAssigneesFetchFn, getOrganizationsFetchFn} from '@features/organizations/utils';
import {InputTechniciansComponent} from '@features/projects/components/input-technicians';
import {ProjectExportComponent} from '@features/projects/components/project-export';
import {RouteValidationComponent} from '@features/projects/components/route-validation';
import {
  EventReportProjectForm,
  EventReportProjectFormRawValue,
  MappingType,
  ProjectMapFieldsData
} from '@features/projects/models';
import {
  isEventReportProjectsAreEqual,
  mapErProjectDtoFormValue,
  mapRawValueToEventReportProjectCreateReq,
  mapRawValueToEventReportProjectUpdateReq
} from '@features/projects/utils';
import {UserRole} from '@features/users/models';
import {
  CopyToClipboardComponent,
  EntityCreateDialogComponent,
  InfiniteScrollSelectorComponent,
} from '@shared/components';
import {LocationComponent} from '@shared/components/location';
import {ProjectHistoryCardComponent} from '@shared/components/project-history-card';

type DialogMode = 'create' | 'update';

@Component({
  selector: 'aps-event-report-project-create',
  templateUrl: './event-report-project-create.component.html',
  styleUrls: ['./event-report-project-create.component.scss'],
  standalone: true,
  imports: [
    EntityCreateDialogComponent,
    AsyncPipe,
    ReactiveFormsModule,
    MatFormField,
    MatIcon,
    MatInput,
    MatError,
    InfiniteScrollSelectorComponent,
    ProjectHistoryCardComponent,
    LocationComponent,
    EventReportProjectDeactivateComponent,
    MatButton,
    NgxPermissionsModule,
    CopyToClipboardComponent,
    RouteValidationComponent,
    InputTechniciansComponent,
    ProjectExportComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventReportProjectCreateComponent implements OnInit {
  private readonly store = inject(Store);
  private readonly dialogRef = inject(MatDialogRef<EventReportProjectCreateComponent>);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly organizationApiService = inject(OrganizationApiService);
  private readonly eventReportingApiService = inject(EventReportingApiService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly actions$ = inject(Actions);
  private readonly window = inject(WINDOW);
  private readonly eventReportProjectFormBuilder = inject(EventReportProjectFormBuilder);
  private readonly projectStatusUpdated$ = this.actions$.pipe(
    ofType(EventReportingActions.updateEventProjectStatusSuccess)
  );

  private blueprintFile: Maybe<File> = null;
  private fiberRouteFile: Maybe<File> = null;

  project = this.store.selectSignal(eventProjectsFeature.selectProjectById);
  createUpdatePending$ = this.store.select(eventProjectsFeature.selectIsProjectCreateUpdatePending);

  mode: DialogMode = 'create';
  form!: FormGroup<EventReportProjectForm>;
  submitBtnDisabled = true;
  isLocationInvalid = false;
  changed$ = new BehaviorSubject<boolean>(false);
  organizationFetchFn!: DataFetcherFn;
  notificationAssigneesFetchFn!: DataFetcherFn<string>;
  activeUserOrganizationsOptions: BaseEntity<number>[] = [];
  activeAssignedUsersToAlarmNotification:  BaseEntity<string>[] = [];

  reportDownloadInProgress = false;

  readonly projectNameMinLength = this.eventReportProjectFormBuilder.projectNameMinLength;
  readonly projectNameMaxLength = this.eventReportProjectFormBuilder.projectNameMaxLength;
  readonly descriptionMaxLength = this.eventReportProjectFormBuilder.descriptionMaxLength;
  readonly allowedPermissionsToEditMapping = [UserRole.APS_ADMIN, UserRole.APS_PE];

  get isGeographicalReferenceSelected(): boolean {
    return this.form?.get('mappingType')?.value === MappingType.map;
  }

  get isUpdateMode(): boolean {
    return this.mode === 'update';
  }

  ngOnInit(): void {
    this.form = this.eventReportProjectFormBuilder.buildForm(this.project()!);
    this.initOrganizationFetchFn();
    this.detectMode();
    if (this.isUpdateMode) {
      this.initNotificationAssigneesFetchFn(this.project()?.organization.id!);
      this.patchValue(mapErProjectDtoFormValue(this.project()!));
      this.toggleFormState();
    }
    this.listenFormChanges();
    this.activeUserOrganizationsOptions = this.project()?.organization ? [this.project()?.organization!] : [];
    this.activeAssignedUsersToAlarmNotification = this.project()?.alarmNotificationUsers ?
      (this.project()?.alarmNotificationUsers ?? []).map(v => ({ id: v.email!, name: v.email! })) :
      [];
  }

  close(): void {
    this.store.dispatch(EventReportingActions.closeEventProjectPreviewDialog());
  }

  onBlueprintChanged(file: File | null): void {
    this.blueprintFile = file;
  }

  onMapFieldsDataChanged(fieldsData: ProjectMapFieldsData): void {
    const project: Partial<EventReportProjectFormRawValue> = {
      mappingType: fieldsData.mappingType,
      latitude: fieldsData.latitude,
      longitude: fieldsData.longitude,
      address: fieldsData.address
    };
    this.patchValue(project);
  }

  onLocationStatusChanged(status: FormControlStatus): void {
    this.isLocationInvalid = status === 'INVALID';
  }

  handleReportDownloadStateChanges(inProgress: boolean): void {
    this.reportDownloadInProgress = inProgress;
    this.dialogRef.disableClose = inProgress;
  }

  openProjectInMobileApp(): void {
    const link = `${environment.mobileAppWebUrl}/event-reports-projects/${this.project()?.id}/map`;
    this.window.open(link, '_blank');
  }

  submit(): void {
    if (!this.form.valid) {
      return;
    }
    if (this.isUpdateMode) {
      const projectUpdateReq = mapRawValueToEventReportProjectUpdateReq(this.getFormRawValue(), this.project()!);
      this.store.dispatch(EventReportingActions.updateEventProject(projectUpdateReq, this.blueprintFile!, this.fiberRouteFile!));
    } else {
      const projectCreateReq = mapRawValueToEventReportProjectCreateReq(this.getFormRawValue());
      this.store.dispatch(EventReportingActions.createEventProject(projectCreateReq, this.blueprintFile!, this.fiberRouteFile!));
    }
  }

  private patchValue(project: Partial<EventReportProjectFormRawValue>): void {
    this.form.patchValue(project, { emitEvent: false });
    this.updateFormControlsState();
  }

  private getFormRawValue(): EventReportProjectFormRawValue {
    return this.form.getRawValue() as EventReportProjectFormRawValue;
  }

  private updateFormControlsState(): void {
    this.form.controls.webhookUrl.disable();
    if (this.form.controls.mappingType.value === MappingType.notSelected) {
      this.form.controls.technicians.setValue([]);
      this.form.controls.technicians.disable();
    } else {
      this.form.controls.technicians.enable();
    }
  }

  private listenFormChanges(): void {
    this.changed$.next(!isEventReportProjectsAreEqual(this.project()!, this.getFormRawValue()));

    this.form.valueChanges
      .pipe(
        tap(() => {
          this.changed$.next(!isEventReportProjectsAreEqual(this.project()!, this.getFormRawValue()));
          this.checkSubmitBtnDisabled();
          this.changeDetectorRef.markForCheck();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    this.form.controls.fiberRouteFile.valueChanges.pipe(
      tap((file) => {
        this.fiberRouteFile = file;
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();

    this.form.controls.organization.valueChanges.pipe(
      tap((orgId) => {
        this.resetAssignedUsersToAlarmNotification(orgId!);
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();

    this.form.statusChanges
      .pipe(
        tap(() => {
          this.dialogRef.disableClose = this.changed$.value;
          this.checkSubmitBtnDisabled();
          this.changeDetectorRef.markForCheck();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private detectMode(): void {
    if (this.project()?.id) {
      this.mode = 'update';
      this.listenProjectStatusChanges();
    }
    this.isLocationInvalid = this.mode === 'create';
  }

  private initOrganizationFetchFn(): void {
    this.organizationFetchFn = getOrganizationsFetchFn(this.organizationApiService);
  }

  private initNotificationAssigneesFetchFn(organizationId: number): void {
    this.notificationAssigneesFetchFn = getNotificationAssigneesFetchFn(this.eventReportingApiService, this.project()?.id!, organizationId);
  }


  private checkSubmitBtnDisabled(): void {
    this.submitBtnDisabled = this.form.invalid
      || this.isLocationInvalid
      || !this.changed$.value
      || this.form.controls.name.pending;
  }

  private toggleFormState(): void {
    if (this.project()?.isActive) {
      this.form.enable();
    } else {
      this.form.disable();
    }
    if (!this.isGeographicalReferenceSelected) {
      this.form.controls.assignedUsersToAlarmNotification.disable();
    }
    this.form.controls.webhookUrl.disable();
  }

  private resetAssignedUsersToAlarmNotification(organizationId: number): void {
    this.form.controls.assignedUsersToAlarmNotification.setValue([]);
    this.activeAssignedUsersToAlarmNotification = [];
    this.initNotificationAssigneesFetchFn(organizationId);
  }

  private listenProjectStatusChanges(): void {
    this.projectStatusUpdated$.pipe(
      tap(({ isActive }) => {
        if (isActive) {
          this.form.enable();
        } else {
          this.form.disable();
        }
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }
}
