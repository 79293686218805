import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {APP_INITIALIZER, ApplicationConfig, importProvidersFrom, Provider} from '@angular/core';
import {provideAnimations} from '@angular/platform-browser/animations';
import {provideRouter} from '@angular/router';
import {environment} from '@environments/environment';
import {provideEffects} from '@ngrx/effects';
import {provideState, provideStore, Store} from '@ngrx/store';
import {provideStoreDevtools} from '@ngrx/store-devtools';
import {KeycloakBearerInterceptor, KeycloakService} from 'keycloak-angular';
import {provideEnvironmentNgxMask} from 'ngx-mask';
import {NgxPermissionsModule} from 'ngx-permissions';
import {combineLatest,filter, switchMap, take} from 'rxjs';

import {eventProjectsFeature} from '@features/event-reporting/store';
import {organizationsFeature} from '@features/organizations/store';
import {assetProjectsFeature} from '@features/projects/store';
import {usersFeature} from '@features/users/store';

import {selectApplications, selectCountries} from './store/static-data';
import {UserInfoActions} from './store/user-info';
import {selectUserProfile} from './store/user-info/user-info.selectors';
import {appRoutes} from './app.routes';
import {AppState, rootReducers, storeEffects} from './store';

const KeycloakInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeKeycloak,
  deps: [KeycloakService],
  multi: true
};

const KeycloakBearerInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: KeycloakBearerInterceptor,
  multi: true
};

const InitializePermissionsProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeUserState,
  deps: [Store],
  multi: true
};

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.keycloakUrl,
        realm: environment.keycloakRealm,
        clientId: environment.keycloakClientId
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html'
      }
    }).catch((e: unknown) => {
      if ((e as { error: string })?.error === 'access_denied') {
        void keycloak.logout();
      }
    });
}

function initializeUserState(store: Store<AppState>) {
  return () => {
    store.dispatch(UserInfoActions.init());

    return store.select(selectUserProfile).pipe(
      filter(Boolean),
      switchMap(() => combineLatest([
        store.select(selectApplications).pipe(filter(Boolean), take(1)),
        store.select(selectCountries).pipe(filter(Boolean), take(1))
      ])),
      take(1)
    );
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    KeycloakInitializerProvider,
    KeycloakBearerInterceptorProvider,
    KeycloakService,
    provideStore(rootReducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictStateSerializability: true,
        strictActionImmutability: false,
        strictActionSerializability: false,
        strictActionTypeUniqueness: true
      }
    }),
    provideState(organizationsFeature),
    provideState(assetProjectsFeature),
    provideState(eventProjectsFeature),
    provideState(usersFeature),
    provideEffects(storeEffects),
    ...(!environment.production ? [
      provideStoreDevtools({ maxAge: 25, logOnly: false })
    ] : []),
    importProvidersFrom(
      NgxPermissionsModule.forRoot()
    ),
    provideEnvironmentNgxMask(),
    InitializePermissionsProvider,
    provideRouter(appRoutes)
  ]
};
