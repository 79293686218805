<form [formGroup]="form" autocomplete="off">
  <ng-container formArrayName="serialNumbers">
    @for (serialNumber of serialNumbers.controls; let i = $index; track serialNumber) {
      <div class="flex items-center">
        <div>
          <label>{{ label }}</label>
          <mat-form-field class="w-full" appearance="outline">
            <mat-icon matPrefix class="color-medium" svgIcon="tools" />
            <input matInput
                   data-test="e2e-serial-number-input"
                   [formControl]="serialNumber"
                   type="text"
                   [placeholder]="placeholder">
            @if (serialNumbers.controls[i].invalid) {
              <mat-error>
                @if (serialNumbers.controls[i].errors?.['required']) {
                  <span data-test="e2e-serial-required-msg">This field is required</span>
                }
                @if (serialNumbers.controls[i].errors?.['invalidSn']) {
                  <span data-test="e2e-serial-invalid-format-msg">Invalid format</span>
                }
              </mat-error>
            }
          </mat-form-field>
        </div>
        @if (serialNumbers.controls.length > 1) {
          <button type="button" mat-icon-button color="warn" data-test="e2e-remove-serial-btn" [disabled]="disabled" (click)="removeSerialNumber(i)">
            <mat-icon svgIcon="delete" />
          </button>
        }
      </div>
    }
  </ng-container>
  <button mat-button type="button" color="primary" data-test="e2e-add-serial-btn" [disabled]="disabled" (click)="addSerialNumber()">
    <mat-icon slot="start" svgIcon="plus" />
    Add Serial
  </button>
</form>
