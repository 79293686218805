import {EventReportingEffects} from '@features/event-reporting/store';
import {OrganizationsEffects} from '@features/organizations/store';
import {ProjectsEffects} from '@features/projects/store';
import {UsersEffects} from '@features/users/store';

import {StaticDataEffects} from './static-data';
import {UserInfoEffects} from './user-info';

export const storeEffects = [
  UserInfoEffects,
  StaticDataEffects,
  OrganizationsEffects,
  ProjectsEffects,
  EventReportingEffects,
  UsersEffects,
];
