import {AsyncPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, ElementRef, inject, Input, OnInit, ViewChild} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from '@angular/forms';
import {MatButton} from '@angular/material/button';
import {MatDialog} from '@angular/material/dialog';
import {MatError, MatFormField, MatPrefix, MatSuffix} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatInput} from '@angular/material/input';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {MatTooltip} from '@angular/material/tooltip';
import {environment} from '@environments/environment';
import {Store} from '@ngrx/store';
import {NgxMaskDirective} from 'ngx-mask';
import {combineLatest,defaultIfEmpty, filter, map, take, tap} from 'rxjs';

import {Maybe} from '@core/common';
import {eventProjectsFeature, EventReportingActions} from '@features/event-reporting/store';
import {EventReportProject, EventReportProjectForm, Project, ProjectForm} from '@features/projects/models';
import {assetProjectsFeature, ProjectsActions} from '@features/projects/store';
import {ConfirmActionDialogComponent} from '@shared/components';
import {ConfirmActionDialogData} from '@shared/models';
import {FileUtilsService} from '@shared/utils';

@Component({
  selector: 'aps-route-validation',
  templateUrl: './route-validation.component.html',
  styleUrls: ['./route-validation.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgxMaskDirective,
    MatIcon,
    MatTooltip,
    MatButton,
    MatError,
    MatSlideToggle,
    MatFormField,
    MatPrefix,
    MatSuffix,
    MatInput,
    AsyncPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RouteValidationComponent implements OnInit {
  private readonly fileUtils = inject(FileUtilsService);
  private readonly dialog = inject(MatDialog);
  private readonly store = inject(Store);

  @Input() parentForm!: FormGroup<ProjectForm | EventReportProjectForm>;
  @Input() project!: Project | EventReportProject;
  @Input() routeValidationActive = true;
  @Input() attachDisabled = false;
  @Input() isEventReportProject = false;

  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;
  selectedFile: Maybe<File> = null;
  allowedFiberRouteFilesExtensions = environment.allowedFiberRouteFilesExtensions;
  uploadRouteFileTooltipText = 'Upload a KML, KMZ or CSV file with the coordinates of the fiber route to display the route as a reference in the app. A CSV reference route can not be exported as KML file';
  positiveSecLengthToleranceTooltipText = 'Tolerance of how much a fiber section length may exceed the GPS section length in order to pass validation';
  negativeSecLengthToleranceTooltipText = 'Tolerance of how much shorter a fiber section length is allowed to be compared to the GPS section length in order to pass validation';
  projectCreateUpdateErrorMsg$ = combineLatest([
    this.store.select(assetProjectsFeature.selectProjectCreateUpdateErrorMsg).pipe(defaultIfEmpty(null)),
    this.store.select(eventProjectsFeature.selectProjectCreateUpdateErrorMsg).pipe(defaultIfEmpty(null))
  ]).pipe(
    map(([assetProjectErrorMsg, eventReportErrorMsg]) => assetProjectErrorMsg ?? eventReportErrorMsg),
  );

  get fiberRouteFileName(): string {
    return (this.project as Project)?.reportFileName ?? (this.project as EventReportProject)?.fiberRouteFileName;
  }

  ngOnInit(): void {
    if (this.fiberRouteFileName) {
      this.selectedFile = { name: this.fiberRouteFileName } as File;
    }
    this.updateAllowedFileExtensionsAndTooltips();
  }

  clearFile(): void {
    this.selectedFile = null;
    this.fileInput.nativeElement.value = null;
    this.parentForm.controls.fiberRouteFile.setValue(null);
    if (this.isEventReportProject) {
      (this.parentForm as FormGroup<EventReportProjectForm>).controls.fiberRouteFileName.setValue(null);
      this.store.dispatch(EventReportingActions.clearProjectCreateUpdateErrorMsg());
    } else {
      (this.parentForm as FormGroup<ProjectForm>).controls.reportFileName.setValue('');
      this.store.dispatch(ProjectsActions.clearProjectCreateUpdateErrorMsg());
    }
  }

  onFileSelected(event: any): void {
    this.parentForm.controls.fiberRouteFile.setErrors({ invalidExt: false });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (this.isValidFileExtension(event?.files.item(0))) {
      this.selectedFile = event?.files.item(0);
      this.parentForm.controls.fiberRouteFile.setValue(this.selectedFile);
      if (this.isEventReportProject) {
        (this.parentForm as FormGroup<EventReportProjectForm>).controls.fiberRouteFileName.setValue(this.selectedFile!.name);
      } else {
        (this.parentForm as FormGroup<ProjectForm>).controls.reportFileName.setValue(this.selectedFile!.name);
      }
    } else {
      this.parentForm.controls.fiberRouteFile.setErrors({ invalidExt: true });
    }
  }

  browseFile(): void {
    this.allowedFiberRouteFilesExtensions = environment.allowedFiberRouteFilesExtensions;
    if (this.fiberRouteFileName) {
      const fileExt = this.fileUtils.getFileExtensionFromName(this.fiberRouteFileName);
      if (fileExt === 'csv') {
        this.allowedFiberRouteFilesExtensions = ['.csv'];
      }
      const confirmActionDialogData: ConfirmActionDialogData = fileExt === 'csv' ? {
        title: 'Reference route and Asset POIs will be replaced.',
        description: 'Uploading a new reference route with asset POIs will replace the current reference route and all asset POIs. Would you like to continue?'
      } : {
        title: 'Reference route will be replaced.',
        description: 'Would you like to continue?'
      };
      this.showReplaceKmlKmzFileReplacePopup(confirmActionDialogData);
    } else {
      this.fileInput.nativeElement.click();
    }
  }

  private isValidFileExtension(file: File): boolean {
    const extension = this.fileUtils.getFileExtensionFromName(file.name);
    return this.allowedFiberRouteFilesExtensions.findIndex(v => v === `.${extension}`) !== -1;
  }

  private showReplaceKmlKmzFileReplacePopup(dialogData: ConfirmActionDialogData): void {
    this.dialog.open<ConfirmActionDialogComponent, ConfirmActionDialogData>(ConfirmActionDialogComponent, {
      width: '504px',
      data: dialogData
    }).afterClosed()
      .pipe(
        take(1),
        filter(Boolean),
        tap(() => {
          this.fileInput.nativeElement.click();
        })
      )
      .subscribe();
  }

  private updateAllowedFileExtensionsAndTooltips(): void {
    if (this.isEventReportProject) {
      this.allowedFiberRouteFilesExtensions = this.allowedFiberRouteFilesExtensions.filter(ext => ext !== '.csv');
      this.uploadRouteFileTooltipText = 'Upload a KML or KMZ file with the coordinates of the fiber route to display the route as a reference in the app.';
    }
  }
}
