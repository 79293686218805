import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, finalize, Observable} from 'rxjs';

import {LocationFeature} from '@shared/models';

import {LocationApiService} from './location-api.service';

@Injectable({ providedIn: 'root' })
export class LocationService {
  private readonly locationApiService = inject(LocationApiService);
  private _pending$ = new BehaviorSubject(false);

  pending$ = this._pending$.asObservable();

  findLocation(searchStr: string): Observable<LocationFeature[]> {
    this._pending$.next(true);
    return this.locationApiService.findLocation(searchStr)
      .pipe(finalize(() => {
        this._pending$.next(false);
      }));
  }
}
