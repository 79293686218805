import {ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormGroup, NonNullableFormBuilder, ReactiveFormsModule} from '@angular/forms';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatFormField, MatSuffix} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatInput} from '@angular/material/input';
import {Store} from '@ngrx/store';
import {debounceTime, tap} from 'rxjs';

import {Maybe} from '@core/common';
import {LocalStorageService} from '@core/services';
import {EventReportingActions} from '@features/event-reporting/store';
import {ProjectsActions} from '@features/projects/store';
import {UserRole} from '@features/users/models';
import {ProjectFilter} from '@shared/models';

@Component({
  selector: 'aps-project-filter',
  templateUrl: './project-filter.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatCheckbox,
    MatIcon,
    MatSuffix,
    MatFormField,
    ReactiveFormsModule,
    MatInput
  ]
})
export class ProjectFilterComponent implements OnInit {
  private readonly store = inject(Store);
  private readonly localStorage = inject(LocalStorageService);
  private readonly fb = inject(NonNullableFormBuilder);
  private readonly destroyRef = inject(DestroyRef);
  @Input() isAssetMappingProject = true;
  readonly userRole = UserRole;
  form!: FormGroup;

  ngOnInit(): void {
    this.form = this.buildFilterForm();
    this.watchForFilterChanges();
    this.checkIfFilterSet();
  }

  clearSearchByNameFilter(): void {
    const nameCtrl =  this.form.get('name');
    if (nameCtrl) {
      nameCtrl.setValue('');
    }
  }

  private buildFilterForm(): FormGroup {
    const filterState = this.getFilterStateFromStore();
    return this.fb.group({
      name: this.fb.control(filterState?.name ?? ''),
      showActiveProjects: this.fb.control(filterState?.isActive ?? false),
    });
  }

  private watchForFilterChanges(): void {
    this.form.valueChanges.pipe(
      debounceTime(600),
      tap((value) => {
        const filterValue = { name: value.name, isActive: value.showActiveProjects } as ProjectFilter;
        if (this.isAssetMappingProject) {
          this.store.dispatch(ProjectsActions.setAssetProjectFilter(filterValue));
        } else {
          this.store.dispatch(EventReportingActions.setEventProjectFilter(filterValue));
        }
        this.storeFilterState(filterValue);
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }

  private storeFilterState(filterValue: ProjectFilter): void {
    const storageKey = this.getFilterStateStorageKey();
    this.localStorage.addItem(storageKey, JSON.stringify(filterValue));
  }

  private getFilterStateFromStore(): Maybe<ProjectFilter> {
    const storageKey = this.getFilterStateStorageKey();
    const storageData = this.localStorage.getItem(storageKey);
    return storageData ? JSON.parse(storageData) as ProjectFilter : null;
  }

  private getFilterStateStorageKey(): string {
    return this.isAssetMappingProject ? 'asset_mapping_filter' : 'event_reporting_filter';
  }

  private checkIfFilterSet(): void {
    const rawValue = this.form.getRawValue();
    if (rawValue.name.length > 0 || rawValue.showActiveProjects) {
      const filterValue = { name: rawValue.name, isActive: rawValue.showActiveProjects } as ProjectFilter;
      this.store.dispatch(ProjectsActions.setAssetProjectFilter(filterValue));
    }
  }
}
