<div class="flex flex-col justify-between">
  <h6 class="dialog-section-title mb-2">Route Validation</h6>
  <div class="bg-super-light-gray rounded-xl p-4">
    <div class="flex flex-col justify-between">
      <form [formGroup]="parentForm" autocomplete="off">
        <div class="flex flex-col">
          <p class="text-sm">Upload Reference Fiber Route
            <mat-icon class="size-xs cursor-pointer mr-2" svgIcon="help"
                      [matTooltip]="uploadRouteFileTooltipText" />
          </p>
          <div class="file-input-container mt-1">
            <div class="input">
              <p class="pointer-events-none">{{ selectedFile?.name || 'Please select a file' }}</p>
              @if (selectedFile && !this.fiberRouteFileName) {
                <button class="absolute right-1 top-1" (click)="clearFile()">
                  <mat-icon svgIcon="close" />
                </button>
              }
            </div>
            <button mat-flat-button color="primary" class="w-44" [disabled]="attachDisabled" (click)="browseFile()">
              Browse file
            </button>
            <input type="file" data-test="e2e-route-file-upload-input" [accept]="allowedFiberRouteFilesExtensions" #fileInput hidden
                   (change)="onFileSelected($event.target)">
          </div>
          @if (projectCreateUpdateErrorMsg$ | async; as errorMsg) {
            <mat-error>
              <span class="text-xs">{{errorMsg}}</span>
            </mat-error>
          }
          @if (parentForm?.controls?.fiberRouteFile?.errors?.['invalidExt']) {
            <mat-error>
              <span
                class="text-xs">Unsupported file format. You can attach the following file formats: {{ allowedFiberRouteFilesExtensions.join(', ') }}</span>
            </mat-error>
          }
        </div>
        @if (routeValidationActive) {
          <div class="flex justify-between mt-5">
            <p class="text-sm">Section Length Validation</p>
            <mat-slide-toggle formControlName="routeValidation"
                              data-test="e2e-section-length-toggle"
                              class="col-span-6"
                              color="primary" />
          </div>
          <div class="flex items-center justify-between mt-3">
            <p class="text-sm">Positive Section Length Tolerance
              <mat-icon class="size-xs cursor-pointer mr-2" svgIcon="help"
                        [matTooltip]="positiveSecLengthToleranceTooltipText" />
            </p>
            <mat-form-field class="w-24 tolerance-field" appearance="outline">
              <input type="number"
                     mask="000"
                     matInput
                     data-test="e2e-positive-section-length"
                     formControlName="positiveSectionLengthTolerance"
                     [validation]="false">
              <span matTextPrefix class="mr-1">+</span>
              <span matTextSuffix>%</span>
            </mat-form-field>
          </div>
          <div class="flex items-center justify-between mt-3">
            <p class="text-sm">Negative Section Length Tolerance
              <mat-icon class="size-xs cursor-pointer mr-2" svgIcon="help"
                        [matTooltip]="negativeSecLengthToleranceTooltipText" />
            </p>
            <mat-form-field class="w-24 tolerance-field" appearance="outline">
              <input type="number"
                     mask="000"
                     matInput
                     data-test="e2e-negative-section-length"
                     formControlName="negativeSectionLengthTolerance"
                     [validation]="false">
              <span matTextPrefix class="mr-1">-</span>
              <span matTextSuffix>%</span>
            </mat-form-field>
          </div>
        }
      </form>
    </div>
  </div>
</div>
