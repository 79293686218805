import {createActionGroup, emptyProps} from '@ngrx/store';

import {ListResponse, Maybe, Pagination} from '@core/common';
import {
  EventReportProject,
  EventReportProjectCreateReq,
  EventReportProjectListEntry,
  EventReportProjectUpdateReq
} from '@features/projects/models';
import {AgGridSortState, ProjectFilter} from '@shared/models';

export const EventReportingActions = createActionGroup({
  source: 'Event Projects',
  events: {
    'Load event projects': emptyProps(),
    'Load event projects success': (projects: ListResponse<EventReportProjectListEntry>) => ({ projects }),
    'Load event projects failure': emptyProps(),
    'Open event project preview dialog': (id: number, errorMsg?: string) => ({ id, errorMsg }),
    'Close event project preview dialog': emptyProps(),
    'Load event project by id': (id: number) => ({ id }),
    'Load event project by id success': (project: EventReportProject) => ({ project }),
    'Load event project by id failure': emptyProps(),
    'Create event project': (req: EventReportProjectCreateReq, blueprintFile: File, fiberRouteFile: File) => ({
      req,
      blueprintFile,
      fiberRouteFile
    }),
    'Create event project success': emptyProps(),
    'Create event project failure': emptyProps(),
    'Update event project': (req: EventReportProjectUpdateReq, blueprintFile: File, fiberRouteFile: File) => ({
      req,
      blueprintFile,
      fiberRouteFile
    }),
    'Update event project success': emptyProps(),
    'Update event project failure': (errorMsg?: string) => ({ errorMsg }),
    'Update event project status': (isActive: boolean) => ({ isActive }),
    'Update event project status success': (isActive: boolean) => ({ isActive }),
    'Update event project status failure': emptyProps(),
    'Update event project status cancel': emptyProps(),
    'Update event projects pagination': (pagination: Pagination) => ({ pagination }),
    'Update event projects sorting': (sorting: Maybe<AgGridSortState[]>) => ({ sorting }),
    'Set event project filter': (projectFilter: Maybe<ProjectFilter>) => ({ projectFilter }),
    'Clear event project by id': emptyProps(),
    'Clear project create update error msg': emptyProps(),
    'Reset': emptyProps()
  }
});
