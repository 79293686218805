import {ChangeDetectionStrategy, Component, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {PageEvent} from '@angular/material/paginator';
import {Store} from '@ngrx/store';
import {ColDef, GridOptions, RowClickedEvent} from 'ag-grid-community';
import {NgxPermissionsModule} from 'ngx-permissions';
import {combineLatest, filter, map} from 'rxjs';

import {INITIAL_PAGINATION_CONFIG, Maybe} from '@core/common';
import {
  OrganizationListItem,
  OrganizationStatus
} from '@features/organizations/models';
import {OrganizationsActions, organizationsFeature} from '@features/organizations/store';
import {UserRole} from '@features/users/models';
import {AgGridWrapperComponent, BaseListComponent} from '@shared/components';
import {AgGridSortState, AgGridState} from '@shared/models';


@Component({
  selector: 'aps-organizations-list',
  templateUrl: './organizations-list.component.html',
  standalone: true,
  imports: [AgGridWrapperComponent, NgxPermissionsModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationsListComponent extends BaseListComponent implements OnInit, OnDestroy {
  private readonly store = inject(Store);

  @Input() status: Maybe<OrganizationStatus> = null;
  @Input() columnDefs!: ColDef[];
  readonly userRole = UserRole;

  gridState!: AgGridState<OrganizationListItem>;

  gridOptions: GridOptions = {
    multiSortKey: 'ctrl',
    rowClass: ['e2e-ag-grid-row'],
    rowClassRules: {
      'color-medium': '!data.isActive'
    }
  };

  override ngOnInit(): void {
    super.ngOnInit();
    this.store.dispatch(OrganizationsActions.updateOrganizationsPagination({
      ...INITIAL_PAGINATION_CONFIG,
      pageSize: this.pageSize
    }));
    if (this.status) {
      this.store.dispatch(OrganizationsActions.setOrganizationsListPreferences({ status: this.status }));
    }
    this.initGridSetting();
  }

  ngOnDestroy(): void {
    this.store.dispatch(OrganizationsActions.reset());
  }

  onSortChanged(event: AgGridSortState[] | null): void {
    this.store.dispatch(OrganizationsActions.updateOrganizationsSorting(event));
  }

  onRowClicked(event: RowClickedEvent): void {
    const listItem: OrganizationListItem = event.data;
    this.store.dispatch(OrganizationsActions.openOrganizationPreviewDialog(listItem.id));
  }

  override updatePagination(event: PageEvent): void {
    super.updatePagination(event);
    this.store.dispatch(OrganizationsActions.updateOrganizationsPagination(event));
  }

  private initGridSetting(): void {
    this.gridState = {
      pending$:  combineLatest([
        this.store.select(organizationsFeature.selectIsOrganizationsPending),
        this.store.select(organizationsFeature.selectIsOrganizationByIdPending)
      ]).pipe(
        map(([pendingList, getByIdPending]) => pendingList || getByIdPending)
      ),
      data$: this.store.select(organizationsFeature.selectOrganizations).pipe(filter(Boolean)),
    };
  }
}
