import {AsyncPipe} from '@angular/common';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormGroup, ReactiveFormsModule} from '@angular/forms';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from '@angular/material/expansion';
import {MatError, MatFormField} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatInput} from '@angular/material/input';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {MatOption, MatSelect} from '@angular/material/select';
import {Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {BehaviorSubject, tap} from 'rxjs';

import {DialogMode} from '@core/common';
import {
  OrganizationCreateUpdateReq,
  OrganizationForm,
  OrganizationStatus
} from '@features/organizations/models';
import {OrganizationsActions, organizationsFeature} from '@features/organizations/store';
import {
  isOrganizationsAreEqual,
  OrganizationFormBuilder
} from '@features/organizations/utils';
import {EntityCreateDialogComponent} from '@shared/components';
import {UserCardComponent} from '@shared/components/user-card';

import {selectApplications, selectCountries} from '../../../../store/static-data';
import {OrganizationDeactivateComponent} from '../organization-deactivate';


@Component({
  selector: 'aps-organization-create',
  templateUrl: './organization-create.component.html',
  styleUrls: ['./organization-create.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    EntityCreateDialogComponent,
    UserCardComponent,
    OrganizationDeactivateComponent,
    MatProgressSpinner,
    AsyncPipe,
    MatFormField,
    MatIcon,
    MatInput,
    MatSelect,
    MatError,
    MatOption,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelTitle,
    MatExpansionPanelHeader
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationCreateComponent implements OnInit {
  private readonly store = inject(Store);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly organizationFormBuilder = inject(OrganizationFormBuilder);
  private readonly destroyRef = inject(DestroyRef);
  private readonly actions$ = inject(Actions);
  private readonly organizationStatusUpdated$ = this.actions$.pipe(
    ofType(OrganizationsActions.updateOrganizationStatusSuccess)
  );

  mode: DialogMode = 'create';
  form!: FormGroup<OrganizationForm>;
  submitBtnDisabled = true;
  organization = this.store.selectSignal(organizationsFeature.selectOrganizationById);
  createUpdatePending$ = this.store.select(organizationsFeature.selectIsOrganizationCreateUpdatePending);
  countries$ = this.store.select(selectCountries);
  applications$ = this.store.select(selectApplications);
  changed$ = new BehaviorSubject<boolean>(false);

  readonly organizationNameMinLength = this.organizationFormBuilder.organizationNameMinLength;
  readonly organizationNameMaxLength = this.organizationFormBuilder.organizationNameMaxLength;
  readonly referenceCodeMaxLength = this.organizationFormBuilder.referenceCodeMaxLength;
  readonly locationMaxLength = this.organizationFormBuilder.locationMaxLength;
  readonly commentMaxLength = this.organizationFormBuilder.commentMaxLength;

  get isUpdateMode(): boolean {
    return this.mode === 'update';
  }

  ngOnInit(): void {
    this.form = this.organizationFormBuilder.buildForm(this.organization()!);
    this.form.patchValue(this.organization()!);
    this.detectMode();
    this.listenFormChanges();
  }

  submit(): void {
    if (!this.form.valid) {
      return;
    }
    this.form.disable();
    const createUpdateDto = this.getOrganizationCreateUpdateDto();

    if (this.isUpdateMode) {
      this.store.dispatch(OrganizationsActions.updateOrganization(createUpdateDto));
    } else {
      this.store.dispatch(OrganizationsActions.createOrganization(createUpdateDto));
    }
  }

  close(): void {
    this.store.dispatch(OrganizationsActions.closeOrganizationPreviewDialog());
  }

  private listenFormChanges(): void {
    this.changed$.next(!isOrganizationsAreEqual(this.organization()!, this.getOrganizationCreateUpdateDto()));
    this.form.valueChanges
      .pipe(
        tap(() => {
          this.changed$.next(!isOrganizationsAreEqual(this.organization()!, this.getOrganizationCreateUpdateDto()));
          this.checkSubmitBtnDisabled();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    this.form.statusChanges
      .pipe(
        tap(() => {
          this.checkSubmitBtnDisabled();
          this.changeDetectorRef.markForCheck();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private detectMode(): void {
    if (this.organization()?.id) {
      this.mode = 'update';
      this.form.controls.referenceCode.disable();

      this.listenOrganizationStatusChanges();

      if (this.organization()?.status === OrganizationStatus.active) {
        this.enableForm();
      } else {
        this.form.disable();
      }
    }
  }

  private enableForm(): void {
    this.form.enable();
    if (this.mode === 'update') {
      this.form.controls.referenceCode.disable();
    }
  }

  private getOrganizationCreateUpdateDto(): OrganizationCreateUpdateReq {
    return {
      ...this.form.getRawValue()
    } as OrganizationCreateUpdateReq;
  }

  private checkSubmitBtnDisabled(): void {
    this.submitBtnDisabled = this.form.invalid
      || !this.changed$.value
      || this.form.controls.organizationName.pending
      || this.form.controls.referenceCode.pending
      || this.organization()?.status === OrganizationStatus.inactive;
  }

  private listenOrganizationStatusChanges(): void {
    this.organizationStatusUpdated$.pipe(
      tap(({ status }) => {
        if (status === OrganizationStatus.active) {
          this.enableForm();
        } else {
          this.form.disable();
        }
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }
}
