import {AsyncPipe} from '@angular/common';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormControlStatus, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {MatButton} from '@angular/material/button';
import {MatDialogRef} from '@angular/material/dialog';
import {MatError, MatFormField} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatInput} from '@angular/material/input';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {environment} from '@environments/environment';
import {Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {NgxMaskDirective} from 'ngx-mask';
import {NgxPermissionsModule} from 'ngx-permissions';
import {
  BehaviorSubject,
  tap
} from 'rxjs';

import {BaseEntity, DataFetcherFn, Maybe, RawFormValue, WINDOW} from '@core/common';
import {OrganizationApiService} from '@features/organizations/services';
import {getOrganizationsFetchFn} from '@features/organizations/utils';
import {InputProjectStatusComponent} from '@features/projects/components/input-project-status';
import {InputSerialNumbersComponent} from '@features/projects/components/input-serial-numbers';
import {InputTechniciansComponent} from '@features/projects/components/input-technicians';
import {ProjectDeactivateComponent} from '@features/projects/components/project-deactivate';
import {ProjectExportComponent} from '@features/projects/components/project-export';
import {RouteValidationComponent} from '@features/projects/components/route-validation';
import {
  MappingType,
  ProjectForm,
  ProjectMapFieldsData,
  ProjectProgress
} from '@features/projects/models';
import {assetProjectsFeature, ProjectsActions} from '@features/projects/store';
import {
  isProjectsAreEqual,
  mapFormValueToProjectCreateDto, mapFormValueToProjectUpdateDto,
  mapProjectToFormRawValue,
  ProjectFormBuilder
} from '@features/projects/utils';
import {UserRole} from '@features/users/models';
import {
  CopyToClipboardComponent,
  EntityCreateDialogComponent,
  InfiniteScrollSelectorComponent
} from '@shared/components';
import {LocationComponent} from '@shared/components/location';
import {ProjectHistoryCardComponent} from '@shared/components/project-history-card';
import {BtnPendingDirective} from '@shared/directives';

type DialogMode = 'create' | 'update';

@Component({
  selector: 'aps-project-create',
  templateUrl: './project-create.component.html',
  styleUrls: ['./project-create.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InfiniteScrollSelectorComponent,
    CopyToClipboardComponent,
    InputTechniciansComponent,
    InputProjectStatusComponent,
    InputSerialNumbersComponent,
    ProjectExportComponent,
    RouteValidationComponent,
    LocationComponent,
    MatIcon,
    MatProgressSpinner,
    AsyncPipe,
    MatError,
    MatFormField,
    MatInput,
    NgxMaskDirective,
    MatButton,
    BtnPendingDirective,
    ProjectDeactivateComponent,
    NgxPermissionsModule,
    EntityCreateDialogComponent,
    ProjectHistoryCardComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectCreateComponent implements OnInit {
  private readonly store = inject(Store);
  private readonly dialogRef = inject(MatDialogRef<ProjectCreateComponent>);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly window = inject(WINDOW);
  private readonly actions$ = inject(Actions);
  private readonly destroyRef = inject(DestroyRef);
  private readonly organizationApiService = inject(OrganizationApiService);
  private readonly projectFormBuilder = inject(ProjectFormBuilder);
  private readonly projectStatusUpdated$ = this.actions$.pipe(
    ofType(ProjectsActions.updateAssetProjectStatusSuccess)
  );

  private blueprintFile: Maybe<File> = null;
  private fiberRouteFile!: File;

  project = this.store.selectSignal(assetProjectsFeature.selectProjectById);
  createUpdatePending$ = this.store.select(assetProjectsFeature.selectIsProjectCreateUpdatePending);

  mode: DialogMode = 'create';
  form!: FormGroup<ProjectForm>;
  submitBtnDisabled = true;
  changed$ = new BehaviorSubject<boolean>(false);
  organizationFetchFn!: DataFetcherFn;
  activeUserOrganizationsOptions: BaseEntity<number>[] = [];
  isLocationInvalid = false;
  canViewApsComment = false;
  reportDownloadInProgress = false;
  projectSerialNumbers: string[] = [];

  readonly projectNameMinLength = this.projectFormBuilder.projectNameMinLength;
  readonly projectNameMaxLength = this.projectFormBuilder.projectNameMaxLength;
  readonly descriptionMaxLength = this.projectFormBuilder.descriptionMaxLength;
  readonly commentMaxLength = this.projectFormBuilder.commentMaxLength;
  readonly ocNumberMaxLength = this.projectFormBuilder.ocNumberMaxLength;
  readonly distanceMax = this.projectFormBuilder.distanceMax;
  readonly poNumberMaxLength = this.projectFormBuilder.poNumberMaxLength;
  readonly locationMaxLength = this.projectFormBuilder.locationMaxLength;
  readonly allowedPermissionsToEditMapping = [UserRole.APS_ADMIN, UserRole.APS_PE];
  readonly allowedPermissionsToActivateProject = [UserRole.APS_ADMIN, UserRole.APS_PE, UserRole.PE];
  readonly allowedPermissionsToViewApsComment = [UserRole.APS_PE, UserRole.APS_ADMIN];

  get isGeographicalReferenceSelected(): boolean {
    return this.form?.get('mappingType')?.value === MappingType.map;
  }

  get isUpdateMode(): boolean {
    return this.mode === 'update';
  }

  ngOnInit() {
    this.form = this.projectFormBuilder.buildForm(this.project()!);
    this.initOrganizationFetchFn();
    this.patchValue(mapProjectToFormRawValue(this.project()!));
    this.detectMode();
    this.listenFormChanges();
    this.activeUserOrganizationsOptions = this.project()?.organization ? [this.project()?.organization!] : [];
    this.projectSerialNumbers = this.project()?.serialNumbers ?? [];
  }

  submit(): void {
    if (!this.form.valid) {
      return;
    }
    if (this.isUpdateMode) {
      const projectUpdateReq = mapFormValueToProjectUpdateDto(this.project()?.id!, this.form.getRawValue());
      this.store.dispatch(ProjectsActions.updateAssetProject(projectUpdateReq, this.blueprintFile!, this.fiberRouteFile));
    } else {
      const projectCreateReq = mapFormValueToProjectCreateDto(this.form.getRawValue());
      this.store.dispatch(ProjectsActions.createAssetProject(projectCreateReq, this.blueprintFile!, this.fiberRouteFile));
    }
  }

  openProjectInMobileApp(): void {
    const link = `${environment.mobileAppWebUrl}/projects/${this.project()?.id!}/overview`;
    this.window.open(link, '_blank');
  }

  close(): void {
    this.store.dispatch(ProjectsActions.closeAssetProjectPreviewDialog());
  }

  onBlueprintChanged(file: File | null): void {
    this.blueprintFile = file;
  }

  onMapFieldsDataChanged(fieldsData: ProjectMapFieldsData): void {
    const project: Partial<RawFormValue<ProjectForm>> = {
      progress: this.form.controls.progress.getRawValue()!,
      ...fieldsData
    };
    this.patchValue(project);
  }

  onLocationStatusChanged(status: FormControlStatus): void {
    this.isLocationInvalid = status === 'INVALID';
  }

  handleReportDownloadStateChanges(inProgress: boolean): void {
    this.reportDownloadInProgress = inProgress;
    this.dialogRef.disableClose = inProgress;
  }

  private patchValue(project: Partial<RawFormValue<ProjectForm>>): void {
    this.form.patchValue(project);
    this.updateFormControlsState(project);
  }

  private updateFormControlsState(project: Partial<RawFormValue<ProjectForm>>): void {
    this.form.controls.webhookUrl.disable();
    if (project?.routeValidation) {
      this.form.controls.positiveSectionLengthTolerance.enable();
      this.form.controls.negativeSectionLengthTolerance.enable();
    }
    if (this.form.controls.mappingType.value === MappingType.notSelected) {
      this.form.controls.technicians.setValue([]);
      this.form.controls.technicians.disable();
    } else if (project.progress === ProjectProgress.completed) {
      this.form.controls.technicians.disable();
    } else {
      this.form.controls.technicians.enable();
    }

    if (project?.progress) {
      if (project.progress === ProjectProgress.completed) {
        this.form.disable();
      }

      if (
        (project.progress !== ProjectProgress.inProgress)
        && (project.progress !== ProjectProgress.completed)
      ) {
        this.form.controls.progress.disable();
      } else {
        this.form.controls.progress.enable();
      }
    }
    if (this.isUpdateMode && !this.project()?.isActive) {
      this.form.disable();
    }
  }

  private listenFormChanges(): void {
    this.changed$.next(!isProjectsAreEqual(this.project()!, this.form.getRawValue()));

    this.form.valueChanges
      .pipe(
        tap(() => {
          this.changed$.next(!isProjectsAreEqual(this.project()!, this.form.getRawValue()));
          this.checkSubmitBtnDisabled();
          this.changeDetectorRef.markForCheck();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    this.form.controls.serialNumbers.valueChanges.pipe(
      tap(v => this.projectSerialNumbers = v),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();

    this.form.controls.fiberRouteFile.valueChanges.pipe(
      tap((file) => {
        this.fiberRouteFile = file!;
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();

    this.form.controls.routeValidation.valueChanges.pipe(
      tap((value) => {
        if (value) {
          this.form.controls.positiveSectionLengthTolerance.enable();
          this.form.controls.negativeSectionLengthTolerance.enable();
        } else {
          this.form.controls.positiveSectionLengthTolerance.disable();
          this.form.controls.negativeSectionLengthTolerance.disable();
          this.form.controls.positiveSectionLengthTolerance.setValue(null);
          this.form.controls.negativeSectionLengthTolerance.setValue(null);
        }
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();

    this.form.statusChanges
      .pipe(
        tap(() => {
          this.dialogRef.disableClose = this.changed$.value;
          this.checkSubmitBtnDisabled();
          this.changeDetectorRef.markForCheck();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private detectMode(): void {
    if (this.project()?.id) {
      this.mode = 'update';
      this.listenProjectStatusChanges();
    }
    this.isLocationInvalid = this.mode === 'create';
  }

  private initOrganizationFetchFn(): void {
    this.organizationFetchFn = getOrganizationsFetchFn(this.organizationApiService);
  }

  private checkSubmitBtnDisabled(): void {
    this.submitBtnDisabled = this.form.invalid
      || this.isLocationInvalid
      || !this.changed$.value
      || this.form.controls.name.pending;
  }

  private listenProjectStatusChanges(): void {
    this.projectStatusUpdated$.pipe(
      tap(({ isActive }) => {
        if (isActive) {
          this.form.enable();
        } else {
          this.form.disable();
        }
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }
}
