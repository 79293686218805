<aps-entity-create-dialog
  data-test="e2e-project-create"
  [title]="isUpdateMode ? 'Project Overview' : 'Create a new Project'"
  [icon]="isUpdateMode ? 'edit-project' : 'create-project'"
  [submitBtnText]="isUpdateMode ? 'Save' : 'Create'"
  [submitBtnDisabled]="submitBtnDisabled || reportDownloadInProgress"
  [apsBtnPending]="createUpdatePending$ | async"
  [cancelDtnDisabled]="reportDownloadInProgress || (createUpdatePending$ | async)"
  (submitted)="submit()">
  <form [formGroup]="form" autocomplete="off">
    <div class="grid grid-cols-12">
      <div class="col-span-6 pt-1 pr-6">

        <h6 class="mb-3 dialog-section-title">General Information</h6>

        <label class="required">Project Name</label>
        <mat-form-field class="w-full" appearance="outline">
          @if (form.controls.name.pending) {
            <mat-spinner matPrefix
                         class="mx-3"
                         mode="indeterminate"
                         [diameter]="16" />
          } @else {
            <mat-icon matPrefix
                      class="color-medium"
                      svgIcon="folder" />
          }
          <input data-test="e2e-project-name-input" formControlName="name" type="text" matInput placeholder="Add Project Name">
          @if (form.controls.name.invalid) {
            <mat-error>
              @if (form.controls.name.errors?.['required']) {
                <span data-test="e2e-project-name-required-msg">This field is required</span>
              }
              @if (form.controls.name.errors?.['minlength']) {
                <span data-test="e2e-project-name-min-length-msg">Minimum length is {{ projectNameMinLength }}</span>
              }
              @if (form.controls.name.errors?.['maxlength']) {
                <span data-test="e2e-project-name-max-length-msg">Maximum length is {{ projectNameMaxLength }}</span>
              }
              @if (form.controls.name.errors?.['alreadyExists']) {
                <span data-test="e2e-project-name-alr-exist-msg">Project name already exists. Please provide a different name.</span>
              }
            </mat-error>
          }
        </mat-form-field>

        <aps-infinite-scroll-selector
          data-test="e2e-organizations-selector"
          [label]="'Organization'"
          [icon]="'home-city-outline'"
          [placeholder]="'Select an Organization'"
          [multiple]="false"
          [required]="true"
          [selectedOptions]="activeUserOrganizationsOptions"
          [formControl]="form.controls?.organization"
          [dataFetcherFn]="organizationFetchFn"
        />

        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-4">
            <label>OC Number</label>
            <mat-form-field class="w-full" appearance="outline">
              <mat-icon matPrefix class="color-medium" svgIcon="numbers" />
              <input data-test="e2e-oc-number-input" formControlName="ocNumber" matInput placeholder="Add OC">
              @if (form.controls.ocNumber.invalid) {
                <mat-error>
                  @if (form.controls.ocNumber?.errors?.['invalidSymbols']) {
                    <span>Invalid OC</span>
                  }
                  @if (form.controls.ocNumber?.errors?.['maxlength']) {
                    <span>Maximum length is {{ ocNumberMaxLength }}</span>
                  }
                </mat-error>
              }
            </mat-form-field>
          </div>
          <div class="col-span-4">
            <label>PO Number</label>
            <mat-form-field class="w-full" appearance="outline">
              <mat-icon matPrefix class="color-medium" svgIcon="numbers" />
              <input data-test="e2e-po-number-input" formControlName="poNumber" matInput placeholder="Add PO">
              @if (form.controls.poNumber.invalid) {
                <mat-error>
                  @if (form.controls.poNumber?.errors?.['maxlength']) {
                    <span>Maximum length is {{ poNumberMaxLength }}</span>
                  }
                </mat-error>
              }
            </mat-form-field>
          </div>
          <div class="col-span-4">
            <label>Distance</label>
            <mat-form-field class="w-full" appearance="outline">
              <mat-icon matPrefix class="color-medium" svgIcon="distance" />
              <input formControlName="distance"
                     data-test="e2e-distance-input"
                     matInput
                     type="text"
                     placeholder="0 km"
                     mask="separator.3"
                     suffix=" km">
              @if (form.controls.distance.invalid) {
                <mat-error>
                  @if (form.controls.distance.errors?.['maxlength']) {
                    <span>Should be positive</span>
                  }
                  @if (form.controls.distance?.errors?.['max']) {
                    <span>Maximum value is {{ distanceMax }}</span>
                  }
                </mat-error>
              }
            </mat-form-field>
          </div>
        </div>

        <aps-input-serial-numbers data-test="e2e-serial-numbers-input" formControlName="serialNumbers" />

        @if (isUpdateMode) {
          <div class="flex items-center mt-4">
            <div class="w-full">
              <label>Webhook</label>
              <mat-form-field class="w-full" appearance="outline">
                <mat-icon matPrefix
                          class="color-medium"
                          svgIcon="webhook" />
                <input data-test="e2e-webhook-input" formControlName="webhookUrl" matInput placeholder="Webhook URL">
              </mat-form-field>
            </div>
            <aps-copy-to-clipboard class="color-primary"
                                   data-test="e2e-copy-webhook-btn"
                                   [value]="form.controls.webhookUrl.value" />
          </div>
        }

        <div class="pt-1 my-1">
          <label>Description</label>
          <mat-form-field class="w-full" appearance="outline" floatLabel="always">
            <mat-icon matPrefix class="color-medium" svgIcon="file" />
            <textarea data-test="e2e-project-description-input" formControlName="description" matInput placeholder="Type description"></textarea>
            @if (form.controls.description.invalid) {
              <mat-error>
                @if (form.controls.description?.errors?.['maxlength']) {
                  <span>Maximum length is {{ descriptionMaxLength }}</span>
                }
              </mat-error>
            }
          </mat-form-field>
        </div>
        <ng-container *ngxPermissionsOnly="allowedPermissionsToViewApsComment">
          <label>APS Comment</label>
          <mat-form-field class="w-full" appearance="outline" floatLabel="always">
            <mat-icon matPrefix class="color-medium" svgIcon="comment" />
            <textarea data-test="e2e-project-comment-input" formControlName="apsComment" matInput placeholder="Add a comment"></textarea>
            @if (form.controls.apsComment.invalid) {
              <mat-error>
                @if (form.controls.apsComment?.errors?.['maxlength']) {
                  <span>Maximum length is {{ commentMaxLength }}</span>
                }
              </mat-error>
            }
          </mat-form-field>
          <ng-container *ngxPermissionsOnly="allowedPermissionsToEditMapping">
            @if (isUpdateMode) {
              <button data-test="e2e-edit-mapping-btn" mat-raised-button type="button" color="primary" class="mt-1" (click)="openProjectInMobileApp()">
                Edit mapping
              </button>
            }
          </ng-container>
        </ng-container>
      </div>
      <div class="col-span-6 pt-1 pl-6">
        @if (isUpdateMode && project()?.createdBy) {
          <div class="mb-6">
            <div class="dialog-section-title mb-4">Project History</div>
            <aps-project-history-card
              data-test="e2e-project-history"
              [modifiedBy]="project()?.lastUpdatedBy"
              [lastUpdatedDate]="project()?.modifiedOn"
              [createdDate]="project()?.createdOn"
              [createdBy]="project()?.createdBy"
            />
          </div>
        }

        <div class="mb-6 mt-2">
          <aps-location data-test="e2e-project-location"
                        [project]="project()"
                        [mode]="mode"
                        [locationMaxLength]="locationMaxLength"
                        [mapControlsDisabled]="!project()?.isActive"
                        (locationStatusChanged)="onLocationStatusChanged($event)"
                        (blueprintChanged)="onBlueprintChanged($event)"
                        (mapFieldsDataChanged)="onMapFieldsDataChanged($event)" />
        </div>

        @if (isGeographicalReferenceSelected) {
          <div class="mb-4">
            <aps-route-validation
              data-test="e2e-project-route-validation"
              [parentForm]="form"
              [project]="project()"
              [attachDisabled]="(project() && !project()?.isActive) || (createUpdatePending$ | async)" />
          </div>
        }

        <aps-input-technicians class="block mb-4"
                               data-test="e2e-input-technicians"
                               [mode]="mode"
                               [mappingType]="form.controls.mappingType.value"
                               formControlName="technicians" />
        @if (isUpdateMode) {
          <aps-project-export data-test="e2e-project-export"
                              [project]="project()"
                              [serialNumbers]="projectSerialNumbers"
                              (downloadStateChange)="handleReportDownloadStateChanges($event)" />
          <aps-input-project-status data-test="e2e-project-status" class="block mt-4 mb-4" formControlName="progress"/>
        }
      </div>
    </div>
  </form>
  <ng-container extraControls>
    <div class="grid justify-end">
      @if (isUpdateMode) {
        <ng-container *ngxPermissionsOnly="allowedPermissionsToActivateProject">
          <aps-project-deactivate data-test="e2e-project-deactivate" [isActiveProject]="project()?.isActive" />
        </ng-container>
      }
    </div>
  </ng-container>
</aps-entity-create-dialog>
