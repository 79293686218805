<label>Project Status</label>
<mat-form-field class="w-full" appearance="outline">
  <mat-icon matPrefix class="color-medium" svgIcon="project-status" />
  <mat-select [formControl]="statusControl"
              data-test="e2e-project-status-selector"
              placeholder="Select Status">
    <mat-option [value]="projectProgress.inProgress" data-test="e2e-project-status-in-progress-option">
      {{ progressNameMap[projectProgress.inProgress] }}
    </mat-option>
    <mat-option [value]="projectProgress.completed" data-test="e2e-project-status-completed-option">
      {{ progressNameMap[projectProgress.completed] }}
    </mat-option>
  </mat-select>
</mat-form-field>
