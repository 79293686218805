import {ChangeDetectorRef} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {map, of, switchMap, tap, timer} from 'rxjs';

import {InputTechniciansService} from '@features/projects/services';


export class TouchedErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null): boolean {
    return Boolean(control?.invalid ?? (control?.invalid && (control.dirty || control.touched)));
  }
}

export class ValidateEmailNotTaken {

  static createValidator(service: InputTechniciansService, cdr: ChangeDetectorRef, isEventReportProject = false) {
    return (control: AbstractControl<string>) => {
      if (!control.value) {
        return of(null);
      }

      return timer(400).pipe(
        switchMap(() => service.checkUserByEmail(control.value)),
        map((result) => {
          if (result.userExists) {
            if (!result.isUserActive) {
              return { userInactive: true };
            }
            if (isEventReportProject && !result.eventReporting) {
              return { eventReportingNotAllowed: true };
            } else if (!result.assetMapping) {
              return { assetMappingNotAllowed: true };
            }
            return null;
          }
          return { doesNotExist: true };
        }),
        tap(() => cdr.markForCheck())
      );
    };
  }
}
