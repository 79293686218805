import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {MatTooltip} from '@angular/material/tooltip';

@Component({
  selector: 'aps-offline-area-control',
  templateUrl: './offline-area-control.component.html',
  styleUrls: ['./offline-area-control.component.scss'],
  standalone: true,
  imports: [
    MatIcon,
    MatTooltip,
    MatButton
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfflineAreaControlComponent {
  @Input() icon!: string;
  @Input() text!: string;
  @Input() tooltip!: string;
  @Input() disabled = false;
  @Input() isFullscreen = false;
  @Output() controlClick = new EventEmitter<void>();
}
