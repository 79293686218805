<div class="upload-file-section relative bg-super-light-gray p-8">
  <div class="flex flex-col items-center justify-center gap-4">
    <input #fileInput
           type="file"
           accept="image/*"
           data-test="e2e-file-input"
           (change)="processFile(fileInput)" />
    <p class="text-sm">Drag and drop here or</p>

    <button (click)="browseFile()" mat-raised-button color="primary">
      Browse file
    </button>
  </div>
</div>
