<aps-entity-create-dialog
  data-test="e2e-er-project-create"
  [title]="isUpdateMode ? 'ER Project Overview' : 'Create a new ER Project'"
  [icon]="isUpdateMode ? 'edit-project' : 'create-project'"
  [submitBtnText]="isUpdateMode ? 'Save' : 'Create'"
  [submitBtnDisabled]="submitBtnDisabled || reportDownloadInProgress"
  [apsBtnPending]="createUpdatePending$ | async"
  [cancelDtnDisabled]="reportDownloadInProgress || (createUpdatePending$ | async)"
  (submitted)="submit()">
  <form [formGroup]="form" autocomplete="off">
    <div class="grid grid-cols-12">
      <div class="col-span-6 pt-1 pr-6">

        <h6 class="mb-3 dialog-section-title">General Information</h6>

        <label class="required">Project Name</label>
        <mat-form-field class="w-full" appearance="outline">
          <input data-test="e2e-er-project-name-input" formControlName="name" type="text" matInput placeholder="Add Project Name">
          @if (form.controls.name.invalid) {
            <mat-error>
              @if (form.controls.name.errors?.['required']) {
                <span data-test="e2e-er-project-name-required-msg">This field is required</span>
              }
              @if (form.controls.name.errors?.['minlength']) {
                <span data-test="e2e-er-project-name-min-length-msg">Minimum length is {{ projectNameMinLength }}</span>
              }
              @if (form.controls.name.errors?.['maxlength']) {
                <span data-test="e2e-er-project-name-max-length-msg">Maximum length is {{ projectNameMaxLength }}</span>
              }
              @if (form.controls.name.errors?.['alreadyExists']) {
                <span data-test="e2e-er-project-name-alr-exist-msg">Project name already exists. Please provide a different name.</span>
              }
            </mat-error>
          }
        </mat-form-field>

        <aps-infinite-scroll-selector
          data-test="e2e-organizations-selector"
          [label]="'Organization'"
          [icon]="'home-city-outline'"
          [placeholder]="'Select an Organization'"
          [multiple]="false"
          [required]="true"
          [selectedOptions]="activeUserOrganizationsOptions"
          [formControl]="form.controls?.organization"
          [dataFetcherFn]="organizationFetchFn"
        />

        <div class="pt-1 my-1">
          <label>Description</label>
          <mat-form-field class="w-full" appearance="outline" floatLabel="always">
            <mat-icon matPrefix class="color-medium" svgIcon="file" />
            <textarea data-test="e2e-er-project-description-input" formControlName="description" matInput placeholder="Type description"></textarea>
            @if (form.controls.description.invalid) {
              <mat-error>
                @if (form.controls.description?.errors?.['maxlength']) {
                  <span>Maximum length is {{ descriptionMaxLength }}</span>
                }
              </mat-error>
            }
          </mat-form-field>
        </div>
        @if (isUpdateMode) {
          <div class="flex items-center mt-2">
            <div class="w-full">
              <label>Webhook</label>
              <mat-form-field class="w-full" appearance="outline">
                <mat-icon matPrefix
                          class="color-medium"
                          svgIcon="webhook" />
                <input formControlName="webhookUrl" matInput placeholder="Webhook URL">
              </mat-form-field>
              @if (!isGeographicalReferenceSelected) {
                <mat-error class="relative bottom-4">
                  <span class="text-xs">Alarm reporting is currently not supported for mapping type blueprint</span>
                </mat-error>
              }
            </div>
            <aps-copy-to-clipboard class="color-primary"
                                   [isDisabled]="!isGeographicalReferenceSelected"
                                   [value]="form.controls.webhookUrl.value" />
          </div>
        }
        @if (isUpdateMode && isGeographicalReferenceSelected) {
          <div class="flex items-center mt-2">
            <aps-infinite-scroll-selector
              class="w-full"
              [label]="'Alarm Notification Assignees'"
              [icon]="'home-city-outline'"
              [placeholder]="'Choose users to receive alarm notifications'"
              [multiple]="true"
              [selectedOptions]="activeAssignedUsersToAlarmNotification"
              [formControl]="form.controls.assignedUsersToAlarmNotification"
              [dataFetcherFn]="notificationAssigneesFetchFn"
            />
          </div>
        }
        <ng-container *ngxPermissionsOnly="allowedPermissionsToEditMapping">
          @if (isUpdateMode) {
            <div class="flex items-center mt-2">
              <button mat-raised-button type="button" color="primary" data-test="e2e-edit-event-reports-btn" (click)="openProjectInMobileApp()">
                Edit event reports
              </button>
            </div>
          }
        </ng-container>
      </div>
      <div class="col-span-6 pt-1 pl-6">
        @if (isUpdateMode) {
          <div class="mb-6">
            <label class="dialog-section-title">Project History</label>
            <aps-project-history-card
              data-test="e2e-er-project-history"
              [createdBy]="project()?.createdBy"
              [createdDate]="project()?.createdOn"
              [lastUpdatedDate]="project()?.modifiedOn"
              [modifiedBy]="project()?.modifiedBy"
            />
          </div>
        }

        <div class="mb-6 mt-2">
          <aps-location data-test="e2e-er-project-location"
                        [project]="project()"
                        [mode]="mode"
                        [isEventReportProject]="true"
                        [parentFormDisabled]="form?.disabled"
                        (locationStatusChanged)="onLocationStatusChanged($event)"
                        (blueprintChanged)="onBlueprintChanged($event)"
                        (mapFieldsDataChanged)="onMapFieldsDataChanged($event)" />
        </div>

        @if (isGeographicalReferenceSelected) {
          <div class="mb-4">
            <aps-route-validation
              data-test="e2e-er-project-route-validation"
              [parentForm]="form"
              [project]="project()"
              [isEventReportProject]="true"
              [routeValidationActive]="false"
              [attachDisabled]="(project() && !project()?.isActive) || (createUpdatePending$ | async)" />
          </div>
        }

        <aps-input-technicians class="block mb-4"
                               data-test="e2e-input-technicians"
                               [mode]="mode"
                               [isEventReportProject]="true"
                               [mappingType]="form.controls.mappingType.value"
                               formControlName="technicians" />
        @if (isUpdateMode) {
          <aps-project-export data-test="e2e-er-project-export"
                              [project]="project()"
                              [isEventReportProject]="true"
                              (downloadStateChange)="handleReportDownloadStateChanges($event)" />
        }
      </div>
    </div>
  </form>
  <ng-container extraControls>
    <div class="grid justify-end">
      @if (isUpdateMode) {
        <aps-event-project-deactivate data-test="e2e-er-project-deactivate" [isActiveProject]="project()?.isActive"/>
      }
    </div>
  </ng-container>
</aps-entity-create-dialog>
