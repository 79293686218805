import {createFeature, createReducer, createSelector, on} from '@ngrx/store';
import {produce} from 'immer';

import {INITIAL_PAGINATION_CONFIG, ListResponse, Maybe, Pagination, RequestStatus} from '@core/common';
import {UserProfile, UsersListPreferences} from '@features/users/models';
import {AgGridSortState} from '@shared/models';

import {UsersActions} from './users.actions';

export interface UsersFeature {
  users: Maybe<ListResponse<UserProfile>>;
  sortingState: Maybe<AgGridSortState[]>;
  usersPagination: Pagination;
  usersLoadStatus: RequestStatus,
  userByIdLoadStatus: RequestStatus,
  userCreateUpdateReqStatus: RequestStatus,
  userUpdateStatusReqStatus: RequestStatus,
  usersListPreferences: Maybe<UsersListPreferences>;
  userById: Maybe<UserProfile>;
}

export const usersInitialState: UsersFeature = {
  users: null,
  sortingState: null,
  usersPagination: { ...INITIAL_PAGINATION_CONFIG },
  usersLoadStatus: 'IDLE',
  userByIdLoadStatus: 'IDLE',
  userCreateUpdateReqStatus: 'IDLE',
  userUpdateStatusReqStatus: 'IDLE',
  usersListPreferences: null,
  userById: null
};

export const usersReducer = createReducer(
  usersInitialState,
  on(UsersActions.loadUsers, (state) =>
    produce(state, (draft) => {
      draft.usersLoadStatus = 'LOADING';
    })
  ),
  on(UsersActions.loadUsersSuccess, (state, { users }) =>
    produce(state, (draft) => {
      draft.usersLoadStatus = 'SUCCESS';
      draft.users = users;
    })
  ),
  on(UsersActions.loadUsersFailure, (state) =>
    produce(state, (draft) => {
      draft.usersLoadStatus = 'ERROR';
    })
  ),
  on(UsersActions.updateUsersPagination, (state, { pagination }) =>
    produce(state, (draft) => {
      draft.usersPagination = pagination;
    })
  ),
  on(UsersActions.updateUsersSorting, (state, { sorting }) =>
    produce(state, (draft) => {
      draft.sortingState = sorting;
    })
  ),
  on(UsersActions.loadUserById, (state) =>
    produce(state, (draft) => {
      draft.userByIdLoadStatus = 'LOADING';
    })
  ),
  on(UsersActions.loadUserByIdSuccess, (state, { user }) =>
    produce(state, (draft) => {
      draft.userByIdLoadStatus = 'SUCCESS';
      draft.userById = user;
    })
  ),
  on(UsersActions.loadUserByIdFailure, (state) =>
    produce(state, (draft) => {
      draft.userByIdLoadStatus = 'ERROR';
    })
  ),
  on(UsersActions.updateUserStatus, (state) =>
    produce(state, (draft) => {
      draft.userUpdateStatusReqStatus = 'LOADING';
    })
  ),
  on(UsersActions.updateUserStatusSuccess, (state, { status }) =>
    produce(state, (draft) => {
      draft.userUpdateStatusReqStatus = 'SUCCESS';
      draft.userById = {
        ...draft.userById,
        status
      } as UserProfile;
    })
  ),
  on(UsersActions.updateUserStatusFailure, (state) =>
    produce(state, (draft) => {
      draft.userUpdateStatusReqStatus = 'ERROR';
    })
  ),
  on(UsersActions.updateUserStatusCancel, (state) =>
    produce(state, (draft) => {
      draft.userUpdateStatusReqStatus = 'IDLE';
    })
  ),
  on(UsersActions.createUser,
    UsersActions.updateUser, (state) =>
      produce(state, (draft) => {
        draft.userCreateUpdateReqStatus = 'LOADING';
      })
  ),
  on(UsersActions.createUserSuccess,
    UsersActions.updateUserSuccess, (state) =>
      produce(state, (draft) => {
        draft.userCreateUpdateReqStatus = 'SUCCESS';
      })
  ),
  on(UsersActions.createUserFailure,
    UsersActions.updateUserFailure, (state) =>
      produce(state, (draft) => {
        draft.userCreateUpdateReqStatus = 'ERROR';
      })
  ),
  on(UsersActions.clearUserById, (state) =>
    produce(state, (draft) => {
      draft.userById = usersInitialState.userById;
      draft.userByIdLoadStatus = usersInitialState.userByIdLoadStatus;
    })
  ),
  on(UsersActions.setUsersListPreferences, (state, { usersListPreferences }) =>
    produce(state, (draft) => {
      draft.usersListPreferences = usersListPreferences;
    })
  ),
  on(UsersActions.reset, () => ({ ...usersInitialState }))
);

export const usersFeature = createFeature({
  name: 'users',
  reducer: usersReducer,
  extraSelectors: ({
                     selectUsersLoadStatus,
                     selectUserByIdLoadStatus,
                     selectUserCreateUpdateReqStatus,
                     selectUserUpdateStatusReqStatus
                   }) => {
    const selectIsUsersPending = createSelector(
      selectUsersLoadStatus,
      (loadStatus) => loadStatus === 'LOADING'
    );

    const selectIsUserByIdPending = createSelector(
      selectUserByIdLoadStatus,
      (loadStatus) => loadStatus === 'LOADING'
    );

    const selectIsUserCreateUpdatePending = createSelector(
      selectUserCreateUpdateReqStatus,
      (loadStatus) => loadStatus === 'LOADING'
    );

    const selectIsUserUpdateStatusPending = createSelector(
      selectUserUpdateStatusReqStatus,
      (loadStatus) => loadStatus === 'LOADING'
    );

    return {
      selectIsUsersPending,
      selectIsUserUpdateStatusPending,
      selectIsUserCreateUpdatePending,
      selectIsUserByIdPending
    };
  }
});
