<div class="btn-wrapper" [matTooltip]="tooltip">
  <button mat-raised-button
          [disabled]="disabled"
          [class.icon-only]="!isFullscreen"
          (click)="controlClick.emit()">
    <mat-icon [svgIcon]="icon" />
    @if (isFullscreen) {
      <span>{{ text }}</span>
    }
  </button>
</div>
