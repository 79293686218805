import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MapUtils {
  getMapEntries<T, D>(map: Map<T, D>): [T, D][] {
    return Array.from(map.entries());
  }

  getMapValues<T, D>(map: Map<T, D>): D[] {
    return Array.from(map.values());
  }

  getMapKeys<T, D>(map: Map<T, D>): T[] {
    return Array.from(map.keys());
  }
}
