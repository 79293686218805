<div [ngClass]="options ? 'hidden' : null">
  <aps-file-input (changed)="onFileChanged($event)"/>
</div>

@if (options) {
  <div [class.fullscreen]="isFullscreen" class="width-full map-container z-10">
    <div id='Map'
         leaflet
         data-test="e2e-lf-map"
         [leafletOptions]="options"
         [leafletLayers]="layers"
         (leafletMapReady)="onMapReady($event)">
    </div>
    <aps-resize-control (toggleFullscreen)="toggleFullscreen($event)" />
    @if (replaceBtnAvailable) {
      <aps-replace-control class="absolute bottom-1 left-2.5" (removeClicked)="remove()" [disabled]="mapControlsDisabled" />
    }
  </div>
}
@if (fileInput.showFileSizeErrorMsg) {
  <mat-error>
    <span class="text-xs">{{ fileSizeErrorMsg }}</span>
  </mat-error>
}

