<div class="flex flex-col justify-between">
  <h6 class="dialog-section-title mb-2">Project Export</h6>
  <div class="bg-super-light-gray rounded-xl p-4">
    <div class="flex items-center justify-between">
      <p class="basis-32 text-sm" [class.opacity-50]="disabled">File type</p>
      <mat-form-field appearance="outline" floatLabel="always" class="-mb-5">
        <mat-select placeholder="Select Type"
                    data-test="e2e-proj-export-ft-selector"
                    [(ngModel)]="selectedFileType"
                    [disabled]="disabled">
          @for (type of fileTypeOptions; track type.id) {
            <mat-option data-test="e2e-proj-export-ft-selector-option" [value]="type?.id">{{ type.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    @if (selectedFileType === 'csv') {
      <div class="flex items-center justify-between mt-4">
        <p class="basis-32 text-sm">Format
          <mat-icon class="size-xs cursor-pointer mr-2" svgIcon="help" [matTooltip]="tooltips.formatText" />
        </p>
        <mat-form-field appearance="outline" floatLabel="always" class="-mb-5">
          <mat-select [(ngModel)]="selectedCsvFormat" data-test="e2e-proj-export-csv-selector" placeholder="Select Format">
            @for (type of csvFormatOptions; track type.id) {
              <mat-option data-test="e2e-proj-export-csv-selector-option" [value]="type?.id">{{ type.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      @if (selectedCsvFormat === 'dts') {
        <div class="flex items-center justify-between mt-4">
          <p class="text-sm">Alarm Configuration
            <mat-icon class="size-xs cursor-pointer mr-2" svgIcon="help"
                      [matTooltip]="tooltips.alarmConfigurationText" />
          </p>
          <mat-form-field appearance="outline" floatLabel="always" class="-mb-5">
            <mat-select [(ngModel)]="selectedAlarmConfig" data-test="e2e-proj-export-dts-selector" placeholder="Select Configuration">
              @for (type of alarmConfigOptions; track type.id) {
                <mat-option data-test="e2e-proj-export-dts-selector-option" [value]="type?.id">{{ type.name }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      }
      @if (selectedCsvFormat === 'smartVision') {
        <div class="flex items-center justify-between mt-4">
          <p class="text-sm">Asset Name
            <mat-icon class="size-xs cursor-pointer mr-2" svgIcon="help"
                      [matTooltip]="tooltips.assetNameText" />
          </p>
          <mat-form-field appearance="outline" class="-mb-5">
            <input type="text" data-test="e2e-proj-export-asset-name" [maxLength]="assetNameMaxLength" [(ngModel)]="assetName" matInput>
          </mat-form-field>
        </div>
        <div class="flex items-center justify-between mt-4">
          <p class="text-sm">Instrument Serial Number
            <mat-icon class="size-xs cursor-pointer mr-2" svgIcon="help"
                      [matTooltip]="tooltips.serialNumberText" />
          </p>
          <mat-form-field appearance="outline" floatLabel="always" class="-mb-5">
            <mat-select placeholder="Select Serial Number" data-test="e2e-proj-export-sn-selector" [(ngModel)]="selectedSerialNumber"
                        [disabled]="!serialNumbersOptions.length">
              @for (type of serialNumbersOptions; track type.id) {
                <mat-option data-test="e2e-proj-export-sn-selector-option" [value]="type?.id">{{ type.name }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="flex items-center justify-between mt-4">
          <p class="text-sm">Channel Number
            <mat-icon class="size-xs cursor-pointer mr-2" svgIcon="help"
                      [matTooltip]="tooltips.channelNumberText" />
          </p>
          <mat-form-field appearance="outline">
            <input type="number" mask="000000" data-test="e2e-proj-export-channel-number"  [(ngModel)]="channelNumber" matInput>
          </mat-form-field>
        </div>
      }
    }
    <div class="flex mt-6 justify-between">
      <div class="basis-3/4">
        @if (downloadInProgress) {
          <div class="flex mt-2 items-center">
            <mat-spinner [diameter]="16" class="ag-grid-loader mr-2" mode="indeterminate" />
            <p class="font-medium text-sm">Report generation in progress</p>
          </div>
        }
        @if (downloadError) {
          <div class="flex mt-2 items-center">
            <p class="font-medium text-sm color-primary">Report generation failed. Please try again</p>
          </div>
        }
      </div>
      <div class="basis-1/4">
        <button mat-flat-button
                color="primary"
                data-test="e2e-proj-export-download-btn"
                [apsDownloadFile]="downloadUrl"
                [disabled]="disabled || !isValidSmartVisionParams"
                (downloadError)="downloadError = $event"
                (downloadInProgress)="handleDownloadInProgressEvent($event)">Download
        </button>
      </div>
    </div>
  </div>
</div>
