@if (userRole) {
  @if (multiple) {
    <aps-infinite-scroll-selector
      [label]="'Organization(s)'"
      [icon]="'home-city-outline'"
      [placeholder]="'Select an Organization(s)'"
      [multiple]="true"
      [required]="!isTechnicianRole"
      [selectedOptions]="activeUserOrganizationsOptions"
      [formControl]="multipleCtrl"
      [dataFetcherFn]="organizationFetchFn"
    />
  } @else {
    <aps-infinite-scroll-selector
      [label]="'Organization'"
      [icon]="'home-city-outline'"
      [placeholder]="'Select an Organization'"
      [multiple]="false"
      [required]="!isTechnicianRole"
      [selectedOptions]="activeUserOrganizationsOptions"
      [formControl]="singleCtrl"
      [dataFetcherFn]="organizationFetchFn"
    />
  }
}
