import {inject, Injectable} from '@angular/core';
import {AbstractControl, AsyncValidatorFn, ValidatorFn} from '@angular/forms';
import {PhoneNumberUtil} from 'google-libphonenumber';
import {debounceTime, distinctUntilChanged, first, map, of, switchMap} from 'rxjs';

import {User, UserFormRawValue, UserOrganization, UserProfile} from '@features/users/models';
import {UserApiService} from '@features/users/services';

const phoneNumberInstance = PhoneNumberUtil.getInstance();

@Injectable({
  providedIn: 'root'
})
export class UserCreateUtils {
  private readonly userService = inject(UserApiService);

  createEmailNotTakenValidator(): AsyncValidatorFn {
    return (control: AbstractControl) => {
      if (control.pristine) {
        return of(null);
      }

      return control.valueChanges
        .pipe(
          debounceTime(400),
          distinctUntilChanged(),
          switchMap(value => this.userService.search({
            email: value,
            exact: true
          })),
          map((result) => result?.data?.length ? { alreadyExists: true } : null),
          first()
        );
    };
  }

  createUserModeValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      const assetMapping = control.get('assetMapping')!.value;
      const eventReporting = control.get('eventReporting')!.value;

      if (!assetMapping && !eventReporting) {
        return { invalidUserMode: true };
      }

      return null;
    };
  }

  createPhoneValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      try {
        const phone = phoneNumberInstance.parseAndKeepRawInput(`+${control.value}`);
        if (phoneNumberInstance.isValidNumber(phone)) {
          return null;
        }
      } catch (e) {
        return { invalidPhone: true };
      }
      return { invalidPhone: true };
    };
  }

  areUsersEqual(u1: UserFormRawValue, u2: Partial<User>): boolean {
    return (u1?.firstName === u2?.firstName)
      && (u1?.lastName === u2?.lastName)
      && (u1?.email === u2?.email)
      && (u1?.jobTitle === u2?.jobTitle)
      && (u1?.phone === u2?.phone)
      && (u1?.role === u2?.role)
      && this.isOrganizationsAreEqual(u1?.organizations!, u2?.organizations!)
      && (u1?.isMustResetPassword === u2?.isMustResetPassword)
      && (u1?.enforce2FA === u2?.enforce2FA)
      && (u1?.comment === u2?.comment)
      && (u1?.assetMapping === u2?.assetMapping)
      && (u1.eventReporting === u2?.eventReporting)
      && (u1?.isPrimaryContact === u2?.isPrimaryContact)
      && (u1?.sendEmailInvitation === u2?.sendEmailInvitation);
  }

  isOrganizationsAreEqual(o1: UserOrganization[], o2: UserOrganization[]): boolean {
    if (!o1 && !o2) {
      return true;
    }

    if (!o1?.length && !o2?.length) {
      return true;
    }

    if (o1?.length && o2?.length) {
      return o1.length === o2.length
        && o1.every((value) => value.id === o2.find(d => d.id === value.id)?.id);
    }

    return false;
  }

  userDtoToUser(userDto: UserProfile): User {
    return {
      ...userDto,
      isPrimaryContact: this.isPrimaryContactInAllOrganizations(userDto)
    };
  }

  isPrimaryContactInAllOrganizations(user: UserProfile): boolean {
    let res = true;
    user?.organizations?.forEach((o) => {
      if (!o.isPrimaryContact) {
        res = false;
      }
    });

    return res;
  }

  setIsPrimaryContactToOrganizations(user: UserFormRawValue, isPrimaryContact: boolean): UserProfile {
    const updatedProfile = {
      ...user,
      organizations: user?.organizations?.map((o) => ({ ...o, isPrimaryContact, name: o.name })) ?? []
    } as UserProfile;
    delete updatedProfile.id;
    delete updatedProfile.status;

    return updatedProfile;
  }
}
