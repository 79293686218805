import {createFeature, createReducer, createSelector, on} from '@ngrx/store';
import {produce} from 'immer';

import {INITIAL_PAGINATION_CONFIG, ListResponse, Maybe, Pagination, RequestStatus} from '@core/common';
import {Project} from '@features/projects/models';
import {AgGridSortState, ProjectFilter} from '@shared/models';

import {ProjectsActions} from './projects.actions';

export interface ProjectsFeature {
  projects: Maybe<ListResponse<Project>>;
  sortingState: Maybe<AgGridSortState[]>;
  projectsPagination: Pagination;
  projectsLoadStatus: RequestStatus,
  projectByIdLoadStatus: RequestStatus,
  projectCreateUpdateReqStatus: RequestStatus,
  projectUpdateStatusReqStatus: RequestStatus,
  projectCreateUpdateErrorMsg: Maybe<string>;
  projectFilter: Maybe<ProjectFilter>;
  projectById: Maybe<Project>;
}

export const projectsInitialState: ProjectsFeature = {
  projects: null,
  sortingState: null,
  projectsPagination: { ...INITIAL_PAGINATION_CONFIG },
  projectsLoadStatus: 'IDLE',
  projectByIdLoadStatus: 'IDLE',
  projectCreateUpdateReqStatus: 'IDLE',
  projectUpdateStatusReqStatus: 'IDLE',
  projectCreateUpdateErrorMsg: null,
  projectFilter: null,
  projectById: null
};

export const projectsReducer = createReducer(
  projectsInitialState,
  on(ProjectsActions.loadAssetProjects, (state) =>
    produce(state, (draft) => {
      draft.projectsLoadStatus = 'LOADING';
    })
  ),
  on(ProjectsActions.loadAssetProjectsSuccess, (state, { projects }) =>
    produce(state, (draft) => {
      draft.projectsLoadStatus = 'SUCCESS';
      draft.projects = projects;
    })
  ),
  on(ProjectsActions.loadAssetProjectsFailure, (state) =>
    produce(state, (draft) => {
      draft.projectsLoadStatus = 'ERROR';
    })
  ),
  on(ProjectsActions.updateAssetProjectsPagination, (state, { pagination }) =>
    produce(state, (draft) => {
      draft.projectsPagination = pagination;
    })
  ),
  on(ProjectsActions.updateAssetProjectsSorting, (state, { sorting }) =>
    produce(state, (draft) => {
      draft.sortingState = sorting;
    })
  ),
  on(ProjectsActions.loadAssetProjectById, (state) =>
    produce(state, (draft) => {
      draft.projectByIdLoadStatus = 'LOADING';
      draft.projectCreateUpdateReqStatus = 'IDLE';
    })
  ),
  on(ProjectsActions.loadAssetProjectByIdSuccess, (state, { project }) =>
    produce(state, (draft) => {
      draft.projectByIdLoadStatus = 'SUCCESS';
      draft.projectById = project;
    })
  ),
  on(ProjectsActions.loadAssetProjectByIdFailure, (state) =>
    produce(state, (draft) => {
      draft.projectByIdLoadStatus = 'ERROR';
    })
  ),
  on(
    ProjectsActions.updateAssetProjectFailure,
    ProjectsActions.openAssetProjectPreviewDialog, (state, { errorMsg }) =>
      produce(state, (draft) => {
        draft.projectCreateUpdateErrorMsg = errorMsg ?? null;
      })
  ),
  on(ProjectsActions.updateAssetProjectStatus, (state) =>
    produce(state, (draft) => {
      draft.projectUpdateStatusReqStatus = 'LOADING';
    })
  ),
  on(ProjectsActions.updateAssetProjectStatusSuccess, (state, { isActive }) =>
    produce(state, (draft) => {
      draft.projectUpdateStatusReqStatus = 'SUCCESS';
      draft.projectById = {
        ...draft.projectById,
        isActive
      } as Project;
    })
  ),
  on(ProjectsActions.updateAssetProjectStatusFailure, (state) =>
    produce(state, (draft) => {
      draft.projectUpdateStatusReqStatus = 'ERROR';
    })
  ),
  on(ProjectsActions.updateAssetProjectStatusCancel, (state) =>
    produce(state, (draft) => {
      draft.projectUpdateStatusReqStatus = 'IDLE';
    })
  ),
  on(ProjectsActions.createAssetProject,
    ProjectsActions.updateAssetProject, (state) =>
      produce(state, (draft) => {
        draft.projectCreateUpdateErrorMsg = null;
        draft.projectCreateUpdateReqStatus = 'LOADING';
      })
  ),
  on(ProjectsActions.createAssetProjectSuccess,
    ProjectsActions.updateAssetProjectSuccess, (state) =>
      produce(state, (draft) => {
        draft.projectCreateUpdateReqStatus = 'SUCCESS';
      })
  ),
  on(ProjectsActions.createAssetProjectFailure,
    ProjectsActions.updateAssetProjectFailure, (state) =>
      produce(state, (draft) => {
        draft.projectCreateUpdateReqStatus = 'ERROR';
      })
  ),
  on(ProjectsActions.clearAssetProjectById, (state) =>
    produce(state, (draft) => {
      draft.projectCreateUpdateErrorMsg = projectsInitialState.projectCreateUpdateErrorMsg;
      draft.projectById = projectsInitialState.projectById;
      draft.projectByIdLoadStatus = projectsInitialState.projectByIdLoadStatus;
    })
  ),
  on(ProjectsActions.setAssetProjectFilter, (state, { projectFilter }) =>
    produce(state, (draft) => {
      draft.projectFilter = projectFilter;
    })
  ),
  on(ProjectsActions.clearProjectCreateUpdateErrorMsg, (state) =>
    produce(state, (draft) => {
      draft.projectCreateUpdateErrorMsg = null;
    })
  ),
  on(ProjectsActions.reset, () => ({...projectsInitialState})),
);

export const assetProjectsFeature = createFeature({
  name: 'assetProjects',
  reducer: projectsReducer,
  extraSelectors: ({
                     selectProjectById,
                     selectProjectsLoadStatus,
                     selectProjectByIdLoadStatus,
                     selectProjectCreateUpdateReqStatus,
                     selectProjectUpdateStatusReqStatus
                   }) => {
    const selectIsProjectActive = createSelector(
      selectProjectById,
      (project) => project?.isActive ?? false
    );

    const selectIsProjectsPending = createSelector(
      selectProjectsLoadStatus,
      (loadStatus) => loadStatus === 'LOADING'
    );

    const selectIsProjectByIdPending = createSelector(
      selectProjectByIdLoadStatus,
      (loadStatus) => loadStatus === 'LOADING'
    );

    const selectIsProjectCreateUpdatePending = createSelector(
      selectProjectCreateUpdateReqStatus,
      (loadStatus) => loadStatus === 'LOADING'
    );

    const selectIsProjectUpdateStatusPending = createSelector(
      selectProjectUpdateStatusReqStatus,
      (loadStatus) => loadStatus === 'LOADING'
    );

    return {
      selectIsProjectActive,
      selectIsProjectsPending,
      selectIsProjectByIdPending,
      selectIsProjectCreateUpdatePending,
      selectIsProjectUpdateStatusPending
    };
  }
});
