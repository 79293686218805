<form class="p-6 shadow-md rounded-lg"
      [formGroup]="form"
      autocomplete="off">
  <aps-input-location formControlName="location" data-test="e2e-location-input" [locationMaxLength]="locationMaxLength" />

  @if (!isUpdateMode) {
    <label class="block required" id="mapping-type-label">Select Mapping Type</label>
    <mat-radio-group
      color="primary"
      aria-labelledby="mapping-type-label"
      formControlName="mappingType">
      <mat-radio-button class="block" data-test="e2e-geo-ref-radio-button" [value]="mappingType.map">Geographical Reference</mat-radio-button>
      <mat-radio-button class="block" data-test="e2e-blueprint-radio-button" [value]="mappingType.bluePrint">Blueprint</mat-radio-button>
    </mat-radio-group>
  }
  @if (form.controls.mappingType.value === mappingType.map) {
    <aps-map data-test="e2e-geo-map"
             [coordinates]="coordinates"
             [project]="project"
             [mapControlsDisabled]="mapControlsDisabled"
             [isEventReportProject]="isEventReportProject"
             (offlineAreaChanged)="onOfflineAreaChanged($event)"
             (markerSelected)="onMarkerSelected($event)" />
  }
  @if (form.controls.mappingType.value === mappingType.bluePrint) {
    <aps-blueprint data-test="e2e-bp-map"
                   [project]="project"
                   [mode]="mode"
                   [mapControlsDisabled]="mapControlsDisabled"
                   [isEventReportProject]="isEventReportProject"
                   [blueprintFileName]="bpFileName"
                   [replaceBtnAvailable]="isUpdateMode && canReplaceBlueprint"
                   (fileChanged)="onFileChanged($event)" />
  }
</form>
