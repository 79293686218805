import 'leaflet-draw';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {MatError} from '@angular/material/form-field';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {
  Draw,
  Edit,
  FeatureGroup,
  featureGroup,
  Icon,
  icon,
  LatLng,
  LatLngBounds,
  LatLngExpression,
  LatLngTuple, Layer,
  LeafletMouseEvent,
  Map, Marker,
  marker,
  Rectangle
} from 'leaflet';
import {timer} from 'rxjs';
import {tap} from 'rxjs/operators';

import {EventReportProject, OfflineMapArea, Project, ProjectProgress} from '@features/projects/models';
import {OfflineAreaControlComponent} from '@shared/components/location/components/offline-area-control';

import {ResizeControlComponent} from '../resize-control';

import {MAP_LAYERS_CONTROL, MAP_MIN_ZOOM_FOR_CACHE_TILES, MAP_OPTIONS} from './map.constants';

@Component({
  selector: 'aps-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  standalone: true,
  imports: [
    OfflineAreaControlComponent,
    ResizeControlComponent,
    LeafletModule,
    MatError
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent {
  private readonly cdr = inject(ChangeDetectorRef);
  private markerCorrected = false;
  private rectangleDrawer!: Draw.Rectangle;
  private rectangleEditor!: Edit.Rectangle;
  private _coordinates!: LatLngTuple;
  private layerOriginalLatLng!: LatLngBounds;
  private selectedLayer: 'MAP' | 'SATELLITE' = 'MAP';

  @Input() project!: Project | EventReportProject;
  @Input() isEventReportProject = false;
  @Input() mapControlsDisabled = false;
  @ViewChild('resizeCtrl', { static: false }) resizeCtrl!: ResizeControlComponent;

  @Input() set coordinates(coordinates: LatLngTuple) {
    this.setExternalMarker(coordinates);
    this._coordinates = coordinates;
  }

  @Output() markerSelected = new EventEmitter<LatLngTuple>();
  @Output() offlineAreaChanged = new EventEmitter<OfflineMapArea>();

  map!: Map;
  isFullscreen = false;
  canDrawMapAreaToDownloadOffline = false;
  offlineAreaDrawModeActive = false;
  options = MAP_OPTIONS;
  layersControl = MAP_LAYERS_CONTROL;
  layers: Marker[] = [];
  drawnItems: FeatureGroup = featureGroup();

  get isOfflineAreaDefined(): boolean {
    return Boolean(this.drawnItems.getLayers().length);
  }

  get isOfflineAreaEditModeActive(): boolean {
    return Boolean(this.rectangleEditor?.enabled());
  }

  onMapReady(event: Map): void {
    this.map = event;
    if (this._coordinates) {
      this.map.panTo(this._coordinates);
    }
    if (!this.isEventReportProject) {
      this.initDrawHandlers();
    }
    this.handleLayerChanges();
    this.detectOfflineArea();
    this.drawnItems.addTo(this.map);
  }

  onClick(event: LeafletMouseEvent): void {
    if (!this.offlineAreaDrawModeActive && this.isActiveProject()) {
      this.addMarker(event.latlng);
      this.markerCorrected = true;
      this.markerSelected.emit([event.latlng?.lat, event.latlng?.lng]);
    }
  }

  toggleFullscreen(isFullscreen: boolean): void {
    this.isFullscreen = isFullscreen;
    timer(0).pipe(
      tap(() => this.map.invalidateSize())
    ).subscribe();
  }

  onZoomChange(): void {
    if (!this.isEventReportProject) {
      this.canDrawMapAreaToDownloadOffline = this.map.getZoom() >= MAP_MIN_ZOOM_FOR_CACHE_TILES;
      if (!this.canDrawMapAreaToDownloadOffline) {
        this.rectangleDrawer?.disable();
        if (this.isOfflineAreaEditModeActive) {
          this.cancelEditing();
        }
      }
    }
  }

  drawOfflineArea(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.rectangleDrawer = new Draw.Rectangle(this.map as any, { repeatMode: false });
    this.rectangleDrawer.enable();
    this.toggleFullscreen(true);
    this.toggleOfflineAreaDrawModeActive(true);
  }

  editOfflineArea(): void {
    const activeLayer = this.drawnItems.getLayers()[0] as Rectangle;
    if (!this.isOfflineAreaEditModeActive) {
      this.toggleOfflineAreaDrawModeActive(true);
      this.layerOriginalLatLng = activeLayer.getBounds();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.rectangleEditor = new Edit.Rectangle(activeLayer as any);
      this.rectangleEditor.enable();
    } else {
      this.toggleOfflineAreaDrawModeActive(false);
      this.disableEditing();
      if (activeLayer) {
        this.saveOfflineAreaPolygon(activeLayer.getBounds());
      }
    }
  }

  cancelEditing(): void {
    const activeLayer = this.drawnItems.getLayers()[0] as Rectangle;
    activeLayer.setBounds(this.layerOriginalLatLng);
    this.toggleOfflineAreaDrawModeActive(false);
    this.disableEditing();
  }

  removeOfflineArea(): void {
    this.rectangleEditor?.disable();
    this.drawnItems.clearLayers();
    this.clearOfflineAreaData();
    this.cdr.markForCheck();
  }

  toggleOfflineAreaDrawModeActive(value = false): void {
    this.offlineAreaDrawModeActive = value;
  }

  private disableEditing(): void {
    this.rectangleEditor.updateMarkers();
    this.rectangleEditor.disable();
    this.cdr.markForCheck();
  }

  private setExternalMarker(latLng: LatLngTuple): void {
    if (this.markerCorrected) {
      return;
    }

    if (latLng?.length === 2) {
      this.addMarker(latLng);
      this.markerSelected.emit(latLng);
    } else {
      this.layers = [];
    }
  }

  private addMarker(latLng: LatLngExpression): void {
    this.layers = [marker(latLng, {
      icon: icon({
        ...Icon.Default.prototype.options,
        iconUrl: './assets/custom-marker-icon.png',
        shadowUrl: './assets/custom-marker-shadow.png'
      })
    })];
    this.map?.panTo(latLng);
  }

  private saveOfflineAreaPolygon(coordinates: LatLngBounds): void {
    const northEast = coordinates?.getNorthEast();
    const southWest = coordinates?.getSouthWest();
    const offlineMapArea = {
      leftTopLatitude: southWest?.lat || null,
      leftTopLongitude: southWest?.lng || null,
      rightBottomLatitude: northEast?.lat || null,
      rightBottomLongitude: northEast?.lng || null,
      zoom: this.map.getZoom(),
      mapType: this.selectedLayer
    };
    this.offlineAreaChanged.emit(offlineMapArea);
  }

  private detectOfflineArea(): void {
    if (this.isOfflineAreaDefinedForProject() && this.selectedLayer === this.project?.mapType) {
      const northEast = new LatLng(this.project.rightBottomLatitude!, this.project.rightBottomLongitude!);
      const southWest = new LatLng(this.project.leftTopLatitude!, this.project.leftTopLongitude!
      );
      this.drawnItems.addLayer(new Rectangle(new LatLngBounds(southWest, northEast)));
    }
  }

  private handleLayerChanges(): void {
    this.map.on('baselayerchange', (event) => {
      this.drawnItems.clearLayers();
      this.selectedLayer = event.name === 'Satellite' ? 'SATELLITE' : 'MAP';
      this.detectOfflineArea();
    });
  }

  private clearOfflineAreaData(): void {
    this.offlineAreaChanged.emit({
      leftTopLatitude: null,
      leftTopLongitude: null,
      rightBottomLatitude: null,
      rightBottomLongitude: null
    });
  }

  private initDrawHandlers(): void {
    this.map.on(Draw.Event.CREATED, (e: any) => {
      this.drawnItems.addLayer(e.layer as Layer);
      this.rectangleDrawer.disable();
      this.saveOfflineAreaPolygon(e.layer.getBounds() as LatLngBounds);
      this.cdr.markForCheck();
    });
    this.map.on(Draw.Event.DRAWSTOP, () => this.toggleOfflineAreaDrawModeActive(false));
    this.map.on(Draw.Event.DRAWSTART, () => {
      this.toggleOfflineAreaDrawModeActive(true);
      this.drawnItems.clearLayers();
    });
  }

  private isOfflineAreaDefinedForProject(): boolean {
    if (!this.isEventReportProject) {
      const regularProject = this.project as Project;
      return Boolean(regularProject?.rightBottomLongitude &&
        regularProject.rightBottomLatitude &&
        regularProject.leftTopLatitude &&
        regularProject.leftTopLongitude);
    }
    return false;
  }

  private isActiveProject(): boolean {
    if (this.isEventReportProject) {
      return this.project ?
        (this.project as EventReportProject).isActive : true;
    }
    return (this.project as Project)?.progress !== ProjectProgress.completed;
  }
}
