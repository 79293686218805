import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Directive, EventEmitter, HostListener, inject, Input, Output} from '@angular/core';
import {catchError, EMPTY, finalize} from 'rxjs';
import {tap} from 'rxjs/operators';

import {Maybe} from '@core/common';

@Directive({
  selector: '[apsDownloadFile]',
  standalone: true
})
export class DownloadFileDirective {
  private readonly httpClient = inject(HttpClient);
  private downloadUrl!: string;

  @Output()
  downloadInProgress = new EventEmitter<boolean>();

  @Output()
  downloadError = new EventEmitter<boolean>();

  @Input('apsDownloadFile')
  set url(url: string) {
    this.downloadUrl = url;
  }

  @HostListener('click')
  onClick(): void {
    this.downloadInProgress.emit(true);
    this.downloadError.emit(false);
    // Download the document as a blob
    this.httpClient.get(this.downloadUrl, { responseType: 'blob', observe: 'response' }).pipe(
      tap((response) => {
        const url = URL.createObjectURL(response.body!);

        // Create an anchor element to "point" to it
        const anchor = document.createElement('a');
        anchor.href = url;

        // Get the suggested filename for the file from the response headers
        anchor.download = this.getFilenameFromHeaders(response.headers) ?? 'file';

        // Simulate a click on our anchor element
        anchor.click();

        // Discard the object data
        URL.revokeObjectURL(url);

      }),
      catchError(() => {
        this.downloadError.emit(true);
        return EMPTY;
      }),
      finalize(() => {
        this.downloadInProgress.emit(false);
      })
    ).subscribe();
  }

  private getFilenameFromHeaders(headers: HttpHeaders): string | null {
    const contentDisposition = headers.get('Content-Disposition');
    if (!contentDisposition) {
      return null;
    }

    // Check for the filename* parameter first
    const extendedEncodedFilenames = contentDisposition.match(/filename\*\s*=\s*([^;]+)/);
    if (extendedEncodedFilenames) {
      const encodedFilename = extendedEncodedFilenames[1];
      // Decode the filename according to RFC 5987
      const decodedFilename = this.decodeRFC5987Value(encodedFilename);
      if (decodedFilename) {
        return decodedFilename;
      }
    }

    // Fallback to the regular filename parameter
    const standardEncodedFilenames = contentDisposition.match(/filename\s*=\s*([^;]+)/);
    if (standardEncodedFilenames) {
      return this.getFilenameFromStandardEncodedFilenames(standardEncodedFilenames);
    }

    return null;
  }

  private getFilenameFromStandardEncodedFilenames(standardEncodedFilenames: RegExpMatchArray): Maybe<string> {
    const value = standardEncodedFilenames[1].trim();
    const firstCharacter = value[0];
    if (firstCharacter === '"' || firstCharacter === '\'') {
      const lastCharacter = value[value.length - 1];
      if (lastCharacter === firstCharacter) {
        return value.substring(1, value.length - 1);
      }
    } else {
      return value;
    }
    return null;
  }

  private decodeRFC5987Value(value: string): Maybe<string> {
    try {
      // RFC 5987 format: charset'lang'url-encoded-text
      const parts = value.split('\'');
      if (parts.length === 3) {
        const charset = parts[0].toLowerCase();
        const encodedText = parts[2];
        if (charset === 'utf-8') {
          return decodeURIComponent(encodedText);
        }
      }
    } catch (e) {
      console.error('Error decoding RFC 5987 value:', e);
    }
    return null;
  }
}
