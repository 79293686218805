import {createFeature, createReducer, createSelector, on} from '@ngrx/store';
import {produce} from 'immer';

import {INITIAL_PAGINATION_CONFIG, ListResponse, Maybe, Pagination, RequestStatus} from '@core/common';
import {EventReportProject, EventReportProjectListEntry} from '@features/projects/models';
import {AgGridSortState, ProjectFilter} from '@shared/models';

import {EventReportingActions} from './event-reporting.actions';

export interface EventReportingFeature {
  projects: Maybe<ListResponse<EventReportProjectListEntry>>;
  sortingState: Maybe<AgGridSortState[]>;
  projectsPagination: Pagination;
  projectsLoadStatus: RequestStatus,
  projectByIdLoadStatus: RequestStatus,
  projectCreateUpdateReqStatus: RequestStatus,
  projectUpdateStatusReqStatus: RequestStatus,
  projectCreateUpdateErrorMsg: Maybe<string>;
  projectFilter: Maybe<ProjectFilter>;
  projectById: Maybe<EventReportProject>;
}

export const eventReportingInitialState: EventReportingFeature = {
  projects: null,
  sortingState: null,
  projectsPagination: { ...INITIAL_PAGINATION_CONFIG },
  projectsLoadStatus: 'IDLE',
  projectByIdLoadStatus: 'IDLE',
  projectCreateUpdateReqStatus: 'IDLE',
  projectUpdateStatusReqStatus: 'IDLE',
  projectCreateUpdateErrorMsg: null,
  projectFilter: null,
  projectById: null
};

export const eventProjectsReducer = createReducer(
  eventReportingInitialState,
  on(EventReportingActions.loadEventProjects, (state) =>
    produce(state, (draft) => {
      draft.projectsLoadStatus = 'LOADING';
    })
  ),
  on(EventReportingActions.loadEventProjectsSuccess, (state, { projects }) =>
    produce(state, (draft) => {
      draft.projectsLoadStatus = 'SUCCESS';
      draft.projects = projects;
    })
  ),
  on(EventReportingActions.loadEventProjectsFailure, (state) =>
    produce(state, (draft) => {
      draft.projectsLoadStatus = 'ERROR';
    })
  ),
  on(EventReportingActions.updateEventProjectsPagination, (state, { pagination }) =>
    produce(state, (draft) => {
      draft.projectsPagination = pagination;
    })
  ),
  on(EventReportingActions.updateEventProjectsSorting, (state, { sorting }) =>
    produce(state, (draft) => {
      draft.sortingState = sorting;
    })
  ),
  on(EventReportingActions.loadEventProjectById, (state) =>
    produce(state, (draft) => {
      draft.projectByIdLoadStatus = 'LOADING';
      draft.projectCreateUpdateReqStatus = 'IDLE';
    })
  ),
  on(EventReportingActions.loadEventProjectByIdSuccess, (state, { project }) =>
    produce(state, (draft) => {
      draft.projectByIdLoadStatus = 'SUCCESS';
      draft.projectById = project;
    })
  ),
  on(EventReportingActions.loadEventProjectByIdFailure, (state) =>
    produce(state, (draft) => {
      draft.projectByIdLoadStatus = 'ERROR';
    })
  ),
  on(
    EventReportingActions.updateEventProjectFailure,
    EventReportingActions.openEventProjectPreviewDialog, (state, { errorMsg }) =>
      produce(state, (draft) => {
        draft.projectCreateUpdateErrorMsg = errorMsg ?? null;
      })
  ),
  on(EventReportingActions.updateEventProjectStatus, (state) =>
    produce(state, (draft) => {
      draft.projectUpdateStatusReqStatus = 'LOADING';
    })
  ),
  on(EventReportingActions.updateEventProjectStatusSuccess, (state, { isActive }) =>
    produce(state, (draft) => {
      draft.projectUpdateStatusReqStatus = 'SUCCESS';
      draft.projectById = {
        ...draft.projectById,
        isActive
      } as EventReportProject;
    })
  ),
  on(EventReportingActions.updateEventProjectStatusFailure, (state) =>
    produce(state, (draft) => {
      draft.projectUpdateStatusReqStatus = 'ERROR';
    })
  ),
  on(EventReportingActions.updateEventProjectStatusCancel, (state) =>
    produce(state, (draft) => {
      draft.projectUpdateStatusReqStatus = 'IDLE';
    })
  ),
  on(EventReportingActions.createEventProject,
    EventReportingActions.updateEventProject, (state) =>
      produce(state, (draft) => {
        draft.projectCreateUpdateReqStatus = 'LOADING';
        draft.projectCreateUpdateErrorMsg = null;
      })
  ),
  on(EventReportingActions.createEventProjectSuccess,
    EventReportingActions.updateEventProjectSuccess, (state) =>
      produce(state, (draft) => {
        draft.projectCreateUpdateReqStatus = 'SUCCESS';
      })
  ),
  on(EventReportingActions.createEventProjectFailure,
    EventReportingActions.updateEventProjectFailure, (state) =>
      produce(state, (draft) => {
        draft.projectCreateUpdateReqStatus = 'ERROR';
      })
  ),
  on(EventReportingActions.clearEventProjectById, (state) =>
    produce(state, (draft) => {
      draft.projectCreateUpdateErrorMsg = eventReportingInitialState.projectCreateUpdateErrorMsg;
      draft.projectById = eventReportingInitialState.projectById;
      draft.projectByIdLoadStatus = eventReportingInitialState.projectByIdLoadStatus;
    })
  ),
  on(EventReportingActions.setEventProjectFilter, (state, { projectFilter }) =>
    produce(state, (draft) => {
      draft.projectFilter = projectFilter;
    })
  ),
  on(EventReportingActions.clearProjectCreateUpdateErrorMsg, (state) =>
    produce(state, (draft) => {
      draft.projectCreateUpdateErrorMsg = null;
    })
  ),
  on(EventReportingActions.reset, () => ({...eventReportingInitialState})),
);

export const eventProjectsFeature = createFeature({
  name: 'eventProjects',
  reducer: eventProjectsReducer,
  extraSelectors: ({
                     selectProjectById,
                     selectProjectsLoadStatus,
                     selectProjectByIdLoadStatus,
                     selectProjectCreateUpdateReqStatus,
                     selectProjectUpdateStatusReqStatus
                   }) => {
    const selectIsProjectActive = createSelector(
      selectProjectById,
      (project) => project?.isActive ?? false
    );

    const selectIsProjectsPending = createSelector(
      selectProjectsLoadStatus,
      (loadStatus) => loadStatus === 'LOADING'
    );

    const selectIsProjectByIdPending = createSelector(
      selectProjectByIdLoadStatus,
      (loadStatus) => loadStatus === 'LOADING'
    );

    const selectIsProjectCreateUpdatePending = createSelector(
      selectProjectCreateUpdateReqStatus,
      (loadStatus) => loadStatus === 'LOADING'
    );

    const selectIsProjectUpdateStatusPending = createSelector(
      selectProjectUpdateStatusReqStatus,
      (loadStatus) => loadStatus === 'LOADING'
    );

    return {
      selectIsProjectActive,
      selectIsProjectsPending,
      selectIsProjectByIdPending,
      selectIsProjectCreateUpdatePending,
      selectIsProjectUpdateStatusPending
    };
  }
});
