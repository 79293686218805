import {HttpClient, HttpParams} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {catchError, Observable, of} from 'rxjs';

import {ListResponse, Pagination} from '@core/common';
import {UserCheckResult, UserProfile, UserSearchParams, UserStatus} from '@features/users/models';
import {AgGridSort, AgGridSortState} from '@shared/models';

@Injectable({ providedIn: 'root' })
export class UserApiService {
  private readonly httpClient = inject(HttpClient);

  getList(pagination: Pagination, status?: UserStatus, sorting: AgGridSortState[] | null = null): Observable<ListResponse<UserProfile>> {
    let params = new HttpParams({
      fromObject: {
        pageNumber: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
        ...(status ? { status } : null)
      }
    });

    if (sorting?.length) {
      params = sorting.reduce((parms, el) => {
        const sortingOrder = el.order === AgGridSort.DESC ? 'OrderByDescending' : 'OrderBy';
        return parms.append('PropertyNames', `${this.mapSortPropertyToDtoProperty(el.propertyName!)},${sortingOrder}`);
      }, params);
    }

    return this.httpClient.get<ListResponse<UserProfile>>(`${environment.baseURL}/users`, { params });
  }

  create(user: UserProfile): Observable<UserProfile> {
    return this.httpClient.post<UserProfile>(`${environment.baseURL}/users`, user);
  }

  update(userId: string, user: UserProfile): Observable<UserProfile> {
    return this.httpClient.put<UserProfile>(`${environment.baseURL}/users/${userId}`, user);
  }

  checkLogin(userId: string): Observable<void> {
    return this.httpClient.get<void>(`${environment.baseURL}/users/${userId}/checkLogin`);
  }

  getById(userId: string): Observable<UserProfile> {
    return this.httpClient.get<UserProfile>(`${environment.baseURL}/users/${userId}`);
  }

  getMyProfile(): Observable<UserProfile> {
    return this.httpClient.get<UserProfile>(`${environment.baseURL}/users/me`).pipe(
      catchError(() => of({ assetMapping: false, eventReporting: false } as UserProfile))
    );
  }

  changeStatus(id: string, status: UserStatus): Observable<UserProfile> {
    return this.httpClient.put<UserProfile>(`${environment.baseURL}/users/${id}/status/${status}`, null);
  }

  search(searchParams: UserSearchParams): Observable<ListResponse<UserProfile>> {
    const params = new HttpParams({
      fromObject: { ...searchParams }
    });
    return this.httpClient.get<ListResponse<UserProfile>>(`${environment.baseURL}/users`, { params });
  }

  checkUser(email: string): Observable<UserCheckResult> {
    return this.httpClient.post<UserCheckResult>(`${environment.baseURL}/users/checkEmail`, { email });
  }

  private mapSortPropertyToDtoProperty(propertyName: string): string {
    if (propertyName === 'status') {
      return 'enabled';
    }
    if (propertyName === 'organizations') {
      return 'organization';
    }
    return propertyName;
  }
}
