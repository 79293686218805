import {AsyncPipe} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  forwardRef,
  inject,
  Input,
  OnInit
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule} from '@angular/forms';
import {MatFormField} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatOption, MatSelect, MatSelectTrigger} from '@angular/material/select';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {BehaviorSubject, debounceTime, filter, finalize, ReplaySubject, switchMap, tap} from 'rxjs';

import {UserProfile} from '@features/users/models';
import {UserApiService} from '@features/users/services';
import {FullNamePipe} from '@shared/pipes';

@Component({
  selector: 'aps-user-select',
  templateUrl: './user-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatIcon,
    MatSelect,
    MatSelectTrigger,
    FullNamePipe,
    MatOption,
    NgxMatSelectSearchModule,
    AsyncPipe
  ],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UserSelectComponent),
    multi: true
  }]
})
export class UserSelectComponent implements OnInit, ControlValueAccessor {
  private readonly userService = inject(UserApiService);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly destroyRef = inject(DestroyRef);
  private _value!: UserProfile;

  @Input() multiple = false;
  primaryContactFilterCtrl = new FormControl<string>('');
  filteredPrimaryContacts$ = new ReplaySubject<UserProfile[]>(1);
  primaryContactsPending$ = new BehaviorSubject<boolean>(false);
  disabled = false;

  get value(): UserProfile {
    return this._value;
  }

  set value(val: UserProfile) {
    this._value = val;
    this.onChange(val);
    this.onTouch(val);
  }

  onChange!: (_: any) => void;
  onTouch!: (_: any) => void;

  ngOnInit(): void {
    this.primaryContactFilterCtrl.valueChanges
      .pipe(
        filter(Boolean),
        tap(() => this.primaryContactsPending$.next(true)),
        debounceTime(200),
        switchMap((val) => this.userService.search({
          email: val,
          exact: true
        })),
        tap(() => this.filteredPrimaryContacts$.next([])),
        finalize(() => this.primaryContactsPending$.next(false)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  writeValue(obj: UserProfile): void {
    this.value = obj;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.markForCheck();
  }

  comparePrimaryContacts(a1: UserProfile, a2: UserProfile): boolean {
    return a1?.id === a2?.id;
  }
}
