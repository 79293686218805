import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {MatIcon} from '@angular/material/icon';

@Component({
  selector: 'aps-resize-control',
  templateUrl: './resize-control.component.html',
  styleUrls: ['./resize-control.component.scss'],
  imports: [
    MatIcon
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResizeControlComponent {
  @Input() isFullScreen = false;
  @Output() toggleFullscreen = new EventEmitter<boolean>();

  toggle(): void {
    this.isFullScreen = !this.isFullScreen;
    this.toggleFullscreen.emit(this.isFullScreen);
  }
}
