import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  forwardRef,
  inject,
  OnInit
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {MatFormField} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatOption, MatSelect} from '@angular/material/select';
import {tap} from 'rxjs';

import {Maybe} from '@core/common';
import {PROGRESS_NAME_MAP} from '@features/projects/const';
import {ProjectProgress} from '@features/projects/models';

@Component({
  selector: 'aps-input-project-status',
  templateUrl: './input-project-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, MatFormField, MatIcon, MatSelect, MatOption],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputProjectStatusComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputProjectStatusComponent),
      multi: true
    }
  ]
})
export class InputProjectStatusComponent implements OnInit, ControlValueAccessor, Validator {
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly destroyRef = inject(DestroyRef);
  private _value!: string;

  disabled = false;
  statusControl = new FormControl<Maybe<ProjectProgress>>(null);
  projectProgress = ProjectProgress;
  progressNameMap = PROGRESS_NAME_MAP;

  get value(): any {
    return this.statusControl.getRawValue();
  }

  set value(val: ProjectProgress) {
    if (val) {
      this.statusControl.setValue(val);
    }
    this._value = val;
    this.onChange(val);
    this.onTouch(val);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (_: any) => {
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch = (_: any) => {
  };

  ngOnInit() {
    this.statusControl.valueChanges
      .pipe(
        tap(() => {
          this.onChange(this.value);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  writeValue(val: ProjectProgress): void {
    this.value = val;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.statusControl.disable();
    } else {
      this.statusControl.enable();
    }
    this.changeDetectorRef.markForCheck();
  }

  validate(): Maybe<ValidationErrors> {
    return this.statusControl.invalid ? { status: true } : null;
  }
}
