import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {map, Observable} from 'rxjs';

import {LocationFeature, LocationSearchRes} from '@shared/models';


@Injectable({ providedIn: 'root' })
export class LocationApiService {
  private readonly httpClient = inject(HttpClient);

  findLocation(searchStr: string): Observable<LocationFeature[]> {
    const encoded: string = encodeURIComponent(searchStr);

    return this.httpClient
      .get<LocationSearchRes>(`${environment.mapBaseUrl}/search?q=${encoded}&format=geojson&accept-language=en`)
      .pipe(map(l => l.features));
  }
}
