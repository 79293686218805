import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {MatIcon} from '@angular/material/icon';

@Component({
  selector: 'aps-replace-control',
  templateUrl: './replace-control.component.html',
  styleUrls: ['./replace-control.component.scss'],
  standalone: true,
  imports: [
    MatIcon
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReplaceControlComponent {
  @Input() disabled = false;
  @Output() removeClicked = new EventEmitter<boolean>();

  remove() {
    this.removeClicked.emit(true);
  }
}
