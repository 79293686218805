import {inject, Injectable} from '@angular/core';
import {FormGroup, NonNullableFormBuilder, Validators} from '@angular/forms';

import {MappingType, Project, ProjectForm} from '@features/projects/models';
import {ProjectApiService} from '@features/projects/services';

import {ask2Validator, ValidateProjectNameNotTaken} from './project-create.helper';


@Injectable({ providedIn: 'root' })
export class ProjectFormBuilder {
  private readonly fb = inject(NonNullableFormBuilder);
  private readonly projectApi = inject(ProjectApiService);

  readonly projectNameMinLength = 2;
  readonly projectNameMaxLength = 256;
  readonly descriptionMaxLength = 256;
  readonly commentMaxLength = 256;
  readonly ocNumberMaxLength = 256;
  readonly distanceMax = 1000;
  readonly poNumberMaxLength = 256;
  readonly locationMaxLength = 256;

  buildForm(project: Project): FormGroup<ProjectForm> {
    return this.fb.group<ProjectForm>({
      id: this.fb.control(null),
      name: this.fb.control('',
        [
          Validators.required,
          Validators.minLength(this.projectNameMinLength),
          Validators.maxLength(this.projectNameMaxLength)
        ],
        [ValidateProjectNameNotTaken.createValidator(this.projectApi, project)]
      ),
      ocNumber: this.fb.control('', [Validators.maxLength(this.ocNumberMaxLength), ask2Validator]),
      poNumber: this.fb.control('', [Validators.maxLength(this.poNumberMaxLength)]),
      distance: this.fb.control(null, [Validators.max(this.distanceMax)]),
      serialNumbers: this.fb.control([]),
      description: this.fb.control('', [Validators.maxLength(this.descriptionMaxLength)]),
      apsComment: this.fb.control('', [Validators.maxLength(this.commentMaxLength)]),
      organization: this.fb.control(null, Validators.required),
      technicians: this.fb.control([]),
      address: this.fb.control(''),
      latitude: this.fb.control(null),
      longitude: this.fb.control(null),
      mappingType: this.fb.control(MappingType.notSelected),
      photo: this.fb.control(''),
      progress: this.fb.control(null),
      webhookUrl: this.fb.control(''),
      leftTopLatitude: this.fb.control(null),
      leftTopLongitude: this.fb.control(null),
      rightBottomLatitude: this.fb.control(null),
      rightBottomLongitude: this.fb.control(null),
      routeValidation: this.fb.control(false),
      positiveSectionLengthTolerance: this.fb.control({
        value: null,
        disabled: true
      }, [Validators.max(100), Validators.min(1), Validators.required]),
      negativeSectionLengthTolerance: this.fb.control({
        value: null,
        disabled: true
      }, [Validators.max(100), Validators.min(1), Validators.required]),
      zoom: this.fb.control(0),
      fiberRouteFile: this.fb.control(null),
      reportFileName: this.fb.control(null),
      mapType: this.fb.control(null)
    });
  }
}
