import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  forwardRef,
  inject,
  Input,
  OnInit
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl, FormControlOptions, FormControlState,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import {MatButton, MatIconButton} from '@angular/material/button';
import {ErrorStateMatcher} from '@angular/material/core';
import {MatError, MatFormField} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatInput} from '@angular/material/input';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {MatTooltip} from '@angular/material/tooltip';
import {tap, timer} from 'rxjs';

import {DialogMode, Maybe} from '@core/common';
import {RouteValidationComponent} from '@features/projects/components/route-validation';
import {MappingType} from '@features/projects/models';
import {InputTechniciansService} from '@features/projects/services';
import {TouchedErrorStateMatcher, ValidateEmailNotTaken} from '@features/projects/utils';
import {UserBase} from '@features/users/models';


class TechnicianControl<T> extends FormControl {
  private _isReadonly = true;

  get isReadonly(): boolean {
    return this._isReadonly;
  }

  constructor(value: FormControlState<T> | T, opts: FormControlOptions, isReadonly: boolean) {
    super(value, opts);
    this._isReadonly = isReadonly;
  }
}

interface FormType {
  emails: FormArray<TechnicianControl<string>>;
}

@Component({
  selector: 'aps-input-technicians',
  templateUrl: './input-technicians.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, MatFormField, MatProgressSpinner, MatError, MatIcon, MatInput, MatIconButton, MatButton, MatTooltip, RouteValidationComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTechniciansComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputTechniciansComponent),
      multi: true
    },
    { provide: ErrorStateMatcher, useClass: TouchedErrorStateMatcher },
    InputTechniciansService
  ]
})
export class InputTechniciansComponent implements OnInit, ControlValueAccessor, Validator {
  private readonly inputTechniciansService = inject(InputTechniciansService);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly destroyRef = inject(DestroyRef);
  private readonly fb = inject(FormBuilder);

  @Input() maxTechniciansCount = 20;
  @Input() label = 'Assign Technician';
  @Input() placeholder = 'Enter email';
  @Input() mappingType!: MappingType;
  @Input() isEventReportProject = false;
  @Input() mode!: DialogMode;
  form!: FormGroup<FormType>;
  disabled = false;

  get value(): Partial<UserBase>[] {
    const emails = this.form.controls.emails.getRawValue() as string[];
    return this.emailsToBaseEntities(emails);
  }

  set value(val: Partial<UserBase>[]) {
    this.emails.clear();

    if (val?.length) {
      val.forEach((sn, i) => {
        this.emails.setControl(i, this.newEmail(sn.email, true));
      });
    } else {
      this.emails.setControl(0, this.newEmail('', false));
    }

    this.onChange(val);
    this.onTouch(val);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (_: any) => {
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch = (_: any) => {
  };

  get emails(): FormArray<TechnicianControl<string>> {
    return this.form.controls.emails;
  }

  ngOnInit() {
    this.form = this.fb.group<FormType>({
      emails: this.fb.array<TechnicianControl<string>>([this.newEmail()]) as any,
    });

    this.form.statusChanges
      .pipe(
        tap((status) => {
          this.onChange(this.value);
          if (status === 'PENDING') {
            timer(0).pipe(
              tap(() => this.form.updateValueAndValidity())
            ).subscribe();
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  writeValue(users: Partial<UserBase>[]): void {
    this.value = users;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
      this.form.controls.emails.controls.forEach((control) => {
        if (control.isReadonly) {
          control.disable({ onlySelf: true });
        }
      })
    }
    this.changeDetectorRef.markForCheck();
  }

  addEmail() {
    if (this.emails.length < this.maxTechniciansCount) {
      this.emails.push(this.newEmail());
      this.onChange(this.value);
    }
  }

  removeEmail(index: number) {
    this.emails.removeAt(index);
    this.onChange(this.value);
  }

  validate(): Maybe<ValidationErrors> {
    return this.form.invalid ? { emails: true } : null;
  }

  get tooltipText(): string {
    const message = 'Please select Mapping type for assigning technician';

    return this.mode === 'create'
    && this.disabled
    && this.mappingType === MappingType.notSelected ? message : '';
  }

  private newEmail(email = '', isReadonly = false): TechnicianControl<string> {
    return new TechnicianControl({ value: email, disabled: isReadonly }, {
      validators: [Validators.email],
      asyncValidators: [ValidateEmailNotTaken.createValidator(this.inputTechniciansService, this.changeDetectorRef, this.isEventReportProject)]
    }, isReadonly);
  }

  private emailsToBaseEntities(emails: string[]): Partial<UserBase>[] {
    const res: Partial<UserBase>[] = [];

    emails?.forEach((e) => {
      if (e && this.inputTechniciansService.foundUsersMap[e]) {
        res.push({
          email: this.inputTechniciansService.foundUsersMap[e].email
        });
      }
    });

    return res;
  }
}
