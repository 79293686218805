import {NgClass} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatError} from '@angular/material/form-field';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {environment} from '@environments/environment';
import {CRS, imageOverlay, LatLngBounds, Map, MapOptions} from 'leaflet';
import {catchError, EMPTY, filter, finalize, from} from 'rxjs';
import {take, tap} from 'rxjs/operators';

import {DialogMode, FileChangeEvent, ImageMetadata, Maybe} from '@core/common';
import {EventReportingApiService} from '@features/event-reporting/services';
import {EventReportProject, Project} from '@features/projects/models';
import {ProjectApiService} from '@features/projects/services';
import {ConfirmActionDialogComponent} from '@shared/components';
import {ReplaceControlComponent} from '@shared/components/location/components/replace-control';
import {ResizeControlComponent} from '@shared/components/location/components/resize-control';
import {ConfirmActionDialogData} from '@shared/models';
import {ImageUtilsService} from '@shared/utils/image.utils';

import {FileInputComponent} from '../file-input';

interface BlueprintImg {
  url: string;
  width: number;
  height: number;
}

@Component({
  selector: 'aps-blueprint',
  templateUrl: './blueprint.component.html',
  styleUrls: ['./blueprint.component.scss'],
  standalone: true,
  imports: [
    FileInputComponent,
    ResizeControlComponent,
    ReplaceControlComponent,
    LeafletModule,
    MatError,
    NgClass
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlueprintComponent {
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly projectApiService = inject(ProjectApiService);
  private readonly eventReportingApiService = inject(EventReportingApiService);
  private readonly imageUtils = inject(ImageUtilsService);
  private readonly dialog = inject(MatDialog);
  private _blueprintFileName!: string;

  @ViewChild(FileInputComponent, { static: true }) fileInput!: FileInputComponent;
  @Input() project!: Project | EventReportProject;
  @Input() mode!: DialogMode;
  @Input() replaceBtnAvailable = true;
  @Input() isEventReportProject = false;
  @Input() mapControlsDisabled = false;
  @Output() fileChanged = new EventEmitter<FileChangeEvent>();

  @Input() set blueprintFileName(layerUrl: string) {
    this._blueprintFileName = layerUrl;
    this.initMap();
  }

  get blueprintFileName(): string {
    return this._blueprintFileName;
  }

  blueprintImg!: Maybe<BlueprintImg>;
  map!: Map;
  isFullscreen = false;
  options!: Maybe<MapOptions>;
  layers = [];
  fileSizeErrorMsg = `The blueprint file size must not exceed the maximum limit of ${environment.blueprintMaxFileSizeMb} MB.`;

  onMapReady(event: Map): void {
    this.map = event;
  }

  toggleFullscreen(isFullscreen: boolean): void {
    this.isFullscreen = isFullscreen;

    setTimeout(() => {
      this.map.invalidateSize();
    }, 0);
  }

  remove(): void {
    const confirmActionDialogData: ConfirmActionDialogData = {
      title: 'Blueprint will be replaced',
      description: 'Replacing the blueprint may require manual adjustment of positions of existing POI if the blueprint size changes. Would you like to continue?'
    };

    this.dialog.open<ConfirmActionDialogComponent, ConfirmActionDialogData>(ConfirmActionDialogComponent, {
      width: '504px',
      data: confirmActionDialogData
    }).afterClosed()
      .pipe(
        take(1),
        filter(Boolean),
        tap(() => {
          this.fileInput.browseFile();
        })
      )
      .subscribe();
  }

  onFileChanged(event: FileChangeEvent): void {
    this.fileChanged.emit(event);
    this.initMap(true, event.fileBase64);
  }

  private initMap(force = false, fileBase64?: string): void {
    if (fileBase64) {
      this.getImageMetaDataAndSetMapOptions(fileBase64, force);
    } else if (this.mode === 'update' && this.blueprintFileName) {
      this.getBlueprintBlobAndDisplay(force);
    } else {
      this.blueprintImg = null;
      this.options = null;
    }
  }

  private getImageMetaDataAndSetMapOptions(imageData: string, force = false): void {
    from(this.imageUtils.getImageMetadata(imageData)).pipe(
      tap((metadata) => {
        this.setMapOptions(metadata, force);
      }),
      catchError(() => {
        this.blueprintImg = null;
        this.options = null;
        return EMPTY;
      }),
      finalize(() => this.cdr.markForCheck())
    ).subscribe();
  }

  private setMapOptions(imageData: ImageMetadata, force: boolean): void {
    this.blueprintImg = {
      url: imageData.url,
      width: imageData.width,
      height: imageData.height
    };
    this.options = this.getMapOptions(this.blueprintImg);
    if (force && this.map) {
      this.map.eachLayer(l => l.remove());
      this.map.addLayer(this.options.layers![0]);
      this.map.setMaxBounds(this.options.maxBounds);
      this.map.invalidateSize();
    }
  }

  private getMapOptions(blueprintImg: BlueprintImg): MapOptions {
    const bounds = new LatLngBounds([0, blueprintImg.width], [blueprintImg.height, 0]);

    return {
      layers: [
        imageOverlay(blueprintImg.url, bounds)
      ],
      maxZoom: 5,
      minZoom: -5,
      zoom: 0,
      crs: CRS.Simple,
      center: [blueprintImg.width / 2, blueprintImg.height / 2],
      maxBounds: bounds,
      attributionControl: false
    };
  }

  private getBlueprintBlobAndDisplay(force: boolean): void {
    const bpFileSource$ = this.isEventReportProject ?
      this.eventReportingApiService.getProjectFileByName(this.project.id, encodeURIComponent(this.blueprintFileName)) :
      this.projectApiService.getProjectFileByName(this.project.id, encodeURIComponent(this.blueprintFileName));

    bpFileSource$.pipe(
      tap((blob) => {
        const url = URL.createObjectURL(blob);
        this.getImageMetaDataAndSetMapOptions(url, force);
      })
    ).subscribe();
  }
}
