import {AsyncPipe, NgClass} from '@angular/common';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormGroup, ReactiveFormsModule} from '@angular/forms';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatError, MatFormField} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatInput} from '@angular/material/input';
import {MatOption, MatSelect} from '@angular/material/select';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {MatTooltip} from '@angular/material/tooltip';
import {Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {NgxMaskDirective} from 'ngx-mask';
import {NgxPermissionsModule} from 'ngx-permissions';
import {tap} from 'rxjs';

import {BaseEntity, DialogMode} from '@core/common';
import {InputOrganizationComponent} from '@features/users/components/input-organization';
import {UserDeactivateComponent} from '@features/users/components/user-deactivate';
import {USER_ROLES_MAP} from '@features/users/const';
import {UserForm, UserRole, UserStatus} from '@features/users/models';
import {UsersActions, usersFeature} from '@features/users/store';
import {UserCreateUtils, UserFormBuilder} from '@features/users/utils';
import {EntityCreateDialogComponent} from '@shared/components';
import {MapUtils} from '@shared/utils';

import {
  selectIsAdminUser,
  selectIsApsPeUser,
  selectIsPeUser,
} from '../../../../store/user-info/user-info.selectors';

@Component({
  selector: 'aps-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    EntityCreateDialogComponent,
    NgxMaskDirective,
    InputOrganizationComponent,
    NgxPermissionsModule,
    UserDeactivateComponent,
    MatFormField,
    MatIcon,
    MatError,
    AsyncPipe,
    MatInput,
    NgClass,
    MatSelect,
    MatOption,
    MatSlideToggle,
    MatCheckbox,
    MatTooltip,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserCreateComponent implements OnInit {
  private readonly store = inject(Store);
  private readonly userFormBuilder = inject(UserFormBuilder);
  private readonly destroyRef = inject(DestroyRef);
  private readonly userCreateUtils = inject(UserCreateUtils);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly mapUtils = inject(MapUtils);
  private readonly actions$ = inject(Actions);
  private readonly userStatusUpdated$ = this.actions$.pipe(
    ofType(UsersActions.updateUserStatusSuccess)
  );

  private hasUserChanged = false;
  private mode: DialogMode = 'create';

  form!: FormGroup<UserForm>;
  userRoleOptions!: BaseEntity<UserRole>[];
  submitBtnDisabled = true;

  readonly user = this.store.selectSignal(usersFeature.selectUserById);
  readonly isAuthUserIsPe = this.store.selectSignal(selectIsPeUser);
  readonly isAuthUserIsAdmin = this.store.selectSignal(selectIsAdminUser);
  readonly isAuthUserIsApsPe = this.store.selectSignal(selectIsApsPeUser);
  createUpdatePending$ = this.store.select(usersFeature.selectIsUserCreateUpdatePending);

  assetMappingTooltipText = 'Asset Mapping mode allows the user to document, check and verify the fiber installation of an asset';
  eventReportingTooltipText = 'Event Reporting mode allows the user to document suspicious events';

  readonly userRole = UserRole;
  readonly firstNameMinLength = this.userFormBuilder.firstNameMinLength;
  readonly firstNameMaxLength = this.userFormBuilder.firstNameMaxLength;
  readonly lastNameMinLength = this.userFormBuilder.lastNameMinLength;
  readonly lastNameMaxLength = this.userFormBuilder.lastNameMaxLength;
  readonly jobTitleMaxLength = this.userFormBuilder.jobTitleMaxLength;
  readonly commentMaxLength = this.userFormBuilder.commentMaxLength;

  get isUpdateMode(): boolean {
    return this.mode === 'update';
  }

  ngOnInit(): void {
    this.initForm();
    this.detectMode();
    this.checkPrimaryContactToggleState();
    this.buildRolesOptions();
    if (!this.isAuthUserIsPe()) {
      this.listenFormChanges();
      this.checkSubmitBtnDisabled();
    }
  }

  submit(): void {
    if (!this.form.valid) {
      return;
    }
    this.form.disable();
    const userCreateUpdateDto = this.userCreateUtils.setIsPrimaryContactToOrganizations(
      this.form.getRawValue(),
      this.form.controls.isPrimaryContact.value
    );

    if (this.isUpdateMode) {
      this.store.dispatch(UsersActions.updateUser(userCreateUpdateDto));
    } else {
      this.store.dispatch(UsersActions.createUser(userCreateUpdateDto));
    }
  }

  close(): void {
    this.store.dispatch(UsersActions.closeUserPreviewDialog());
  }

  resetOrganizationValue(): void {
    if (!this.isUpdateMode) {
      this.form.controls.organizations.setValue([]);
      this.form.controls.isPrimaryContact.setValue(false);
    }
  }

  private initForm(): void {
    this.form = this.userFormBuilder.buildForm();
    if (this.user()?.id) {
      this.form.patchValue({
        ...this.user(),
        isPrimaryContact: this.userCreateUtils.isPrimaryContactInAllOrganizations(this.user()!),
        sendEmailInvitation: false,
        isMustResetPassword: false
      });
    }
  }

  private listenFormChanges(): void {
    this.checkIfFormDataWasChanged();
    this.form.valueChanges
      .pipe(
        tap(() => {
          this.checkIfFormDataWasChanged();
          this.checkSubmitBtnDisabled();
          this.checkPrimaryContactToggleState();
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();

    this.form.statusChanges
      .pipe(
        tap(() => {
          this.checkSubmitBtnDisabled();
          setTimeout(() => {
            this.changeDetectorRef.markForCheck();
          });
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  private detectMode(): void {
    this.mode = this.user()?.id ? 'update' : 'create';
    if (this.isAuthUserIsPe()) {
      this.form.disable();
      return;
    }

    if (this.isUpdateMode) {
      this.listenUserStatusChanges();

      if (this.user()?.status !== UserStatus.ACTIVE) {
        this.form.disable();
        return;
      } else {
        this.form.enable();
        this.form.controls.role.disable();
      }
      if (this.form.controls.role.value === UserRole.TECHNICIAN || this.user()?.organizations?.length) {
        this.toggleImmutableFieldsState(true);
      }
      if (this.isAuthUserIsAdmin() && this.user()?.role === UserRole.APS_PE) {
        this.toggleImmutableFieldsState(false);
      }
    }
  }

  private checkSubmitBtnDisabled(): void {
    this.submitBtnDisabled = this.form.invalid ||
      (this.isUpdateMode && !this.hasUserChanged) ||
      this.form.controls.email.pending;
  }

  private buildRolesOptions(): void {
    const userRoles = this.mapUtils
      .getMapEntries(USER_ROLES_MAP)
      .map(([id, name]) => ({ id, name }));

    if (this.isAuthUserIsApsPe()) {
      this.userRoleOptions = userRoles.filter(v => {
        return [UserRole.PE, UserRole.TECHNICIAN].includes(v.id) || (this.isUpdateMode && v.id === UserRole.APS_PE);
      });
    }

    if (this.isAuthUserIsPe()) {
      this.userRoleOptions = userRoles.filter(v => {
        return UserRole.TECHNICIAN === v.id || (this.isUpdateMode && v.id === UserRole.PE);
      });
    }

    if (this.isAuthUserIsAdmin()) {
      if (this.isUpdateMode) {
        this.userRoleOptions = userRoles;
      } else {
        this.userRoleOptions = userRoles.filter(v => v.id !== UserRole.APS_ADMIN);
      }
    }
  }

  private toggleImmutableFieldsState(disable = false): void {
    if (disable) {
      this.form.controls.organizations.disable({ emitEvent: false });
    } else {
      this.form.controls.organizations.enable({ emitEvent: false });
    }
  }

  private checkIfFormDataWasChanged(): void {
    this.hasUserChanged = !this.userCreateUtils.areUsersEqual(this.form.getRawValue(), this.user()!);
  }

  private checkPrimaryContactToggleState(): void {
    if (this.user()?.status !== UserStatus.ACTIVE) {
      this.form.controls.isPrimaryContact.disable({ emitEvent: false });
    } else {
      if (this.form.controls.organizations.value?.length) {
        this.form.controls.isPrimaryContact.enable({ emitEvent: false });
      } else {
        this.form.controls.isPrimaryContact.disable({ emitEvent: false });
      }
    }
  }

  private listenUserStatusChanges(): void {
    this.userStatusUpdated$.pipe(
      tap(({ status }) => {
        if (status === UserStatus.ACTIVE) {
          this.form.enable();
        } else {
          this.form.disable();
        }
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }
}
