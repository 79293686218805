<form>
  <label>{{ label }}</label>
  <mat-form-field class="w-full" appearance="outline">
    @if (pending$ | async) {
      <mat-spinner matPrefix [diameter]="16" class="mx-3" mode="indeterminate" />
    } @else {
      <mat-icon matPrefix class="color-medium" svgIcon="marker" />
    }
    <input type="text"
           matInput
           data-test="e2e-autocomplete-input"
           [placeholder]="placeholder"
           [formControl]="locationControl"
           [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete"
                      [displayWith]="displayFn">
      @for (option of filteredOptions$ | async; track option.properties?.display_name) {
        <mat-option data-test="e2e-autocomplete-option" [value]="option">{{ option?.properties?.display_name }}</mat-option>
      }
    </mat-autocomplete>
    @if (locationControl.invalid) {
      <mat-error>
        @if (locationControl?.errors?.maxlength) {
          <span>Max length is {{ locationMaxLength }}</span>
        }
      </mat-error>
    }
  </mat-form-field>
</form>
