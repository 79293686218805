import {Injectable} from '@angular/core';

import {ImageMetadata} from '@core/common';

@Injectable({
  providedIn: 'root'
})
export class ImageUtilsService {

  async getImageMetadata(imgUrl: string): Promise<ImageMetadata> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = imgUrl;
      img.onload = () => {
        resolve({
          width: img.width,
          height: img.height,
          url: img.src
        } as ImageMetadata);
      };
      img.onerror = () => {
        reject('Image failed to load');
      };
    });
  }
}
