import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatLatestFrom} from '@ngrx/operators';
import {Store} from '@ngrx/store';
import {catchError, filter, forkJoin, from, iif, map, Observable, of, switchMap, tap, throwError} from 'rxjs';
import {take} from 'rxjs/operators';

import {BackendError, DEFAULT_MODAL_WIDTH, ErrorCode, Maybe} from '@core/common';
import {ProjectCreateComponent} from '@features/projects/components/project-create';
import {Project} from '@features/projects/models';
import {ProjectApiService} from '@features/projects/services';
import {ProjectsActions} from '@features/projects/store/projects.actions';
import {assetProjectsFeature} from '@features/projects/store/projects.feature';
import {ConfirmActionDialogComponent} from '@shared/components';
import {ConfirmActionDialogData} from '@shared/models';
import {NotificationService} from '@shared/services';

@Injectable()
export class ProjectsEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly projectApi = inject(ProjectApiService);
  private readonly notification = inject(NotificationService);
  private readonly dialog = inject(MatDialog);

  loadProjects$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ProjectsActions.loadAssetProjects,
        ProjectsActions.createAssetProjectSuccess,
        ProjectsActions.updateAssetProjectSuccess,
        ProjectsActions.updateAssetProjectStatusSuccess
      ),
      concatLatestFrom(() => [
        this.store.select(assetProjectsFeature.selectProjectsPagination),
        this.store.select(assetProjectsFeature.selectSortingState),
        this.store.select(assetProjectsFeature.selectProjectFilter)
      ]),
      switchMap(([_, pagination, sortingState, projFilter]) => {
        return this.projectApi.getProjects(pagination, sortingState, projFilter).pipe(
          map((response) => ProjectsActions.loadAssetProjectsSuccess(response)),
          catchError((err) => {
            this.notification.error('Failed to fetch projects.');
            console.error('Failed to fetch projects!', err);
            return of(ProjectsActions.loadAssetProjectsFailure());
          })
        );
      })
    );
  });

  createProject$ = createEffect(() => {
    let createdProjectId: Maybe<number> = null;

    return this.actions$.pipe(
      ofType(ProjectsActions.createAssetProject),
      switchMap(({ req, fiberRouteFile, blueprintFile }) => {
        const uploadFileOperation$ = blueprintFile ?
          this.projectApi.uploadFile(blueprintFile) :
          of('');
        return uploadFileOperation$
          .pipe(
            tap((bpFileName) => req.photo = bpFileName),
            switchMap(() => this.projectApi.create(req).pipe(
              tap(resp => {
                this.notification.success(`Created successfully`);
                createdProjectId = resp.value!;
              })
            )),
            switchMap((response) => fiberRouteFile ?
              this.uploadFiberRouteFile(response.value!, fiberRouteFile) :
              of('')
            ),
            map(() => ProjectsActions.createAssetProjectSuccess()),
            catchError((err) => {
              if (err instanceof BackendError) {
                // need to reopen modal on edit mode with new project
                this.notification.error(err.message);
                if (typeof createdProjectId === 'number') {
                  this.dialog.closeAll();
                  return from([ProjectsActions.openAssetProjectPreviewDialog(createdProjectId, err.message), ProjectsActions.loadAssetProjects()]) ;
                }
              } else {
                this.notification.error();
              }
              return of(ProjectsActions.createAssetProjectFailure());
            })
          );
      })
    );
  });

  updateProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectsActions.updateAssetProject),
      concatLatestFrom(() => this.store.select(assetProjectsFeature.selectProjectById)),
      switchMap(([{ req, fiberRouteFile, blueprintFile }, project]) => {
        const uploadFileOperation$ = blueprintFile ?
          this.projectApi.updateFile(project!.id, blueprintFile) :
          of('');

        const uploadFiberRouteFileOperation$ = fiberRouteFile ?
          this.uploadFiberRouteFile(project!.id, fiberRouteFile) :
          of('');

        return forkJoin([
          uploadFileOperation$,
          uploadFiberRouteFileOperation$
        ]).pipe(
          tap(([bpFileName]) => req.photo = bpFileName || req.photo),
          switchMap(() => this.projectApi.update(project!.id, req)),
          map(() => {
            this.notification.success(`Updated successfully`);
            return ProjectsActions.updateAssetProjectSuccess();
          }),
          catchError((err) => {
            if (err instanceof BackendError) {
              this.notification.error('Failed to upload file');
              return of(ProjectsActions.updateAssetProjectFailure(err.message))
            }
            this.notification.error('Failed to update project.');
            console.error('Failed to update project!', err);
            return of(ProjectsActions.updateAssetProjectFailure());
          })
        );
      }),
    );
  });

  changeProjectStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectsActions.updateAssetProjectStatus),
      concatLatestFrom(() => this.store.select(assetProjectsFeature.selectProjectById)),
      switchMap(([{ isActive }, project]) => {
        const confirmActionDialogData: ConfirmActionDialogData = {
          title: `Project will be ${isActive ? 'activated' : 'deactivated'}`,
          description: `Do you want to ${isActive ? 'activate' : 'deactivate'} this project?`
        };

        return this.dialog.open(ConfirmActionDialogComponent, {
          width: '504px',
          data: confirmActionDialogData
        }).afterClosed()
          .pipe(
            take(1),
            switchMap((value) => iif(
              () => Boolean(value),
              this.projectApi.updateStatus(project?.id!, isActive).pipe(
                map(() => {
                  this.notification.success(`Project successfully ${isActive ? 'activated' : 'deactivated'}`);
                  return ProjectsActions.updateAssetProjectStatusSuccess(isActive);
                }),
                catchError((err) => {
                  this.notification.error('Failed to update project status.');
                  console.error('Failed to update project status!', err);
                  return of(ProjectsActions.updateAssetProjectStatusFailure());
                })
              ),
              of(ProjectsActions.updateAssetProjectStatusCancel())
            ))
          );
      })
    );
  });

  checkIfProjectPendingBeforePreviewDialogOpen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectsActions.openAssetProjectPreviewDialog),
      concatLatestFrom(() => this.store.select(assetProjectsFeature.selectIsProjectByIdPending).pipe(
        filter(pending => !pending)
      )),
      map(([{ id }]) => ProjectsActions.loadAssetProjectById(id))
    );
  });

  loadProjectById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectsActions.loadAssetProjectById),
      switchMap(({ id }) => this.projectApi.getById(id).pipe(
        map((response) => ProjectsActions.loadAssetProjectByIdSuccess(response)),
        catchError((err) => {
          this.notification.error(`Failed to fetch project data.`);
          console.error(`Failed to fetch project. ID ${id}`, err);
          return of(ProjectsActions.loadAssetProjectByIdFailure());
        })
      ))
    );
  });

  closeProjectDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ProjectsActions.closeAssetProjectPreviewDialog,
        ProjectsActions.createAssetProjectSuccess,
        ProjectsActions.createAssetProjectFailure,
        ProjectsActions.updateAssetProjectSuccess,
      ),
      tap(() => this.dialog.closeAll())
    );
  }, { dispatch: false });

  openProjectUpdateDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectsActions.loadAssetProjectByIdSuccess),
      switchMap(() => this.dialog.open(ProjectCreateComponent, {
        width: DEFAULT_MODAL_WIDTH,
        closeOnNavigation: true
      }).afterClosed()),
      map(() => ProjectsActions.clearAssetProjectById())
    );
  });

  updateProjectsList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectsActions.updateAssetProjectsSorting, ProjectsActions.updateAssetProjectsPagination, ProjectsActions.setAssetProjectFilter),
      map(() => ProjectsActions.loadAssetProjects())
    );
  });

  uploadFiberRouteFile(projectId: number, file: File): Observable<Project> {
    return this.projectApi.uploadFiberRouteFile(projectId, file).pipe(
      catchError((err: unknown) => {
        // Temp solution. Replace when error codes support will be added to backend
        if (err instanceof HttpErrorResponse && (err.status as HttpStatusCode) === HttpStatusCode.BadRequest) {
          if (typeof err?.error === 'string' && err?.error?.includes('Parsing exception')) {
            return throwError(() => new BackendError(
              'File format incorrect. CSV file must contain four columns, separated by semicolon',
              ErrorCode.INVALID_CSV
            ));
          }
          if (err?.error?.isValidFile === false) {
            return throwError(() => new BackendError(
              'The file contains more than one reference route. Please select a file with only one reference route.',
              ErrorCode.INVALID_KML_KMZ
            ));
          }
        }
        return throwError(() => err as Error);
      })
    );
  }
}
