import {createSelector} from '@ngrx/store';

import {UserRole} from '@features/users/models';

import {AppState} from '../core.state';

export const selectUserInfoState = (state: AppState) => state.userInfo;

export const selectUserProfile = createSelector(
  selectUserInfoState,
  (state) => state?.userProfile,
);

export const selectKeycloakRoles = createSelector(
  selectUserInfoState,
  (state) => state?.keycloakPermissions,
);

export const selectIsPeUser = createSelector(
  selectKeycloakRoles,
  (keycloakPermissions) => keycloakPermissions?.includes(UserRole.PE) ?? false,
)

export const selectIsAdminUser = createSelector(
  selectKeycloakRoles,
  (keycloakPermissions) => keycloakPermissions?.includes(UserRole.APS_ADMIN) ?? false,
)

export const selectIsApsPeUser = createSelector(
  selectKeycloakRoles,
  (keycloakPermissions) => keycloakPermissions?.includes(UserRole.APS_PE) ?? false,
)
