import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {MatInput} from '@angular/material/input';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {MatFormField, MatOption, MatSelect} from '@angular/material/select';
import {MatTooltip} from '@angular/material/tooltip';
import {environment} from '@environments/environment';

import {BaseEntity, Maybe} from '@core/common';
import {EventReportProject, MappingType, Project, ProjectProgress} from '@features/projects/models';
import {DownloadFileDirective} from '@shared/directives';
import {FileUtilsService} from '@shared/utils';

type AlarmConfigType = 'CSV(EN54-22 A1N compliant)' | 'CSV(UL521 UL15F compliant)' | 'CSV (MaxTemp)';
type ReportFileType = 'pdf' | 'csv' | 'kml' | 'archive' | 'alarm-pdf';
type CsvReportFormat = 'default' | 'dts' | 'smartVision';

@Component({
  selector: 'aps-project-export',
  templateUrl: './project-export.component.html',
  standalone: true,
  imports: [
    FormsModule,
    MatSelect,
    MatFormField,
    MatOption,
    MatTooltip,
    MatIcon,
    MatProgressSpinner,
    DownloadFileDirective,
    MatButton,
    MatInput
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectExportComponent implements OnInit {
  private readonly fileUtils = inject(FileUtilsService);
  private _serialNumbers: string[] = [];

  @Input() project!: Project | EventReportProject;
  @Input() isEventReportProject = false;
  @Output() downloadStateChange = new EventEmitter<boolean>();

  csvFormatOptions!: BaseEntity<string>[];
  fileTypeOptions!: BaseEntity<string>[];
  serialNumbersOptions!: BaseEntity<string>[];
  alarmConfigOptions!: BaseEntity<string>[];
  selectedSerialNumber!: Maybe<string>;
  channelNumber!: string;

  tooltips = {
    assetNameText: 'Enter the asset (Circuit or Pipeline) name for which the mapping shall be applied',
    serialNumberText: 'Select the instrument serial number for which the mapping shall be applied',
    channelNumberText: 'Select the channel number of the instrument for which the mapping shall be applied',
    alarmConfigurationText: 'Select the standard whose alarm parameters are to be applied to the alarm zone configuration to be exported',
    formatText: 'Select the target format according to the desired use. N45-series DTS generates a CSV which can be directly applied as alarm zone configuration on the DTS. If the mapping configuration is to be imported into SmartVision, select SmartVision. For individual use, select Standard'
  };

  selectedCsvFormat: CsvReportFormat = 'default';
  selectedFileType: ReportFileType = 'pdf';
  assetNameMaxLength = 70;
  assetName = '';
  selectedAlarmConfig: AlarmConfigType = 'CSV(EN54-22 A1N compliant)';
  mappingType = MappingType;

  disabled = false;
  downloadInProgress = false;
  downloadError = false;

  @Input() set serialNumbers(value: string[]) {
    this._serialNumbers = value || [];
    this.selectedSerialNumber = null;
    this.setSerialNumbersOptions();
    this.setCsvFormatOptions();
  }

  @HostListener('window:beforeunload')
  beforeUnloadHandler() {
    return !this.downloadInProgress;
  }

  get downloadUrl(): string {
    if (this.isEventReportProject) {
      return this.getDownloadUrlForEventReport();
    }
    return this.getDownloadUrlForAssetMapping();
  }

  get isValidSmartVisionParams(): boolean {
    if (this.selectedFileType === 'csv' && this.selectedCsvFormat === 'smartVision') {
      return Boolean(this.selectedSerialNumber && this.assetName && this.channelNumber && !isNaN(Number(this.channelNumber)));
    }
    return true;
  }

  ngOnInit(): void {
    this.disabled = this.isInactiveProject();
    this.setFileTypeOptions();
    this.setCsvFormatOptions();
    this.setAlarmConfigOptions();
    this.setSerialNumbersOptions();
  }

  handleDownloadInProgressEvent(isInProgress: boolean): void {
    this.downloadStateChange.emit(isInProgress);
    this.disabled = isInProgress;
    this.downloadInProgress = isInProgress;
  }

  private setFileTypeOptions(): void {
    this.fileTypeOptions = [
      { id: 'pdf', name: 'PDF' },
      { id: 'csv', name: 'CSV' },
      { id: 'kml', name: 'KML/KMZ' },
      { id: 'archive', name: 'Media Files' }
    ];
    if (this.isEventReportProject) {
      this.fileTypeOptions = this.fileTypeOptions.filter(v => v.id === 'pdf');
      if ((this.project as EventReportProject).hasAlarms) {
        this.fileTypeOptions.push({ id: 'alarm-pdf', name: 'PDF (Alarms)' });
      }
      this.fileTypeOptions.push({ id: 'archive', name: 'JSON collection' })
    } else {
      const fileExt = this.fileUtils.getFileExtensionFromName((this.project as Project).reportFileName);
      if ((this.project as Project).mappingType === MappingType.bluePrint || (fileExt !== 'kml' && fileExt !== 'kmz')) {
        this.fileTypeOptions = this.fileTypeOptions.filter(v => v.id !== 'kml');
      }
    }
  }

  private setCsvFormatOptions(): void {
    const fileExt = this.fileUtils.getFileExtensionFromName((this.project as Project).reportFileName);

    this.csvFormatOptions = [
      { id: 'default', name: 'Standard' },
      { id: 'dts', name: 'N45-series DTS' },
      { id: 'smartVision', name: 'SmartVision' }
    ];
    if (!this.isDtsNumbersPresentInProject()) {
      this.csvFormatOptions = this.csvFormatOptions.filter(v => v.id !== 'dts');
    }
    if (fileExt !== 'csv') {
      this.csvFormatOptions = this.csvFormatOptions.filter(v => v.id !== 'smartVision');
    }
  }

  private setAlarmConfigOptions(): void {
    this.alarmConfigOptions = [
      { id: 'CSV(EN54-22 A1N compliant)', name: 'CSV (EN54-22 A1N compliant)' },
      { id: 'CSV(UL521 UL15F compliant)', name: 'CSV (UL521 UL15F compliant)' },
      { id: 'CSV (MaxTemp)', name: 'CSV (MaxTemp)' }
    ];
  }

  private isDtsNumbersPresentInProject(): boolean {
    const dtsPrefix = 'DE4';
    return this._serialNumbers.some(v => v.startsWith(dtsPrefix));
  }

  private setSerialNumbersOptions(): void {
    this.serialNumbersOptions = this._serialNumbers.map(v => ({ id: v, name: v }));
  }

  private isInactiveProject(): boolean {
    if (this.isEventReportProject) {
      return false;
    }
    return (this.project as Project)?.progress !== ProjectProgress.inProgress && (this.project as Project)?.progress !== ProjectProgress.completed;
  }

  private getDownloadUrlForEventReport(): string {
    if (this.selectedFileType === 'archive') {
      return `${environment.baseURL}/eventreporting/${this.project.id}/archive`;
    }
    return this.selectedFileType === 'alarm-pdf' ?
      `${environment.baseURL}/eventreporting/projects/${this.project.id}/alarms/reports/pdf` :
      `${environment.baseURL}/eventreporting/projects/${this.project.id}/events/reports/pdf`;
  }

  private getDownloadUrlForAssetMapping(): string {
    const baseUrlForRegularProject = `${environment.baseURL}/projects/${this.project.id}`;
    if (this.selectedFileType === 'archive') {
      return `${baseUrlForRegularProject}/archive`;
    }
    if (this.selectedFileType === 'csv' && this.selectedCsvFormat === 'dts') {
      return `${baseUrlForRegularProject}/report/alarmZone/${this.selectedAlarmConfig}`;
    }
    if (this.selectedFileType === 'csv' && this.selectedCsvFormat === 'smartVision') {
      return `${baseUrlForRegularProject}/assetRoute/export/csv?serialNumber=${this.selectedSerialNumber}&assetName=${this.assetName.trim()}&channelNumber=${this.channelNumber}`;
    }
    return `${baseUrlForRegularProject}/report/${this.selectedFileType}`;
  }
}
