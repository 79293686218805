import {AsyncPipe} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {Store} from '@ngrx/store';

import {assetProjectsFeature, ProjectsActions} from '@features/projects/store';
import {BtnPendingDirective, LetDirective} from '@shared/directives';

@Component({
  selector: 'aps-project-deactivate',
  templateUrl: './project-deactivate.component.html',
  standalone: true,
  imports: [BtnPendingDirective, MatButton, LetDirective, AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectDeactivateComponent {
  private readonly store = inject(Store);
  isActiveProject = input();

  pending$ = this.store.select(assetProjectsFeature.selectIsProjectUpdateStatusPending);

  deactivate(): void {
    this.store.dispatch(ProjectsActions.updateAssetProjectStatus(false));
  }

  activate(): void {
    this.store.dispatch(ProjectsActions.updateAssetProjectStatus(true));
  }
}
