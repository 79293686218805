import {inject, Injectable} from '@angular/core';
import {FormGroup, NonNullableFormBuilder, Validators} from '@angular/forms';

import {EventReportingApiService} from '@features/event-reporting/services';
import {EventReportProject, EventReportProjectForm, MappingType} from '@features/projects/models';
import {ValidateEventReportProjectNameNotTaken} from '@features/projects/utils';


@Injectable({ providedIn: 'root' })
export class EventReportProjectFormBuilder {
  private readonly fb = inject(NonNullableFormBuilder);
  private readonly eventReportingApiService = inject(EventReportingApiService);

  readonly projectNameMinLength = 2;
  readonly projectNameMaxLength = 256;
  readonly descriptionMaxLength = 256;

  buildForm(project: EventReportProject): FormGroup<EventReportProjectForm> {
    return this.fb.group<EventReportProjectForm>({
      id: this.fb.control(null),
      name: this.fb.control('',
        [
          Validators.required,
          Validators.minLength(this.projectNameMinLength),
          Validators.maxLength(this.projectNameMaxLength)
        ],
        [ValidateEventReportProjectNameNotTaken.createValidator(this.eventReportingApiService, project)]
      ),
      description: this.fb.control('', [Validators.maxLength(this.descriptionMaxLength)]),
      organization: this.fb.control(null, Validators.required),
      technicians: this.fb.control([]),
      assignedUsersToAlarmNotification: this.fb.control([]),
      latitude: this.fb.control(null),
      longitude: this.fb.control(null),
      address: this.fb.control(''),
      webhookUrl: this.fb.control(''),
      blueprintFileName: this.fb.control(''),
      fiberRouteFile: this.fb.control(null),
      fiberRouteFileName: this.fb.control(null),
      mappingType: this.fb.control(MappingType.notSelected),
    });
  }
}
