import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  forwardRef,
  inject,
  Input,
  OnInit
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import {combineLatest, map, tap} from 'rxjs';

import {DataFetcherFn, Maybe, Pagination} from '@core/common';
import {OrganizationListItemResponse, OrganizationStatus} from '@features/organizations/models';
import {OrganizationApiService} from '@features/organizations/services';
import {UserOrganization, UserProfile, UserRole} from '@features/users/models';
import {InfiniteScrollSelectorComponent} from '@shared/components';
import {AgGridSort} from '@shared/models';

@Component({
  selector: 'aps-input-organization',
  templateUrl: './input-organization.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [InfiniteScrollSelectorComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputOrganizationComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputOrganizationComponent),
      multi: true
    }
  ]
})
export class InputOrganizationComponent implements OnInit, ControlValueAccessor, Validator {
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly organizationService = inject(OrganizationApiService);
  private readonly destroyRef = inject(DestroyRef);
  private _value!: UserOrganization[];
  private _userRole!: UserRole;
  private organizationsNamesMap = new Map<number, string>();

  @Input() user!: UserProfile;
  @Input() isUpdateMode = false;

  @Input() set userRole(userRole: UserRole) {
    this._userRole = userRole;
    this.initCtrl(this._userRole);
  }

  get userRole(): UserRole {
    return this._userRole;
  }

  get isTechnicianRole(): boolean {
    return this._userRole === UserRole.TECHNICIAN;
  }

  multiple = false;
  singleCtrl = new FormControl<Maybe<number>>(null, Validators.required);
  multipleCtrl = new FormControl<number[]>([], Validators.required);
  disabled = false;
  activeUserOrganizationsOptions: UserOrganization[] = [];
  organizationFetchFn!: DataFetcherFn;

  get value(): UserOrganization[] {
    if (this._userRole === UserRole.APS_PE) {
      return this.idsToBaseEntity(this.multipleCtrl.value ?? []);
    } else if (this._userRole === UserRole.PE) {
      return this.idsToBaseEntity(this.singleCtrl.value ? [this.singleCtrl.value] : []);
    } else {
      return [];
    }
  }

  set value(val: UserOrganization[]) {
    const ids = this.baseEntitiesToNumberArr(val);
    this.singleCtrl.setValue(ids[0]);
    this.multipleCtrl.setValue(ids);
    this._value = val;
    this.onChange(val);
    this.onTouch(val);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (_: any) => {
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch = (_: any) => {
  };

  ngOnInit() {
    this.initCtrl(this._userRole);
    this.initOrganizationFetchFn();
    this.registerCtrlOnChange();
    this.activeUserOrganizationsOptions = (this.user?.organizations ?? []).map((v) => ({
      ...v,
      isDisabled: this.isUpdateMode
    }));
  }

  writeValue(organizations: UserOrganization[]): void {
    this.value = organizations;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.singleCtrl.disable();
      this.multipleCtrl.disable();
    } else {
      this.singleCtrl.enable();
      this.multipleCtrl.enable();
    }

    this.changeDetectorRef.markForCheck();
  }

  validate(): Maybe<ValidationErrors> {
    if (this.userRole === UserRole.APS_PE) {
      return this.multipleCtrl.invalid ? { required: true } : null;
    } else if (this.userRole === UserRole.PE) {
      return this.singleCtrl.invalid ? { required: true } : null;
    } else {
      return null;
    }
  }

  private initCtrl(userRole: UserRole) {
    if (userRole === UserRole.APS_PE) {
      this.multiple = true;

      this.singleCtrl.setValue(null);
      this.multipleCtrl.setValue([]);
      this.singleCtrl.enable();
      this.multipleCtrl.enable();
      this.singleCtrl.markAsUntouched();
      this.multipleCtrl.markAsUntouched();
    } else if (userRole === UserRole.PE) {
      this.multiple = false;
      this.singleCtrl.setValue(null);
      this.multipleCtrl.setValue([]);
      this.singleCtrl.enable();
      this.multipleCtrl.enable();
      this.singleCtrl.markAsUntouched();
      this.multipleCtrl.markAsUntouched();
    } else {
      this.disabled = true;
      this.singleCtrl.setValue(null);
      this.multipleCtrl.setValue([]);
      this.singleCtrl.disable();
      this.multipleCtrl.disable();
      this.singleCtrl.markAsUntouched();
      this.multipleCtrl.markAsUntouched();
    }
  }

  private baseEntitiesToNumberArr(baseEntities: UserOrganization[]): number[] {
    return baseEntities?.map(e => e.id) || [];
  }

  private initOrganizationFetchFn(): void {
    const organizationSortSettings = {
      propertyName: 'organizationName',
      order: AgGridSort.ASC
    };
    this.organizationFetchFn = (pagination: Pagination) => {
      return this.organizationService.getOrganizations(pagination, OrganizationStatus.active, [organizationSortSettings]).pipe(
        map(response => {
          return {
            pagination: response.pagination,
            data: this.mapUserOrganizationsToBaseEntity(response.data ?? []),
          };
        })
      );
    };
  }

  private mapUserOrganizationsToBaseEntity(organizations: Partial<OrganizationListItemResponse>[]): UserOrganization[] {
    return organizations.map(({ id, organizationName }) => {
      this.organizationsNamesMap.set(id!, organizationName!);
      return {
        id,
        name: organizationName,
        isDisabled: this.isUpdateMode && Boolean(this.value?.find(v => v.id === id))
      };
    }) as UserOrganization[];
  }

  private registerCtrlOnChange(): void {
    combineLatest([this.singleCtrl.valueChanges, this.multipleCtrl.valueChanges])
      .pipe(
        tap(() => {
          this.onChange(this.value);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private idsToBaseEntity(ids: number[]): UserOrganization[] {
    return ids.map((id) => ({ id, name: this.organizationsNamesMap.get(id) }));
  }
}
