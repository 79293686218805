import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  forwardRef,
  inject,
  Input,
  OnInit
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {MatButton, MatIconButton} from '@angular/material/button';
import {MatError, MatFormField} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatInput} from '@angular/material/input';
import {tap} from 'rxjs';

import {Maybe} from '@core/common';
import {ValidateSerialNumber} from '@features/projects/utils';


interface FormType {
  serialNumbers: FormArray<FormControl<string>>;
}

@Component({
  selector: 'aps-input-serial-numbers',
  templateUrl: './input-serial-numbers.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, MatFormField, MatError, MatIcon, MatButton, MatIconButton, MatInput],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputSerialNumbersComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputSerialNumbersComponent),
      multi: true
    }
  ]
})
export class InputSerialNumbersComponent implements OnInit, ControlValueAccessor, Validator {
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly fb = inject(FormBuilder);
  private readonly destroyRef = inject(DestroyRef);
  private _value!: string[];

  @Input() maxLength = 10;
  @Input() label = 'Instrument Serial Number(s)';
  @Input() placeholder = 'Enter Serial Number';
  form!: FormGroup<FormType>;
  disabled = false;

  get value(): string[] | undefined {
    if (this.form.valid) {
      const { value: { serialNumbers } } = this.form;
      return serialNumbers;
    }

    return [];
  }

  set value(val: string[]) {
    let serialNumbers: string[];
    if (!val?.length) {
      serialNumbers = [''];
    } else {
      serialNumbers = val;
    }

    serialNumbers.forEach((sn, i) => {
      this.serialNumbers.setControl(i, this.newSerialNumber(sn));
    });

    this._value = val;
    this.onChange(val);
    this.onTouch(val);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (_: any) => {
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch = (_: any) => {
  };

  get serialNumbers(): FormArray<FormControl<string>> {
    return this.form.controls.serialNumbers ;
  }


  ngOnInit() {
    this.form = this.fb.group({
      serialNumbers: this.fb.array([this.newSerialNumber()])
    });

    this.form.valueChanges
      .pipe(
        tap(() => {
          this.onChange(this.value);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  writeValue(serialNumbers: string[]): void {
    this.value = serialNumbers;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
    this.changeDetectorRef.markForCheck();
  }

  addSerialNumber() {
    if (this.serialNumbers.length < this.maxLength) {
      this.serialNumbers.push(this.newSerialNumber());
      this.onChange(this.value);
    }
  }

  removeSerialNumber(index: number) {
    this.serialNumbers.removeAt(index);
    this.onChange(this.value);
  }

  validate(): Maybe<ValidationErrors> {
    return this.form.invalid ? { serialNumbers: true } : null;
  }

  private newSerialNumber(serialNumber = ''): FormControl<string> {
    return this.fb.control<string>(serialNumber, [ValidateSerialNumber]) as FormControl<string>;
  }
}
