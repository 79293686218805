import {AsyncPipe} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input
} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {Store} from '@ngrx/store';

import {UserStatus} from '@features/users/models';
import {UsersActions, usersFeature} from '@features/users/store';
import {BtnPendingDirective, LetDirective} from '@shared/directives';

@Component({
  selector: 'aps-user-deactivate',
  templateUrl: './user-deactivate.component.html',
  standalone: true,
  imports: [
    BtnPendingDirective,
    MatButton,
    AsyncPipe,
    LetDirective
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDeactivateComponent {
  private readonly store = inject(Store);
  userStatus = input();
  isActiveUser = computed(() => this.userStatus() === UserStatus.ACTIVE);
  pending$ = this.store.select(usersFeature.selectIsUserUpdateStatusPending);

  deactivate(): void {
    this.store.dispatch(UsersActions.updateUserStatus(UserStatus.INACTIVE));
  }

  activate(): void {
    this.store.dispatch(UsersActions.updateUserStatus(UserStatus.ACTIVE));
  }
}
