import {createActionGroup, emptyProps} from '@ngrx/store';

import {ListResponse, Maybe, Pagination} from '@core/common';
import {
  OrganizationCreateUpdateReq,
  OrganizationListItem,
  OrganizationListPreferences,
  OrganizationResponse, OrganizationStatus
} from '@features/organizations/models';
import {AgGridSortState} from '@shared/models';

export const OrganizationsActions = createActionGroup({
  source: 'Organizations',
  events: {
    'Load organizations': emptyProps(),
    'Load organizations success': (organizations: ListResponse<OrganizationListItem>) => ({ organizations }),
    'Load organizations failure': emptyProps(),
    'Open Organization Preview Dialog': (id: number) => ({ id }),
    'Close Organization Preview Dialog': emptyProps(),
    'Load organization by id': (id: number) => ({ id }),
    'Load organization by id success': (organization: OrganizationResponse) => ({ organization }),
    'Load organization by id failure': emptyProps(),
    'Create organization': (req: OrganizationCreateUpdateReq) => ({ req }),
    'Create organization success': emptyProps(),
    'Create organization failure': emptyProps(),
    'Update organization': (req: OrganizationCreateUpdateReq) => ({ req }),
    'Update organization success': emptyProps(),
    'Update organization failure': emptyProps(),
    'Update organization status': (status: OrganizationStatus) => ({ status }),
    'Update organization status success': (status: OrganizationStatus) => ({ status }),
    'Update organization status failure': emptyProps(),
    'Update organization status cancel': emptyProps(),
    'Update organizations pagination': (pagination: Pagination) => ({ pagination }),
    'Update organizations sorting': (sorting: Maybe<AgGridSortState[]>) => ({ sorting }),
    'Set organizations list preferences': (organizationListPreferences: OrganizationListPreferences) => ({ organizationListPreferences }),
    'Clear Organization By Id': emptyProps(),
    'Reset': emptyProps()
  }
});
