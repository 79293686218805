<form [formGroup]="form" autocomplete="off">
  <div class="max-h-[250px] overflow-y-auto pr-0.5">
    <ng-container formArrayName="emails">
    @for (email of emails.controls; let i = $index; track email) {
      <div class="flex items-center">
        <div class="w-full">
          <label>{{ label }}</label>
          <mat-form-field class="w-full" appearance="outline" [matTooltip]="tooltipText">
            @if (email?.pending) {
              <mat-spinner matPrefix
                           class="mx-3"
                           mode="indeterminate"
                           [diameter]="16" />
            } @else {
              <mat-icon matPrefix
                        class="color-medium"
                        svgIcon="mail" />
            }
            <input matInput
                   type="text"
                   data-test="e2e-technician-email-input"
                   [formControl]="email"
                   [placeholder]="placeholder">
            @if (email?.value && email?.valid) {
              <mat-icon matSuffix
                        class="color-success"
                        svgIcon="check-circle" />
            }
            @if (email.errors?.['doesNotExist'] || email?.errors?.userInactive) {
              <mat-icon matSuffix
                        class="color-danger"
                        svgIcon="close-circle" />
            }
            <mat-error>
              @if (email?.errors?.email) {
                <span data-test="e2e-technician-email-invalid-msg">Please provide a valid email address</span>
              }
              @if (email?.errors?.doesNotExist) {
                <span data-test="e2e-technician-email-not-found-msg">Technician not found. Please create an account first.</span>
              }
              @if (email?.errors?.userInactive) {
                <span data-test="e2e-technician-email-deactivated-tech-msg">Technician deactivated. Should be deleted from the Assigned list.</span>
              }
              @if (email?.errors?.assetMappingNotAllowed) {
                <span data-test="e2e-technician-email-disabled-asset-mapping-msg">Asset mapping mode is not enabled for the specified account.</span>
              }
              @if (email?.errors?.eventReportingNotAllowed) {
                <span data-test="e2e-technician-email-disabled-event-report-msg">Event reporting mode is not enabled for the specified account.</span>
              }
            </mat-error>
          </mat-form-field>
        </div>
        @if (emails.controls.length > 1) {
          <button type="button" mat-icon-button color="warn" data-test="e2e-remove-technician-btn" [disabled]="disabled" (click)="removeEmail(i)">
            <mat-icon svgIcon="delete" />
          </button>
        }
      </div>
    }
    </ng-container>
  </div>
  <div class="inline-block" [matTooltip]="tooltipText">
    <button mat-button type="button" data-test="e2e-add-technician-btn" color="primary" [disabled]="disabled" (click)="addEmail()">
      <mat-icon slot="start" svgIcon="plus" />
      Add Technician
    </button>
    @if (emails.controls.length >= maxTechniciansCount) {
      <mat-error class="text-xs">Technician limit reached</mat-error>
    }
  </div>
</form>
