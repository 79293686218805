import {createActionGroup, emptyProps} from '@ngrx/store';

import {ListResponse, Maybe, Pagination} from '@core/common';
import {UserProfile, UsersListPreferences, UserStatus} from '@features/users/models';
import {AgGridSortState} from '@shared/models';

export const UsersActions = createActionGroup({
  source: 'Users',
  events: {
    'Load users': emptyProps(),
    'Load users success': (users: ListResponse<UserProfile>) => ({ users }),
    'Load users failure': emptyProps(),
    'Open User Preview Dialog': (id: string) => ({ id }),
    'Close User Preview Dialog': emptyProps(),
    'Load user by id': (id: string) => ({ id }),
    'Load user by id success': (user: UserProfile) => ({ user }),
    'Load user by id failure': emptyProps(),
    'Create user': (req: UserProfile) => ({ req }),
    'Create user success': emptyProps(),
    'Create user failure': emptyProps(),
    'Update user': (req: UserProfile) => ({ req }),
    'Update user success': (id: string) => ({ id }),
    'Update user failure': emptyProps(),
    'Update user status': (status: UserStatus) => ({ status }),
    'Update user status success': (id: string, status: UserStatus) => ({ id, status }),
    'Update user status failure': emptyProps(),
    'Update user status cancel': emptyProps(),
    'Update users pagination': (pagination: Pagination) => ({ pagination }),
    'Update users sorting': (sorting: Maybe<AgGridSortState[]>) => ({ sorting }),
    'Set users list preferences': (usersListPreferences: UsersListPreferences) => ({ usersListPreferences }),
    'Clear User By Id': emptyProps(),
    'Reset': emptyProps()
  }
});
