import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileUtilsService {

  getFileExtensionFromName(fileName: string): string | undefined {
    return (fileName || '').split('.').pop()?.toLowerCase();
  }
}
