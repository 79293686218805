<ng-container *apsNgLet="(pending$ | async) as pending">
  @if (isActiveUser()) {
    <button mat-stroked-button
            data-test="e2e-deactivate-btn"
            color="warn"
            class="w-full"
            [apsBtnPending]="pending"
            (click)="deactivate()">
      Deactivate User
    </button>
  } @else {
    <button mat-stroked-button
            data-test="e2e-activate-btn"
            color="accent"
            class="w-full"
            [apsBtnPending]="pending"
            (click)="activate()">
      Activate User
    </button>
  }
</ng-container>
