import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  forwardRef, inject,
  Input, OnChanges,
  OnInit,
  Output, SimpleChanges
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule} from '@angular/forms';
import {MatFormField} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {MatOption, MatSelect} from '@angular/material/select';
import {BehaviorSubject, catchError, finalize, Observable, of, Subject, switchMap, tap} from 'rxjs';

import {BaseEntity, DataFetcherFn, Pagination} from '@core/common';
import {MatSelectInfiniteScrollDirective} from '@shared/directives';
import {ValueOrDefaultPipe} from '@shared/pipes';

@Component({
  selector: 'aps-infinite-scroll-selector',
  templateUrl: './infinite-scroll-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormField,
    MatProgressSpinner,
    MatIcon,
    MatSelect,
    MatOption,
    MatSelectInfiniteScrollDirective,
    ValueOrDefaultPipe
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InfiniteScrollSelectorComponent),
      multi: true
    }
  ]
})
export class InfiniteScrollSelectorComponent<T = unknown, ID = unknown> implements OnInit, OnChanges, ControlValueAccessor {
  private pagination$ = new Subject<Pagination>();
  private optionsSubject = new BehaviorSubject<BaseEntity<ID>[]>([]);
  private destroyRef = inject(DestroyRef);
  private currentPage = 0;
  private _value!: T;
  private alreadyFetchedOptionsSet = new Set<ID>();

  @Input() label!: string;
  @Input() icon!: string;
  @Input() selectedOptions: BaseEntity<ID>[] = [];
  @Input() formControl!: FormControl;
  @Input() placeholder!: string;
  @Input() dataFetcherFn!: DataFetcherFn<ID>;
  @Input() pageSize = 10;
  @Input() multiple = false;
  @Input() required = false;
  @Output() selectionChange = new EventEmitter<void>();
  fetchedCount = 0;
  total = 0;
  options$: Observable<BaseEntity<ID>[]> = this.optionsSubject.asObservable();
  isRequestPending = false;

  get value(): T {
    return this._value;
  }

  set value(val: T) {
    this._value = val;
    if (this.onChange) {
      this.onChange(val);
    }
    if (this.onTouch) {
      this.onTouch(val);
    }
  }

  onChange!: (_: any) => void;
  onTouch!: (_: any) => void;

  ngOnInit(): void {
    this.checkSelectedOptions();
    this.subscribeToPaginationChanges();
    this.updatePagination();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['dataFetcherFn'].firstChange) {
      this.resetFetchedData();
    }
  }

  getNextBatch(): void {
    this.currentPage += 1;
    this.updatePagination();
  }

  writeValue(obj: T): void {
    this.value = obj;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  private updatePagination(): void {
    if (this.total >= this.fetchedCount) {
      this.pagination$.next({
        pageIndex: this.currentPage,
        pageSize: this.pageSize
      });
    }
  }

  private subscribeToPaginationChanges(): void {
    this.pagination$.asObservable().pipe(
      tap(() => this.toggleIsRequestPendingState(true)),
      switchMap((pagination) => this.dataFetcherFn(pagination).pipe(
        catchError(() => of(null)),
      )),
      tap((response) => {
        this.fetchedCount += response?.data?.length ?? 0;
        this.total = response?.pagination?.totalCount ?? 0;
        if (response?.data?.length) {
          this.optionsSubject.next([
            ...(this.optionsSubject?.value || null),
            ...response.data.filter(val => !this.alreadyFetchedOptionsSet.has(val.id))
          ] as BaseEntity<ID>[]);
        }
      }),
      tap(() => this.toggleIsRequestPendingState()),
      finalize(() => this.toggleIsRequestPendingState()),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  private toggleIsRequestPendingState(isPending = false): void {
    this.isRequestPending = isPending;
  }

  private checkSelectedOptions(): void {
    if (this.selectedOptions?.length) {
      this.optionsSubject.next(this.selectedOptions);
      this.selectedOptions.forEach((value) => this.alreadyFetchedOptionsSet.add(value.id));
    }
  }

  private resetFetchedData(): void {
    this.fetchedCount = 0;
    this.total = 0;
    this.optionsSubject.next([]);
    this.updatePagination();
  }
}
