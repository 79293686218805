<aps-entity-create-dialog
  data-test="e2e-user-create"
  [title]="isUpdateMode ? 'User Overview' : 'Create a New User'"
  [icon]="isUpdateMode ? 'user-edit' : 'user-add'"
  [submitBtnText]="isUpdateMode ? 'Save' : 'Create'"
  [submitBtnDisabled]="submitBtnDisabled"
  [apsBtnPending]="createUpdatePending$ | async"
  (submitted)="submit()">
  <form [formGroup]="form" autocomplete="off">
    <div class="grid grid-cols-12">
      <div class="col-span-8 pt-1 pr-6 section-divider-shadow">

        <h6 class="mb-3 dialog-section-title">General Information</h6>

        <div class="pt-4 mb-6">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-6">

              <label class="required">First Name</label>
              <mat-form-field class="w-full" appearance="outline">
                <mat-icon matPrefix class="color-medium" svgIcon="user" />
                <input data-test="e2e-first-name-input" formControlName="firstName" matInput placeholder="Add Name">
                @if (form.controls.firstName.invalid) {
                  <mat-error>
                    @if (form.controls.firstName.errors?.['required']) {
                      <span>This field is required</span>
                    } @else if (form.controls.firstName.errors?.['minlength']) {
                      <span>Min length is {{ firstNameMinLength }}</span>
                    } @else if (form.controls.firstName.errors?.['maxlength']) {
                      <span>Max length is {{ firstNameMaxLength }}</span>
                    }
                  </mat-error>
                }
              </mat-form-field>
            </div>
            <div class="col-span-6">

              <label class="required">Last Name</label>
              <mat-form-field class="w-full" appearance="outline">
                <mat-icon matPrefix class="color-medium" svgIcon="user" />
                <input data-test="e2e-last-name-input" formControlName="lastName" matInput placeholder="Add Last Name">
                @if (form.controls.lastName.invalid) {
                  <mat-error>
                    @if (form.controls.lastName.errors?.['required']) {
                      <span>This field is required</span>
                    } @else if (form.controls.lastName.errors?.['minlength']) {
                      <span>Min length is {{ lastNameMinLength }}</span>
                    } @else if (form.controls.lastName.errors?.['maxlength']) {
                      <span>Max length is {{ lastNameMaxLength }}</span>
                    }
                  </mat-error>
                }
              </mat-form-field>

            </div>
          </div>

          <label [ngClass]="{
          'opacity-50': form.controls?.email?.disabled,
          'required': form.controls?.email?.enabled }">E-Mail</label>
          <mat-form-field class="w-full" appearance="outline">
            <mat-icon matPrefix class="color-medium" svgIcon="mail" />
            <input type="email" data-test="e2e-email-input" formControlName="email" matInput placeholder="Add User Email">
            @if (form.controls.email.invalid) {
              <mat-error>
                @if (form.controls.email.errors?.['required']) {
                  <span>This field is required</span>
                } @else if (form.controls.email.errors?.['alreadyExists']) {
                  <span>A user with specified email already exists</span>
                } @else if (form.controls.email.errors?.['email']) {
                  <span>Please provide a valid email address</span>
                }
              </mat-error>
            }
          </mat-form-field>

          <label>Job Title</label>
          <mat-form-field class="w-full" appearance="outline">
            <mat-icon matPrefix class="color-medium" svgIcon="user" />
            <input data-test="e2e-job-title-input" formControlName="jobTitle" type="text" matInput placeholder="Add Job Title">
            @if (form.controls.jobTitle.invalid) {
              <mat-error>
                @if (form.controls.jobTitle.errors?.['maxlength']) {
                  <span>Max length is {{ jobTitleMaxLength }}<</span>
                }
              </mat-error>
            }
          </mat-form-field>

          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-6">

              <label>Phone</label>
              <mat-form-field class="w-full" appearance="outline">
                <mat-icon matPrefix class="color-medium" svgIcon="phone" />
                <input formControlName="phone"
                       data-test="e2e-phone-input"
                       matInput
                       mask="+0*"
                       [specialCharacters]="['+']"
                       [validation]="false"
                       type="tel"
                       placeholder="+">
                @if (form.controls.phone.invalid) {
                  <mat-error>
                    @if (form.controls.phone.errors?.['invalidPhone']) {
                      <span>Invalid format</span>
                    }
                  </mat-error>
                }
              </mat-form-field>

            </div>
          </div>
          <div class="bg-super-light-gray rounded-lg grid-cols-12 gap-4 px-4 pt-4 mb-6 mt-2">

            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-6">

                <label class="required">Role</label>
                <mat-form-field class="w-full" appearance="outline" floatLabel="always">
                  <mat-icon matPrefix class="color-medium" svgIcon="badge" />
                  <mat-select formControlName="role"
                              data-test="e2e-role-selector"
                              placeholder="Select Role"
                              (selectionChange)="resetOrganizationValue()">
                    @for (role of userRoleOptions; track role.id) {
                      <mat-option [value]="role?.id" data-test="e2e-role-selector-option">{{ role.name }}</mat-option>
                    }
                  </mat-select>
                  @if (form.controls.role.invalid) {
                    <mat-error>
                      @if (form.controls.role.errors?.['required']) {
                        <span>This field is required</span>
                      }
                    </mat-error>
                  }
                </mat-form-field>

              </div>
            </div>

            <ng-container *ngxPermissionsOnly="[userRole.APS_PE, userRole.APS_ADMIN]">
              <aps-input-organization formControlName="organizations"
                                      [user]="user()"
                                      [isUpdateMode]="isUpdateMode"
                                      [userRole]="form.controls.role.value" />

              <div class="grid grid-cols-12 gap-4">
                <div class="col-span-6 mt-3 mb-6">
                  <label class="text-gray-500 text-sm">Set as a Primary Contact</label>
                </div>
                <div class="col-span-6 mt-3 mb-6">
                  <mat-slide-toggle class="col-span-6"
                                    data-test="e2e-is-primary-contact"
                                    color="primary"
                                    formControlName="isPrimaryContact" />
                </div>
              </div>
              <label>APS Comment</label>
              <mat-form-field class="w-full" appearance="outline" floatLabel="always">
                <mat-icon matPrefix class="color-medium" svgIcon="comment" />
                <textarea formControlName="comment"  data-test="e2e-aps-comment-input" matInput placeholder="Add Your Comment"></textarea>
                @if (form.controls.comment.invalid) {
                  <mat-error>
                    @if (form.controls.comment.errors?.['maxlength']) {
                      <span>Max length is {{ commentMaxLength }}</span>
                    }
                  </mat-error>
                }
              </mat-form-field>
            </ng-container>
          </div>
          @if (isAuthUserIsApsPe() || isAuthUserIsAdmin()) {
            <mat-checkbox color="primary"
                          data-test="e2e-send-email-invitation"
                          class="mt-2 relative right-2.5"
                          formControlName="sendEmailInvitation">
              <span class="text-sm color-primary">Send E-Mail Invitation</span>
            </mat-checkbox>
          }
        </div>
      </div>
      <div class="col-span-4 pt-1 pl-6">
        <h6 class="dialog-section-title">User Mode</h6>
        <div class="grid grid-cols-12 mt-4">
          <div class="col-span-10">
            <label class="text-gray-500 text-sm" [matTooltip]="assetMappingTooltipText">Asset Mapping</label>
          </div>
          <div class="col-span-2 grid justify-end">
            <mat-slide-toggle class="col-span-6"
                              color="primary"
                              data-test="e2e-asset-mapping-mode"
                              formControlName="assetMapping" />
          </div>
        </div>
        <div class="grid grid-cols-12 mt-3">
          <div class="col-span-10">
            <label class="text-gray-500 text-sm" [matTooltip]="eventReportingTooltipText">Event Reporting</label>
          </div>
          <div class="col-span-2 grid justify-end">
            <mat-slide-toggle class="col-span-6"
                              data-test="e2e-event-reporting-mode"
                              color="primary"
                              formControlName="eventReporting" />
          </div>
        </div>
        @if (form?.hasError('invalidUserMode')) {
          <div class="flex mt-2 items-center">
            <p class="font-normal text-xs color-danger">Enable at least one mode for the user</p>
          </div>
        }
        <h6 class="dialog-section-title mt-6">Password Settings</h6>
        <div class="grid grid-cols-12 mt-4">
          <div class="col-span-10">
            <label class="text-gray-500 text-sm">Must change password on next login</label>
          </div>
          <div class="col-span-2 grid justify-end">
            <mat-slide-toggle class="col-span-6"
                              color="primary"
                              data-test="e2e-change-password-on-next-login"
                              formControlName="isMustResetPassword" />
          </div>
        </div>
        <!-- Keep the functionality, but do not show it, since 2FA is always enforced by the login system -->
        <div class="grid grid-cols-12 mt-4" style="display: none;">
          <div class="col-span-10">
            <label class="text-gray-500 text-sm">Enforce 2FA</label>
          </div>
          <div class="col-span-2 grid justify-end">
            <mat-slide-toggle class="col-span-6" color="primary" formControlName="enforce2FA" data-test="e2e-enforce-2fa"/>
          </div>
        </div>
      </div>
    </div>
  </form>
  <ng-container extraControls>
    @if (isAuthUserIsApsPe() || isAuthUserIsAdmin()) {
      <div class="grid justify-end">
        @if (isUpdateMode) {
          <aps-user-deactivate data-test="e2e-user-deactivate"
                               [userStatus]="user()?.status" />
        }
      </div>
    }
  </ng-container>
</aps-entity-create-dialog>
