import {inject, Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatLatestFrom} from '@ngrx/operators';
import {Store} from '@ngrx/store';
import {catchError, filter, iif, map, of, switchMap, tap} from 'rxjs';
import {take} from 'rxjs/operators';

import {DEFAULT_MODAL_WIDTH} from '@core/common';
import {UserCreateComponent} from '@features/users/components/user-create';
import {UserStatus} from '@features/users/models';
import {UserApiService} from '@features/users/services';
import {UsersActions} from '@features/users/store/users.actions';
import {usersFeature} from '@features/users/store/users.feature';
import {ConfirmActionDialogComponent} from '@shared/components';
import {ConfirmActionDialogData} from '@shared/models';
import {NotificationService} from '@shared/services';

@Injectable()
export class UsersEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly userApiService = inject(UserApiService);
  private readonly notification = inject(NotificationService);
  private readonly dialog = inject(MatDialog);

  loadUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        UsersActions.loadUsers,
        UsersActions.createUserSuccess,
        UsersActions.updateUserSuccess,
        UsersActions.updateUserStatusSuccess
      ),
      concatLatestFrom(() => [
        this.store.select(usersFeature.selectUsersPagination),
        this.store.select(usersFeature.selectSortingState),
        this.store.select(usersFeature.selectUsersListPreferences),
      ]),
      switchMap(([_, pagination, sortingState, prefs]) => {
        const paginationToUse = {...pagination};
        if (prefs?.pageSize) {
          paginationToUse.pageSize = prefs.pageSize;
        }
        return this.userApiService.getList(paginationToUse, prefs?.status!, sortingState).pipe(
          map((response) => UsersActions.loadUsersSuccess(response)),
          catchError((err) => {
            this.notification.error('Failed to fetch users.');
            console.error('Failed to fetch users!', err);
            return of(UsersActions.loadUsersFailure());
          })
        );
      })
    );
  });

  createUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.createUser),
      switchMap(({ req }) => this.userApiService.create(req).pipe(
        map(() => {
          this.notification.success(`Created successfully`);
          return UsersActions.createUserSuccess();
        }),
        catchError((err) => {
          this.notification.error('Failed to create user.');
          console.error('Failed to create user!', err);
          return of(UsersActions.createUserFailure());
        })
      ))
    );
  });

  updateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.updateUser),
      concatLatestFrom(() => this.store.select(usersFeature.selectUserById)),
      switchMap(([{ req }, user]) => this.userApiService.update(user?.id!, req).pipe(
        map(() => {
          this.notification.success(`Updated successfully`);
          return UsersActions.updateUserSuccess(user?.id!);
        }),
        catchError((err) => {
          this.notification.error('Failed to update user.');
          console.error('Failed to update user!', err);
          return of(UsersActions.updateUserFailure());
        })
      ))
    );
  });

  changeUserStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.updateUserStatus),
      concatLatestFrom(() => this.store.select(usersFeature.selectUserById)),
      switchMap(([{ status }, user]) => {
        const isActiveStatus = status === UserStatus.ACTIVE;
        const confirmActionDialogData: ConfirmActionDialogData = {
          title: `User will be ${isActiveStatus ? 'activated' : 'deactivated'}`,
          description: `Do you want to ${isActiveStatus ? 'activate' : 'deactivate'} this user?`
        };

        return this.dialog.open(ConfirmActionDialogComponent, {
          width: '504px',
          data: confirmActionDialogData
        }).afterClosed()
          .pipe(
            take(1),
            switchMap((value) => iif(
              () => Boolean(value),
              this.userApiService.changeStatus(user?.id!, status).pipe(
                map(() => {
                  this.notification.success(`User successfully ${isActiveStatus ? 'activated' : 'deactivated'}`);
                  return UsersActions.updateUserStatusSuccess(user?.id!, status);
                }),
                catchError((err) => {
                  this.notification.error('Failed to update user status.');
                  console.error('Failed to update user status!', err);
                  return of(UsersActions.updateUserStatusFailure());
                })
              ),
              of(UsersActions.updateUserStatusCancel())
            ))
          );
      })
    );
  });

  checkIfUserPendingBeforePreviewDialogOpen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.openUserPreviewDialog),
      concatLatestFrom(() => this.store.select(usersFeature.selectIsUserByIdPending).pipe(
        filter(pending => !pending)
      )),
      map(([{ id }]) => UsersActions.loadUserById(id))
    );
  });

  loadUserById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.loadUserById),
      switchMap(({ id }) => this.userApiService.getById(id).pipe(
        map((response) => UsersActions.loadUserByIdSuccess(response)),
        catchError((err) => {
          this.notification.error(`Failed to fetch user data.`);
          console.error(`Failed to fetch user. ID ${id}`, err);
          return of(UsersActions.loadUserByIdFailure());
        })
      ))
    );
  });

  closeUserDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        UsersActions.closeUserPreviewDialog,
        UsersActions.createUserSuccess,
        UsersActions.createUserFailure,
        UsersActions.updateUserSuccess,
        UsersActions.updateUserFailure
      ),
      tap(() => this.dialog.closeAll())
    );
  }, { dispatch: false });

  openUserUpdateDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.loadUserByIdSuccess),
      switchMap(() => this.dialog.open(UserCreateComponent, {
        width: DEFAULT_MODAL_WIDTH,
        closeOnNavigation: true
      }).afterClosed()),
      map(() => UsersActions.clearUserById())
    );
  });

  updateUsersList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.updateUsersSorting, UsersActions.updateUsersPagination, UsersActions.setUsersListPreferences),
      map(() => UsersActions.loadUsers())
    );
  });
}
