import {LatLngExpression, LatLngLiteral, LatLngTuple} from 'leaflet';

import {LocationFeature} from '@shared/models';


export const isLocationFeature = (location: string | LocationFeature): location is LocationFeature => {
  return (location as LocationFeature)?.geometry !== undefined;
};

export const isLatLngLiteral = (latLng: LatLngExpression): latLng is LatLngLiteral => {
  return (latLng as LatLngLiteral)?.lat !== undefined;
};

export const coordinatesToLatLngTuple = (coordinates: number[]): LatLngTuple => {
  return [coordinates[1], coordinates[0]];
};
