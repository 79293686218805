import {Injectable} from '@angular/core';
import {ColDef, ValueFormatterParams} from 'ag-grid-community';

import {UserOrganization,UserProfile} from '@features/users/models';
import {AgCellStatusComponent, AgCellStringComponent, AgCellUsernameComponent} from '@shared/components/ag-cells';
import {UserRolePipe} from '@shared/pipes';

@Injectable({
  providedIn: 'root'
})
export class UsersTableHeadersUtils {

  getColumnDefs(): ColDef[] {
    return [
      {
        field: 'firstName',
        headerName: 'Name',
        cellRenderer: AgCellUsernameComponent,
        sortable: true,
        resizable: true,
        comparator: () => 0,
        cellClass: 'cell-overflow-ellipsis',
        tooltipField: 'firstName'
      },
      {
        field: 'role',
        headerName: 'Role',
        cellRenderer: AgCellStringComponent,
        sortable: true,
        comparator: () => 0,
        valueFormatter: (params: ValueFormatterParams<UserProfile, string>) => {
          return UserRolePipe.transform(params.data!)!;
        },
        tooltipField: 'role'
      },
      {
        field: 'assetMapping',
        headerName: 'Asset Mapping',
        cellRenderer: AgCellStatusComponent,
        sortable: true,
        comparator: () => 0,
        cellClass: 'cell-overflow-ellipsis',
        valueFormatter: (params: ValueFormatterParams<UserProfile, string>) => {
          return params.value ? 'ACTIVE' : 'INACTIVE';
        },
      },
      {
        field: 'eventReporting',
        headerName: 'Event Reporting',
        cellRenderer: AgCellStatusComponent,
        sortable: true,
        comparator: () => 0,
        valueFormatter: (params: ValueFormatterParams<UserProfile, string>) => {
          return params.value ? 'ACTIVE' : 'INACTIVE';
        },
      },
      {
        field: 'organizations',
        valueFormatter: (params: ValueFormatterParams<UserProfile, UserOrganization[]>) => {
          return params?.value?.length ? params?.value?.map(a => a.name).join(', ') : 'N/A';
        },
        resizable: true,
        sortable: true,
        comparator: () => 0,
        cellClass: 'cell-overflow-ellipsis'
      },
      {
        field: 'email',
        sortable: true,
        comparator: () => 0,
        headerName: 'Email',
        cellRenderer: AgCellStringComponent,
        cellClass: 'cell-overflow-ellipsis',
        tooltipField: 'email'
      },
      {
        field: 'status',
        sortable: true,
        comparator: () => 0,
        headerName: 'Status',
        cellRenderer: AgCellStatusComponent,
        maxWidth: 100
      }
    ];
  }
}
