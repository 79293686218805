import {createActionGroup, emptyProps} from '@ngrx/store';

import {ListResponse, Maybe, Pagination} from '@core/common';
import {Project, ProjectCreateReq, ProjectUpdateReq} from '@features/projects/models';
import {AgGridSortState, ProjectFilter} from '@shared/models';

export const ProjectsActions = createActionGroup({
  source: 'Asset Projects',
  events: {
    'Load asset projects': emptyProps(),
    'Load asset projects success': (projects: ListResponse<Project>) => ({ projects }),
    'Load asset projects failure': emptyProps(),
    'Open asset project preview dialog': (id: number, errorMsg?: string) => ({ id, errorMsg }),
    'Close asset project preview dialog': emptyProps(),
    'Load asset project by id': (id: number) => ({ id }),
    'Load asset project by id success': (project: Project) => ({ project }),
    'Load asset project by id failure': emptyProps(),
    'Create asset project': (req: ProjectCreateReq, blueprintFile: File, fiberRouteFile: File) => ({
      req,
      blueprintFile,
      fiberRouteFile
    }),
    'Create asset project success': emptyProps(),
    'Create asset project failure': emptyProps(),
    'Update asset project': (req: ProjectUpdateReq, blueprintFile: File, fiberRouteFile: File) => ({
      req,
      blueprintFile,
      fiberRouteFile
    }),
    'Update asset project success': emptyProps(),
    'Update asset project failure': (errorMsg?: string) => ({ errorMsg }),
    'Update asset project status': (isActive: boolean) => ({ isActive }),
    'Update asset project status success': (isActive: boolean) => ({ isActive }),
    'Update asset project status failure': emptyProps(),
    'Update asset project status cancel': emptyProps(),
    'Update asset projects pagination': (pagination: Pagination) => ({ pagination }),
    'Update asset projects sorting': (sorting: Maybe<AgGridSortState[]>) => ({ sorting }),
    'Set asset project filter': (projectFilter: Maybe<ProjectFilter>) => ({ projectFilter }),
    'Clear asset project by id': emptyProps(),
    'Clear project create update error msg': emptyProps(),
    'Reset': emptyProps()
  }
});
