import {
  ChangeDetectionStrategy,
  Component, DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output, SimpleChanges
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormControlStatus,
  FormGroup,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {MatRadioButton, MatRadioGroup} from '@angular/material/radio';
import {LatLngTuple} from 'leaflet';
import {tap} from 'rxjs';

import {DialogMode, FileChangeEvent, Maybe} from '@core/common';
import {projectNumericMappingTypeToString} from '@features/projects/const';
import {
  EventReportProject,
  MappingType, MappingTypeNumeric,
  OfflineMapArea,
  Project,
  ProjectMapFieldsData,
  ProjectProgress
} from '@features/projects/models';
import {mappingTypeValidator} from '@features/projects/utils';
import {BlueprintComponent} from '@shared/components/location/components/blueprint';
import {InputLocationComponent} from '@shared/components/location/components/input-location';
import {MapComponent} from '@shared/components/location/components/map';
import {LocationFeature} from '@shared/models';

import {coordinatesToLatLngTuple, isLocationFeature} from './location.helpers';

interface FormType {
  location: FormControl<string | LocationFeature>;
  address: FormControl<string>;
  latitude: FormControl<Maybe<number>>;
  longitude: FormControl<Maybe<number>>;
  mappingType: FormControl<MappingType>;
  leftTopLatitude: FormControl<Maybe<number>>;
  leftTopLongitude: FormControl<Maybe<number>>;
  rightBottomLatitude: FormControl<Maybe<number>>;
  rightBottomLongitude: FormControl<Maybe<number>>;
  zoom: FormControl<Maybe<number>>;
  mapType: FormControl<Maybe<string>>;
}

@Component({
  selector: 'aps-location',
  templateUrl: './location.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MapComponent,
    BlueprintComponent,
    InputLocationComponent,
    MatRadioGroup,
    MatRadioButton
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationComponent implements OnInit, OnChanges {
  private readonly fb = inject(NonNullableFormBuilder);
  private readonly destroyRef = inject(DestroyRef);
  private _parentFormDisabled = false;
  @Input() project!: Project | EventReportProject;

  @Input() mode!: DialogMode;
  @Input() mapControlsDisabled = false;
  @Input() isEventReportProject = false;
  @Input() locationMaxLength = 256;

  @Input() set parentFormDisabled(disabled: boolean) {
    this._parentFormDisabled = disabled;
    if (disabled) {
      this.form?.disable();
    } else {
      this.form?.enable();
    }
  }

  get parentFormDisabled(): boolean {
    return this._parentFormDisabled;
  }

  @Output() mapFieldsDataChanged = new EventEmitter<ProjectMapFieldsData>();
  @Output() blueprintChanged = new EventEmitter<File | null>();
  @Output() offlineAreaChanged = new EventEmitter<OfflineMapArea>();
  @Output() locationStatusChanged = new EventEmitter<FormControlStatus>();
  form!: FormGroup<FormType>;
  mapType = 'map';
  mappingType = MappingType;
  coordinates!: LatLngTuple;
  bpFileName = '';

  get canReplaceBlueprint(): boolean {
    if (this.isEventReportProject) {
      return Boolean((this.project as EventReportProject)?.isActive && this.project?.organization?.isActive);
    }
    return (this.project as Project)?.progress !== ProjectProgress.completed && this.project?.organization?.status === true;
  }

  ngOnInit() {
    this.form = this.getForm();
    this.bpFileName = this.isEventReportProject ? (this.project as EventReportProject)?.blueprintFileName : (this.project as Project)?.photo;
    this.patchFormValue(this.project);
    this.listenFormChanges();
    this.listenLocationCtrl();
    this.listenMapTypeCtrl();
    if (this.mapControlsDisabled && this.isUpdateMode) {
      this.form.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['mapControlsDisabled'] && !changes['mapControlsDisabled'].firstChange) {
      if (changes['mapControlsDisabled'].currentValue) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    }
  }

  onMarkerSelected(latLng: LatLngTuple): void {
    if (latLng.length === 2) {
      this.form.controls.latitude.setValue(latLng[0], { emitEvent: false });
      this.form.controls.longitude.setValue(latLng[1], { emitEvent: false });
      if (this.form.controls.mappingType.value === MappingType.map) {
        this.coordinates = latLng;
      }
    } else {
      this.form.controls.latitude.setValue(null, { emitEvent: false });
      this.form.controls.longitude.setValue(null, { emitEvent: false });
    }
    this.mapFieldsDataChanged.emit(this.form.value as ProjectMapFieldsData);
  }

  onOfflineAreaChanged(area: OfflineMapArea): void {
    this.form.controls.leftTopLatitude.setValue(area.leftTopLatitude);
    this.form.controls.leftTopLongitude.setValue(area.leftTopLongitude);
    this.form.controls.rightBottomLatitude.setValue(area.rightBottomLatitude);
    this.form.controls.rightBottomLongitude.setValue(area.rightBottomLongitude);
    this.form.controls.zoom.setValue(area?.zoom!);
    this.form.controls.mapType.setValue(area?.mapType!);
  }

  onFileChanged(ev: FileChangeEvent): void {
    this.form.controls.latitude.setValue(null);
    this.form.controls.longitude.setValue(null);
    this.blueprintChanged.emit(ev.file);
  }

  get isUpdateMode(): boolean {
    return this.mode === 'update';
  }

  private getForm(): FormGroup<FormType> {
    const mappingTypeValidatorToUse = !this.isUpdateMode ?
      [mappingTypeValidator] : null;
    return this.fb.group<FormType>({
      location: this.fb.control('', [Validators.maxLength(this.locationMaxLength)]),
      address: this.fb.control(''),
      latitude: this.fb.control(null),
      longitude: this.fb.control(null),
      mappingType: this.fb.control(MappingType.notSelected, mappingTypeValidatorToUse),
      leftTopLatitude: this.fb.control(null),
      leftTopLongitude: this.fb.control(null),
      rightBottomLatitude: this.fb.control(null),
      rightBottomLongitude: this.fb.control(null),
      zoom: this.fb.control(0),
      mapType: this.fb.control(null)
    });
  }

  private listenFormChanges(): void {
    this.form.valueChanges
      .pipe(
        tap((v) => this.mapFieldsDataChanged.emit(v as ProjectMapFieldsData)),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe();

    this.form.statusChanges
      .pipe(
        tap((status) => this.locationStatusChanged.emit(status)),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe();
  }

  private listenLocationCtrl(): void {
    this.form.controls.location.valueChanges
      .pipe(
        tap((a) => {
          if (isLocationFeature(a)) {
            this.form.controls.address.setValue(a.properties?.display_name);
            this.coordinates = coordinatesToLatLngTuple(a.geometry?.coordinates);
          } else {
            this.form.controls.address.setValue(a);
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe();
  }

  private listenMapTypeCtrl(): void {
    this.form.controls.mappingType.valueChanges
      .pipe(
        tap(() => {
          this.form.controls.latitude.setValue(null);
          this.form.controls.longitude.setValue(null);
          this.blueprintChanged.emit(null);
        }),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe();
  }

  private patchFormValue(project: Project | EventReportProject): void {
    if (!project) {
      return;
    }
    if (this.isEventReportProject) {
      this.patchFormByEventReportProjectValue(project as EventReportProject);
    } else {
      this.patchFormByRegularProjectValue(project as Project);
    }
  }

  private patchFormByEventReportProjectValue(project: EventReportProject): void {
    this.form.controls.address.setValue(project.locationAddress);
    this.form.controls.location.setValue(project.locationAddress);
    this.form.controls.latitude.setValue(project.gpsLocation?.latitude!);
    this.form.controls.longitude.setValue(project.gpsLocation?.longitude!);
    this.form.controls.mappingType.setValue(projectNumericMappingTypeToString.get(project.mapType)!);
    if (project.gpsLocation && project.mapType === MappingTypeNumeric.geographicalReference) {
      this.coordinates = [project.gpsLocation.latitude, project.gpsLocation.longitude];
    }
    if (!project.isActive) {
      this.form.disable();
    }
  }

  private patchFormByRegularProjectValue(project: Project): void {
    this.form.patchValue(project);
    this.form.controls.location.patchValue(project?.address);
    if ((project?.latitude || project?.longitude === 0) && (project?.longitude || project?.longitude === 0)) {
      if (project?.mappingType === MappingType.map) {
        this.coordinates = [project?.latitude, project?.longitude];
      }
    }

    if (project.progress === ProjectProgress.completed) {
      this.form.disable();
    }
  }
}
