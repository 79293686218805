import {createFeature, createReducer, createSelector, on} from '@ngrx/store';
import {produce} from 'immer';

import {INITIAL_PAGINATION_CONFIG, ListResponse, Maybe, Pagination, RequestStatus} from '@core/common';
import {OrganizationListItem, OrganizationListPreferences, OrganizationResponse} from '@features/organizations/models';
import {AgGridSortState} from '@shared/models';

import {OrganizationsActions} from './organizations.actions';

export interface OrganizationsFeature {
  organizations: Maybe<ListResponse<OrganizationListItem>>;
  sortingState: Maybe<AgGridSortState[]>;
  organizationsPagination: Pagination;
  organizationsLoadStatus: RequestStatus,
  organizationByIdLoadStatus: RequestStatus,
  organizationCreateUpdateReqStatus: RequestStatus,
  organizationUpdateStatusReqStatus: RequestStatus,
  organizationsListPreferences: Maybe<OrganizationListPreferences>;
  organizationById: Maybe<OrganizationResponse>;
}

export const organizationsInitialState: OrganizationsFeature = {
  organizations: null,
  sortingState: null,
  organizationsPagination: { ...INITIAL_PAGINATION_CONFIG },
  organizationsLoadStatus: 'IDLE',
  organizationByIdLoadStatus: 'IDLE',
  organizationCreateUpdateReqStatus: 'IDLE',
  organizationUpdateStatusReqStatus: 'IDLE',
  organizationsListPreferences: null,
  organizationById: null
};

export const organizationsReducer = createReducer(
  organizationsInitialState,
  on(OrganizationsActions.loadOrganizations, (state) =>
    produce(state, (draft) => {
      draft.organizationsLoadStatus = 'LOADING';
    })
  ),
  on(OrganizationsActions.loadOrganizationsSuccess, (state, { organizations }) =>
    produce(state, (draft) => {
      draft.organizationsLoadStatus = 'SUCCESS';
      draft.organizations = organizations;
    })
  ),
  on(OrganizationsActions.loadOrganizationsFailure, (state) =>
    produce(state, (draft) => {
      draft.organizationsLoadStatus = 'ERROR';
    })
  ),
  on(OrganizationsActions.updateOrganizationsPagination, (state, { pagination }) =>
    produce(state, (draft) => {
      draft.organizationsPagination = pagination;
    })
  ),
  on(OrganizationsActions.updateOrganizationsSorting, (state, { sorting }) =>
    produce(state, (draft) => {
      draft.sortingState = sorting;
    })
  ),
  on(OrganizationsActions.loadOrganizationById, (state) =>
    produce(state, (draft) => {
      draft.organizationByIdLoadStatus = 'LOADING';
    })
  ),
  on(OrganizationsActions.loadOrganizationByIdSuccess, (state, { organization }) =>
    produce(state, (draft) => {
      draft.organizationByIdLoadStatus = 'SUCCESS';
      draft.organizationById = organization;
    })
  ),
  on(OrganizationsActions.loadOrganizationByIdFailure, (state) =>
    produce(state, (draft) => {
      draft.organizationByIdLoadStatus = 'ERROR';
    })
  ),
  on(OrganizationsActions.updateOrganizationStatus, (state) =>
    produce(state, (draft) => {
      draft.organizationUpdateStatusReqStatus = 'LOADING';
    })
  ),
  on(OrganizationsActions.updateOrganizationStatusSuccess, (state, { status }) =>
    produce(state, (draft) => {
      draft.organizationUpdateStatusReqStatus = 'SUCCESS';
      draft.organizationById = {
        ...draft.organizationById,
        status,
      } as OrganizationResponse;
    })
  ),
  on(OrganizationsActions.updateOrganizationStatusFailure, (state) =>
    produce(state, (draft) => {
      draft.organizationUpdateStatusReqStatus = 'ERROR';
    })
  ),
  on(OrganizationsActions.updateOrganizationStatusCancel, (state) =>
    produce(state, (draft) => {
      draft.organizationUpdateStatusReqStatus = 'IDLE';
    })
  ),
  on(OrganizationsActions.createOrganization,
    OrganizationsActions.updateOrganization, (state) =>
      produce(state, (draft) => {
        draft.organizationCreateUpdateReqStatus = 'LOADING';
      })
  ),
  on(OrganizationsActions.createOrganizationSuccess,
    OrganizationsActions.updateOrganizationSuccess, (state) =>
      produce(state, (draft) => {
        draft.organizationCreateUpdateReqStatus = 'SUCCESS';
      })
  ),
  on(OrganizationsActions.createOrganizationFailure,
    OrganizationsActions.updateOrganizationFailure, (state) =>
      produce(state, (draft) => {
        draft.organizationCreateUpdateReqStatus = 'ERROR';
      })
  ),
  on(OrganizationsActions.clearOrganizationById, (state) =>
    produce(state, (draft) => {
      draft.organizationById = organizationsInitialState.organizationById;
      draft.organizationByIdLoadStatus = organizationsInitialState.organizationByIdLoadStatus;
    })
  ),
  on(OrganizationsActions.setOrganizationsListPreferences, (state, { organizationListPreferences }) =>
    produce(state, (draft) => {
      draft.organizationsListPreferences = organizationListPreferences;
    })
  ),
  on(OrganizationsActions.reset, () => ({...organizationsInitialState})),
);

export const organizationsFeature = createFeature({
  name: 'organizations',
  reducer: organizationsReducer,
  extraSelectors: ({
                     selectOrganizationsLoadStatus,
                     selectOrganizationByIdLoadStatus,
                     selectOrganizationCreateUpdateReqStatus,
                     selectOrganizationUpdateStatusReqStatus
                   }) => {
    const selectIsOrganizationsPending = createSelector(
      selectOrganizationsLoadStatus,
      (loadStatus) => loadStatus === 'LOADING'
    );

    const selectIsOrganizationByIdPending = createSelector(
      selectOrganizationByIdLoadStatus,
      (loadStatus) => loadStatus === 'LOADING'
    );

    const selectIsOrganizationCreateUpdatePending = createSelector(
      selectOrganizationCreateUpdateReqStatus,
      (loadStatus) => loadStatus === 'LOADING'
    );

    const selectIsOrganizationUpdateStatusPending = createSelector(
      selectOrganizationUpdateStatusReqStatus,
      (loadStatus) => loadStatus === 'LOADING'
    );

    return {
      selectIsOrganizationsPending,
      selectIsOrganizationByIdPending,
      selectIsOrganizationCreateUpdatePending,
      selectIsOrganizationUpdateStatusPending
    };
  }
});
