import {FormControl} from '@angular/forms';

import {BaseEntity, Maybe} from '@core/common';

export interface UserProfile extends UserBase {
  phone: string;
  email: string;
  organizations: UserOrganization[];
  jobTitle: string;
  isMustResetPassword: boolean;
  comment: string;
  status?: UserStatus;
  enforce2FA: boolean;
  sendEmailInvitation: boolean;
  assetMapping: boolean;
  eventReporting: boolean;
}

export interface UserBase {
  id?: string;
  firstName?: string;
  lastName?: string;
  userName?: string;
  email?: string;
  role?: UserRole;
}

export interface UserOrganization extends BaseEntity<number> {
  isPrimaryContact?: boolean;
  status?: boolean;
}

export enum UserRole {
  APS_ADMIN = 'APS Admin',
  APS_PE = 'APS PE',
  PE = 'PE',
  TECHNICIAN = 'Technician',
}

export enum UserPermissions {
  EVENT_REPORTING = 'eventReporting',
  ASSET_MAPPING = 'assetMapping',
}

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface UserSearchParams {
  exact?: boolean;
  email?: string;
  role?: UserRole;
}

export interface UserForm {
  jobTitle: FormControl<string>;
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  email: FormControl<string>;
  phone: FormControl<string>;
  organizations: FormControl<UserOrganization[]>;
  role: FormControl<Maybe<UserRole>>;
  isPrimaryContact: FormControl<boolean>;
  comment: FormControl<string>;
  isMustResetPassword: FormControl<boolean>;
  sendEmailInvitation: FormControl<boolean>;
  enforce2FA: FormControl<boolean>;
  assetMapping: FormControl<boolean>;
  eventReporting: FormControl<boolean>;
}

export interface UserFormRawValue {
  jobTitle: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  organizations: Maybe<UserOrganization[]>;
  role: Maybe<UserRole>;
  isPrimaryContact: boolean;
  comment: string;
  isMustResetPassword: boolean;
  sendEmailInvitation: boolean;
  enforce2FA: boolean;
  assetMapping: boolean;
  eventReporting: boolean;
}

export interface User extends UserProfile {
  isPrimaryContact?: boolean;
}

export interface UserCheckResult {
  userExists: boolean;
  id: number;
  isUserActive: boolean;
  assetMapping: boolean;
  eventReporting: boolean;
}

export interface UsersListPreferences {
  status: Maybe<UserStatus>;
  pageSize: number;
}
