<div [class.fullscreen]="isFullscreen" class="width-full map-container">
  <div id='Map'
       leaflet
       data-test="e2e-lf-map"
       [leafletOptions]="options"
       [leafletLayers]="layers"
       [leafletLayersControl]="layersControl"
       (leafletMapZoomEnd)="onZoomChange()"
       (leafletMapReady)="onMapReady($event)"
       (leafletClick)="onClick($event)">
  </div>
  <aps-resize-control class="absolute top-16 right-2.5"
                      [isFullScreen]="isFullscreen"
                      (toggleFullscreen)="toggleFullscreen($event)" />
  @if (!isEventReportProject) {
    <div class="controls-container">
      @if (isOfflineAreaDefined) {
        <aps-offline-area-control
          data-test="e2e-offline-area-edit"
          [disabled]="!canDrawMapAreaToDownloadOffline || mapControlsDisabled"
          [text]="isOfflineAreaEditModeActive ? 'Save area changes' : 'Edit offline area'"
          [icon]="isOfflineAreaEditModeActive ? 'save' : 'edit-project'"
          [tooltip]="isOfflineAreaEditModeActive ? 'Save changes' : 'Edit defined offline map area.'"
          [isFullscreen]="isFullscreen"
          (controlClick)="editOfflineArea()" />
        @if (isOfflineAreaEditModeActive) {
          <aps-offline-area-control
            data-test="e2e-offline-area-cancel"
            [disabled]="!canDrawMapAreaToDownloadOffline || mapControlsDisabled"
            [text]="'Cancel'"
            [icon]="'close'"
            [tooltip]="'Cancel'"
            [isFullscreen]="isFullscreen"
            (controlClick)="cancelEditing()" />
        } @else {
          <aps-offline-area-control
            data-test="e2e-offline-area-delete"
            [text]="'Delete offline area'"
            [icon]="'delete'"
            [tooltip]="'Delete defined offline map area.'"
            [isFullscreen]="isFullscreen"
            [disabled]="mapControlsDisabled"
            (controlClick)="removeOfflineArea()" />
        }
      } @else {
        <aps-offline-area-control
          data-test="e2e-offline-area-define"
          [disabled]="!canDrawMapAreaToDownloadOffline || offlineAreaDrawModeActive || mapControlsDisabled"
          [text]="'Define offline area'"
          [icon]="'define-area'"
          [tooltip]="'Draw the area on the map that you want to be available offline in the mobile app.'"
          [isFullscreen]="true"
          (controlClick)="drawOfflineArea()" />
      }
    </div>
  }
</div>
@if (!isOfflineAreaDefined && !isEventReportProject) {
  <mat-error>
    <span class="text-xs">No offline map area defined!</span>
  </mat-error>
}
